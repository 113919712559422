import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { MainContainer } from "../globalStyles"
import { TabTitles, TabContent } from "../styles/Services.style";
import PageTitle from '../components/PageTitle';
import { Link, Outlet } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import { Helmet } from 'react-helmet';




function Services() {

    const [searchParams, setSearchParams] = useSearchParams();

    const [ toggleState, setToggleState ] = useState('graphic-design');
    
    const [ activeMeta, setActiveMeta ] = useState({});

    useEffect(() => {
        const active = searchParams.get("active");
        if(active){
            setToggleState(String(active));

            if(String(active) == 'graphic-design'){
                setActiveMeta({
                    title: 'Professional Graphic Design Services | Creative Solutions for Your Brand',
                    content: 'Transform your brand with our expert graphic design services. From logos to marketing materials, we create compelling visuals that captivate and engage.',
                    url: 'https://www.artographertacts.com/services/?active=graphic-design'
                })
            } if (String(active) == 'web-design') {
                setActiveMeta({
                    title: 'Professional Web Design Services | Stunning, Responsive Websites',
                    content: 'Elevate your online presence with our expert web design services. We create stunning, responsive websites that engage users and drive results.',
                    url: 'https://www.artographertacts.com/services/?active=web-design'
                })
            } else if (String(active) == 'web-development') {
                setActiveMeta({
                    title: 'Professional Web Development Services | Build Your Online Presence',
                    content: 'Professional web development services offering custom websites, responsive design, and seamless user experience to boost your online presence and growth.',
                    url: 'https://www.artographertacts.com/services/?active=web-development'
                })
            } else if (String(active) == 'web-app-development') {
                setActiveMeta({
                    title: 'Professional Web App Development Services | Custom Solutions for Your Business',
                    content: 'Offering expert web app development services to build scalable, user-friendly solutions that drive business growth. Transform your ideas into reality with us!',
                    url: 'https://www.artographertacts.com/services/?active=web-app-development'
                })
            } else if(String(active) == 'digital-marketing') {
                setActiveMeta({
                    title: 'Top Digital Marketing Services | Boost Your Online Presence',
                    content: 'Boost your brand with our expert digital marketing services, including SEO, PPC, social media, and content marketing. Drive growth and engagement today!',
                    url: 'https://www.artographertacts.com/services/?active=digital-marketing'
                })
            }
        } else {
            setToggleState('graphic-design');
            setActiveMeta({
                title : 'Web Design, Graphic Design & Digital Marketing Services | Elevate Your Brand',
                content : 'Explore our expert services in web design, graphic design, and digital marketing, tailored to elevate your brand and drive success. Discover more today!',
                url: 'https://www.artographertacts.com/services/'
            })
        }
    }, [searchParams, setSearchParams]);

    const toggleTab = (e) => {
        setToggleState(e);
    }
    return(
        <>
            <Helmet>
                <title>
                    {activeMeta.title}
                </title>
                <link rel="canonical" href={activeMeta.url} />
                <meta 
                    name="descriptions" 
                    content={activeMeta.content}
                />
            </Helmet>
            <PageTitle 
                title= 'Services'
            />
            <MainContainer>
                <div className="col-12 justify-content-center text-align-center">
                    <TabTitles>
                        <div className="tab-title-container">
                            <Row className="justify-content-center">
                                <Col sm={7} md={2}>
                                    <div className='title-wrap'>
                                        <Link to='/services/?active=graphic-design' className= {toggleState === 'graphic-design' ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab('graphic-design')}
                                        >
                                            Graphic Design
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={7} md={2}>
                                    <div className='title-wrap'>
                                        <Link to='/services/?active=web-design' className= {toggleState === 'web-design' ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab('web-design')}
                                        >
                                            Web Design
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={7} md={2}>
                                    <div className='title-wrap'>
                                        <Link to='/services/?active=web-development' className= {toggleState === 'web-development' ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab('web-development')}
                                        >
                                            Web Development
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={7} md={3}>
                                    <div className='title-wrap'>
                                        <Link to='/services/?active=web-app-development' className= {toggleState === 'web-app-development' ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab('web-app-development')}
                                        >
                                            Web App Development
                                        </Link>
                                    </div>
                                </Col>
                                <Col sm={7} md={2}>
                                    <div className='title-wrap'>
                                        <Link to='/services/?active=digital-marketing' className= {toggleState === 'digital-marketing' ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab('digital-marketing')}
                                        >
                                                Digital Marketing
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </TabTitles>
                    <TabContent>
                        <div className= {toggleState === 'graphic-design' ? "tab-content active-content" : "tab-content"}>
                            <h3 className="content-title">Graphic Design Services</h3>
                            <p>Artographer is a full-service custom web design agency that specializes in designing websites that automatically attract the target audience of any business. For more than a decade, we have been serving businesses with user-centric designs with a combination of minimalistic and alluring visuals. Our professional design team includes user-experience and conversion rate optimization specialists who can create awesome websites that not only look good but, simple to use for both you and your website visitors.</p>
                            <div className="button-wrap">
                                <div className="button-inner-wrap">
                                    <Link to='graphic-design-company'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Graphic Design Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='logo-design-company'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Logo Design Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='/services/business-stationery-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Business Stationary Design
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='/services/brochure-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Brouchure Design
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='social-media-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Social Media Design
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='hire-a-graphic-designer'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Hire a Graphic Designer
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 'web-design' ? "tab-content active-content" : "tab-content"}>
                            <h3 className="content-title">Website Design Services</h3>
                            <p>Artographer is a full-service custom web design agency that specializes in designing websites that automatically attract the target audience of any business. For more than a decade, we have been serving businesses with user-centric designs with a combination of minimalistic and alluring visuals. Our professional design team includes user-experience and conversion rate optimization specialists who can create awesome websites that not only look good but, simple to use for both you and your website visitors.</p>
                            <div className="button-wrap services-button-wrap">
                                <div className="button-inner-wrap">
                                    <Link to='website-redesign-company'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Website Redesign Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='mobile-app-ui-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Mobile UI/UX Design Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='hire-a-web-developer'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Hire a Web Designer
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 'web-development' ? "tab-content active-content" : "tab-content"}>
                            <h3 className="content-title">Website Development Services</h3>
                            <p>At Artographer, we offer a huge variety of web development services. We work closely with our customers to create high performing and secure custom websites. Our expert developers have lots of experience to understand your project requirements and deliver a project that meets your needs. We will be happy in assisting you to offer a strong online presence.</p>
                            <div className="button-wrap services-button-wrap">
                                <div className="button-inner-wrap">
                                    <Link to='wordpress-development-services'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            WordPress Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='wordpress-theme-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Theme Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='marketplace-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Marketplace Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='membership-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Membership Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='magento-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Magento Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='woocommerce-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Woocommerce Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='mobile-app-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Mobile App Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='php-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            PHP Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='non-profit-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Non Profit Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='startup-website-design'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Startup Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='android-app-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Android App Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='drupal-website-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Drupal Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='ecommerce-website-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Ecommerce Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='shopify-website-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Shopify Website Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='hire-a-web-developer'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Hire Wordpress Developer
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 'web-app-development' ? "tab-content active-content" : "tab-content"}>
                            <h3 className="content-title">Web App Development Services</h3>
                            <p>Our expert developers in Artographer, have intense experience in handling website projects involving, PHP development, React.JS development, Angular.JS development, and Vue.JS development. You don’t have to limit yourself to the feature set offered by page builders. Our web developing team can work accordingly and provide all necessary custom features that you wish to include in your website. Get started now!</p>
                            <div className="button-wrap services-button-wrap">
                                <div className="button-inner-wrap">
                                    <Link to='web-app-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Web App Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='reactjs-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            React.Js Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='vuejs-development'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Vue.Js Development
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 'digital-marketing' ? "tab-content active-content" : "tab-content"}>
                            <h3 className="content-title">Online Marketing Services</h3>
                            <p>Artographer is a top digital marketing agency which specializes in offering data-driven and ROI-oriented digital marketing services. Our professional digital marketers take a customer-oriented approach to help your business reach its true potential. Start a project with us today and we will provide you with the best internet marketing services that will completely transform your web presence into a lead generating empire.</p>
                            <div className="button-wrap services-button-wrap">
                                <div className="button-inner-wrap">
                                    <Link to='digital-marketing-services'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Digital Marketing Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='seo-services'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            SEO Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='social-media-marketing'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Social Media Marketing
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='pay-per-click-services'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Pay Per Click Services
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='facebook-marketing-company'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Facebook Merketing
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='content-marketing-services'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Content Marketing
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='marketing-automation'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Marketing Automation
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                                <div className="button-inner-wrap">
                                    <Link to='hire-digital-marketing-manager'
                                        className='button-link bounce-to-right button-cream'
                                    >
                                        <span className="button-main-text">
                                            Hire Digital Marketing Manager
                                        </span>
                                        <IoIosArrowDropright className="button-right-icon"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </TabContent>
                </div>
            </MainContainer>
            <Outlet />
        </>
    );
}

export default Services;