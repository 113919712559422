import styled from "styled-components";


export const OneRoofContainer = styled.div`
padding-left: 70px;
padding-right: 70px;
margin-top: 5rem;
margin-bottom: 5rem;
text-align: center;

    @media screen and (max-width: 991px) {
        padding-right: 30px;
        padding-left: 30px;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: 480px) {
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-right: 15px;
        padding-left: 15px;
    }   

    .section-title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 3.3rem;
        padding-left: 190px;
        padding-right: 190px;

        @media screen and (max-width: 1199px){
            font-size: 2.1rem;
            line-height: 2.7rem;
            padding-left: 150px;
            padding-right: 150px;
        }

        @media screen and (max-width: 991px){
            font-size: 2rem;
            line-height: 2.3rem;
            padding-left: 50px;
            padding-right: 50px;
        }

        @media screen and (max-width: 767px){
            text-align: left;
            padding: 0;
        }

        @media screen and (max-width: 480px){
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
    }

    p {
        font-size: 1.1rem;
        font-weight: 500;
        margin-top: 1rem;
        color: var(--paragraph);

        @media screen and (max-width: 991px) {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        @media screen and (max-width: 767px) {
            text-align: left;
        }

        @media screen and (max-width: 480px) {
            font-size: .9rem;
            line-height: 1.4rem;
        }
    }

    .section-content {
        font-size: 1rem;
        font-weight: 500;
        color: var(--paragraph);
        text-align: left;

        .creative-studio-image {
            object-fit: contain;
            width: 100%;
            height: 100%;

            @media screen and (max-width: 767px){
                display: none;
            }
        }

        .container-list {
            margin-top: 6rem;
            padding-left: 50px;
            padding-right: 50px;

            @media screen and (max-width: 991px){
                padding-right: 0;
                padding-left: 10px;
            }

            @media screen and (max-width: 767px){
                padding: 0;
                margin: 0;
            }

            
        }

        li {
            margin-bottom: 1.5rem;
            list-style: none;
            display: flex;
            line-height: 1.5rem;
            color: var(--paragraph);


            @media screen and (max-width: 767px){
                margin: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .check-icon {
                color: var(--primary);
                margin-right: .8rem;

                @media screen and (max-width: 767px){
                    font-size: .8rem;
                    margin-left: .4rem;
                    margin-right: .5rem;
                }

                @media screen and (max-width: 480px){
                    font-size: .7rem;
                    margin-left: .4rem;
                    margin-right: .4rem;
                }
            }
        }
    }
`;