import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HireFormat from './HireFormat';
import {MainContainer} from '../../globalStyles';
import { HireWrap } from '../../styles/HireFormat.style';
import Divider from '../../components/Divider'
import WhiteLabel from '../../components/WhiteLabel';
import Form from '../../components/Form';
import RelatedServices from '../../components/RelatedServices';
import OneRoof from '../../components/OneRoof';
import Certification from '../../components/Certification';
import ResultDriven from '../../components/ResultDriven';
import { FaCheck } from 'react-icons/fa';
import {Helmet} from 'react-helmet';



const WebDeveloper = () => {
    return (
        <>
            <Helmet>
                <title>
                    Hire Web Developer | Remote Developer, Dedicated Developer Team From India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/hire-a-web-developer/" />
                <meta 
                    name="descriptions" 
                    content="Hire a Web developer or a dedicated coders team to build your custom theme, website and more. Hire a full time or monthly dedicated web developer based on your needs."
                />
            </Helmet>
            <HireWrap>
                <HireFormat
                    title= 'Hire a Web Developer'
                    text= 'Artographer is one of the highly acclaimed WordPress development companies to hire WordPress developers in India for your advanced WordPress development project works.'
                    listitem1= 'White Label Partnerships'
                    listitem2= 'Startup Business Partnerships'
                    image= '../../images/hire-web-developer.webp'
                    primarytitle= 'Technical Expertise of Our Dedicated WordPress Developers'
                    primarycontent1= 'Our WordPress development company in India blends the professional expertise of our dedicated developers with the innovative WordPress technologies to deliver cost-effective solutions that will improve your ROI.'
                    primarycontent2= 'Hire a dedicated WordPress developer from our company to convert your WordPress development ideas into reality. Our dedicated WordPress developers are experienced in:'
                    primarylist1= {<span><FaCheck className="blds-list-icon"/><span>PHP | MySQL | Angular JS, Vue JS</span></span>}
                    primarylist2= {<span><FaCheck className="blds-list-icon"/><span>Ajax/JQuery</span></span>}
                    primarylist3= {<span><FaCheck className="blds-list-icon"/><span>XML/RPC/Web Service</span></span>}
                    primarylist4= {<span><FaCheck className="blds-list-icon"/><span>CSS 3, Bootstrap , Foundation, LESS, SAAS Frameworks</span></span>}
                    primarylist5= {<span><FaCheck className="blds-list-icon"/><span>Genesis, Avada, X, Pro, Elementor, Divi WordPress frameworks</span></span>}
                    imagecol= '../images/artographer-hire-web-developer.png'
                    alt= 'artographer digital marketing'
                    header= 'What Artographer’s WordPress Programmers Can do for You?'
                    rdtitle1= 'WordPress Website Development'
                    rdcontent1= 'Our dedicated and highly skilled team of custom WordPress developer can create lead generating WordPress websites that represents your company and business values.'
                    rdtitle2= 'Accessible CMS Development'
                    rdcontent2= 'We offer a complete range of WordPress CMS development services to help you manage your content in a structured manner.'
                    rdtitle3= 'Custom WordPress Template Customization'
                    rdcontent3= 'Hire our WordPress developer from India to build a feature-rich custom WordPress theme which is SEO, responsive and retina ready.'
                    rdtitle4= 'Advanced WordPress Design & Development'
                    rdcontent4= 'We offer a complete range of WordPress CMS development services to help you manage your content in a structured manner.'
                    rdtitle5= 'Accessible CMS Development WordPress Blog Development'
                    rdcontent5= 'Hire our professional WordPress web developers to get an effective content marketing blog website that is easy to update and SEO friendly.'
                    rdtitle6= 'WordPress Migration'
                    rdcontent6= 'Consult our WordPress programmers to get your WordPress website developed, hosted, and moved to another server or host with ease.'
                />
                <Divider />
                <MainContainer>
                    <WhiteLabel 
                        title= 'Capabilities of Our Professional WordPress Developers'
                        content1= 'Simple to advanced responsive WordPress development using Bootstrap CSS, Foundation CSS, LESS, SASS, GULP Workflow'
                        content3= 'Expertise in ACF Pro plugin, Types plugin, Visual Composer, Divi front-end editor, ThemeFusion editor & much more'
                        content2= 'WordPress based multiple payment gateway integrations for e-Commerce & business websites'
                        content4= 'Expertise in complex Contact Form 7(CF7), Gravity Forms, & custom WordPress form development'
                    />
                </MainContainer>
                <MainContainer>
                    <ResultDriven 
                        header= "Reasons to Hire Our WordPress Developers"
                        title1= "One Stop Solution"
                        content1= "You need not go elsewhere as our end-to-end custom WordPress services cover a wide range of solutions."
                        title2= "Deliver Real Value"
                        content2= "Our highly talented WordPress professionals have immense knowledge in various techniques. Our developers can add real value to your project."
                        title3= "Straightforward Advice"
                        content3= "Our dedicated WordPress developers give honest advice before and during the development process. Our team is up-to-date with the latest WordPress trends and advancements."
                        title4= "Quality Coding"
                        content4= "All the themes and plugins developed by our custom WordPress developers are well-coded, reliable and secure."
                        title5= "Transparency"
                        content5= "We involve you throughout the WordPress development process, give you regular updates, and get your feedback."
                        title6= "Timely delivery"
                        content6= "Our team of experienced WordPress programmers completes each project on the set time frame as they follow proven techniques and a result-oriented approach."
                    />
                </MainContainer>
                <Form />
                <MainContainer>
                    <RelatedServices
                        title= "Logo Design Services"
                        subTitle= "Creative Graphic Design Services"
                        title2= "Mobile App UI & UX Design"
                        subTitle2= "Mobile app development | Develop android app | IOS application development"
                        title3= "Startup Website Development"
                        subTitle3= "Creative Startup Website Development"
                        title4= "E-commerce Website Development"
                        subTitle4= "Creative E-commerce Website Development"
                        title5= "Digital Marketing Services"
                        subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                        title6= "Social Media Marketing"
                        subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                    />
                </MainContainer>
                <OneRoof />
                <Certification />
            </HireWrap>
        </>
    );
}

export default WebDeveloper;
