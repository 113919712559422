import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function EcommerceWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    eCommerce Website Development Services Company India - eCommerce Web Application Development
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/ecommerce-website-development/" />
                <meta 
                    name="descriptions" 
                    content="Artographer - India based eCommerce Website Development Company provides custom eCommerce website development solutions to various industries. Click to know more"
                />
            </Helmet>
            <PageTitle
                title= "E-commerce Website Development"
                text1= "We understand the importance of utilizing the latest technologies. Thus, our experts build websites which are best in standard features and functionalities. They are easily customizable as well. We provide individual developers who will assist throughout the project."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our eCommerce Website Development Company"
                    content= "An e-commerce website development should make it simple and easy for customers to buy products in confidence, while showcasing those same products in the very best light possible."
                    content2= "Providing the ability for customers to make purchases directly from your website will not only open your business up to a much wider audience but, will also streamline turnover and reduce running costs to improve profits."
                    listTitle= "We can build you an e-commerce website from scratch, or we can add an online store to your existing e-commerce website. Either way, our shops inspire customer confidence, look great, and make it as simple as possible for your visitors to place orders."
                    image= '../images/artographer-ecommerce-website.png'
                    alt= 'artographer ecommerce website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Custom E-commerce Website Development Platforms</SectionTitle>}
                    para1= "Depending on the size of your business and your specific requirements, we can provide you with varying open-source e-commerce development platforms."
                    para2= "As your dedicated e-commerce website developer in India, we will also offer consultation to help you choose the best platform for your company’s needs."
                    para3= "For those looking for their own custom e-commerce website development, we’re also able to build e-commerce solutions from scratch and to specification. This will give your store a completely unique look and feel, while allowing you to add as many unique features as you like."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from E-commerce Website Development Services"
                content= "Our e-commerce website development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">Professional E-commerce Website Development Services</span>}
                    para2= {<span>Artographer offers comprehensive services to help suit your precise needs. Our e-commerce website development services are 100% secure and will fit seamlessly into your site’s wider design language.<br /><br />We can provide the full range of features that you would expect, including quick payment options, shopping baskets, and more – all in an intuitive and easy to navigate format.</span>}
                    para3= {<span>We will also provide you with a simple-to-use back end, to ensure that it’s as easy as possible to keep track of inventory and add new items as needed.<br /><br />Our smart designs are perfectly thought-out to drive the maximum number of conversions and we can discuss the use of other marketing techniques such as ‘Google remarketing’ and landing pages to help you increase your sales yet further. Why not ask us about it?</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Digital Marketing Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label e-commerce website development services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits. Our professional e-commerce website developers will make sure all the pieces are successfully implemented.</span>}
                    content2= "As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the eCommerce website development needs of any agency. We are happy to work under your branding to take care of your customers."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized E-commerce Website Development Services"
                    content= {<span>We have worked with e-commerce website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a e-commerce website development services.<br /><br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Shopify Website Development"
                    subTitle2= "Creative Shopify Website Developmentt"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Marketing Automation Services"
                    subTitle5= "SCreative Marketing Automation Services"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default EcommerceWeb;