import styled from "styled-components";


export const PortfolioContainer = styled.div`
    padding: 0; 

    .menu-tab {
        padding: 0px;

        @media screen and (max-width: 991px){
            margin-bottom: 2rem;
        }

        .btn-menu-tab {
            text-decoration: none;
            color: #606060;
            display: block;
            text-align: left;
            margin-bottom: .8rem;
            cursor: pointer;
            transition: ease all .3s;

            &:hover {
                margin-left: .6rem;
                color: var(--secondary);
            }
        }

        .active-tab {
            color: var(--secondary);

            &:hover {
                margin-left: .6rem;
            }
        }
    }

    .pf-thumb-container {
        width: 100%;
        float: left;
        position: relative;
        margin-bottom: 30px;

        &:hover .pf-content-wrap .pf-thumb-desc,
        &:hover .pf-content-wrap .pf-icon-right {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s,visibility 0.4s;
        }

        &:hover .pf-content-wrap {
            &::after {
                height: 100%;
                transition: all 0.4s ease-in;
            }
        }

        .pf-thumb-wrap {
            width: 100%;
            float: left;

            img {
                width: 100%;
                height: auto;
                float: left;
            }   
        }

        .pf-content-wrap {
            position: absolute;
            width: 100%;
            height: 100%;
            float: left;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: self-end;

            &::after {
                content: '';
                width: 100%;
                height: 0;
                background-color: rgb(244 121 53/ 80%);
                z-index: 1;
                left: 0;
                position: absolute;
                top: 0;
                transition: all 0.2s linear;
            }

            .pf-thumb-desc,
            .pf-icon-right {
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.4s,visibility 0.4s;
            }

            .pf-thumb-desc {
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 2.2rem;
                letter-spacing: -1px;
                text-align: left;
                color: white;
                z-index: 2;
                width: 100%;
                float: left;

                @media screen and (max-width: 1199px) {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
            }

            .pf-icon-right {
                font-size: 1.8rem;
                color: white;
                z-index: 2;
            }
        }
    }
`;