import React from "react";
import { SectionTitle } from "../globalStyles";
import { SectionContainer } from "../styles/LocationbasedService.style";
import PrimaryButton from "./PrimaryButton";
import { Link } from "react-router-dom";


function LocationbasedService(props) {
    return(
        <SectionContainer>
           <div className="llds-container">
                <SectionTitle className="llds-section-title">{props.title}</SectionTitle>
                <p className="llds-para-text">{props.content}</p>
                <div className="llds-btn">
                    <Link to= '/request-a-quote'>
                        <PrimaryButton
                            smalltext= 'Its Free'
                            maintext= 'Get Custom Quote'
                        />
                    </Link>
                </div>
            </div>
        </SectionContainer>
    );
}


export default LocationbasedService;