import React from 'react';
import { FooterContainer, FooterTitle, MiddleContainer, BottomContainer } from '../styles/FooterPro.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdLocationOn } from "react-icons/md";
import { SiMinutemailer, SiInstagram } from "react-icons/si";
import { FaPhoneAlt, FaChevronCircleRight, FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill, RiYoutubeLine, RiTwitterLine } from "react-icons/ri";






const FooterPro = () => {
    return (
        <FooterContainer>
            <div className='container-top col-12'>
                <FooterTitle>Build Smarter. Better. Faster. Websites</FooterTitle>
            </div>
            <MiddleContainer>
                <Row className='justify-content-center'>
                    <div className='col-xl-3 col-md-4 col-sm-12 newsletter-container'>
                        <span className='artographer-text-only-logo'>
                            <img src='../images/artographer-text-only-logo.webp' alt='artographer' />
                        </span>
                        <div className='newsletter-wrap'>
                            <input 
                                className='newsletter-input'
                                type='text'
                                placeholder='Your email address'
                            />
                            <button className='newsletter-btn'>
                                <FaChevronCircleRight />
                            </button>
                        </div>
                        <div className='subscribe'>
                            Subscribe to our newsletters
                        </div>
                        <div className='social-media-icon-wrap'>
                            <a 
                                className='social-media-icon' 
                                href='https://www.facebook.com/artographertacts/'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <FaFacebookF />
                            </a>
                            <a 
                                className='social-media-icon instagram' 
                                href='https://www.instagram.com/artographertactsindia/'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <SiInstagram />                        
                            </a>
                            <a 
                                className='social-media-icon twitter' 
                                href='https://twitter.com/ArtographerT'
                                target="_blank" 
                                rel="noopener noreferrer" 
                            >
                                <RiTwitterLine />
                            </a>
                            <a 
                                className='social-media-icon linkedin'
                                href='https://www.linkedin.com/in/artographer-tacts-304b49243/'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <RiLinkedinFill />
                            </a>
                            <a 
                                className='social-media-icon youtube' 
                                href='https://www.youtube.com/channel/UCLBQh-ta7EHR5oOADv9b_cw'
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <RiYoutubeLine />
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-md-2  col-sm-6 footer-grid'>
                        <span className='list-title'>Services</span>
                        <ul>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/services?active=graphic-design'
                                >
                                    Graphic Design
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/services?active=web-design'
                                >
                                    Web Design
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/services?active=web-development'
                                >
                                    Web Development
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/services?active=web-app-development'
                                >
                                    Web App Development
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/services?active=digital-marketing'
                                >
                                    Digital Marketing
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xl-2 col-md-2  col-sm-6 footer-grid'>
                    <span className='list-title'>Navigation</span>
                        <ul>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/about-artographer?active=engagement-models'
                                >
                                    Engagement Model
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/about-artographer?active=our-values'
                                >
                                    Values
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/portfolio'
                                >
                                    Portfolio
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/testimonials'
                                >
                                    Testimonials
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/case-studies'
                                >
                                    Case Studies
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xl-2 col-md-3  col-sm-6 footer-grid'>
                        <span className='list-title'>Hire Us</span>
                        <ul>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/hire-a-graphic-designer'
                                >
                                    Hire a Graphic Designer
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/hire-a-web-developer'
                                >
                                    Hire a Web Developer
                                </Link>
                            </li>
                            <li>
                                <Link className='menu-list-item'
                                    to= '/hire-digital-marketing-manager'
                                >
                                    Hire a Digital Marketing Manager
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-xl-3 col-lg-10 col-md-12 col-sm-6 footer-grid'>
                        <span className='list-title contact-title'>Start a conversation</span>
                        <ul className='contact-ul'>
                            <li>
                                <Link className='menu-list-item address'
                                    to= ''
                                >
                                    <MdLocationOn className='menu-contact-icon'/>
                                    Plot No. 167, SF, Dharamshala Road, Mithapur Extn. Delhi INDIA [110044]
                                </Link>
                            </li>
                            <li>
                                <a className='menu-list-item email'
                                    href="mailto:artographertacts@gmail.com?body=My custom mail body"
                                >
                                    <SiMinutemailer className='menu-contact-icon'/>
                                    artographertacts@gmail.com
                                </a>
                            </li>
                            <li>
                                <a className='menu-list-item number'
                                    href='tel:+919971929890'
                                >
                                    <FaPhoneAlt className='menu-contact-icon call'/>
                                    +91 (0) 9971929890
                                </a>
                            </li>
                        </ul>
                    </div>
                </Row>
            </MiddleContainer>
            <hr />
            <BottomContainer>
                <span>
                    Copyright 2022 © ArtographerTacts. All rights reserved.&nbsp;&nbsp;
                    <Link className= 'footer-link' to= '/privacy-policy'>Privacy Policy</Link> &nbsp;|&nbsp;&nbsp;
                    <Link className= 'footer-link' to= '/terms-conditions'>Terms and Conditions</Link> &nbsp;|&nbsp;&nbsp;
                    <Link className= 'footer-link' to= '/site-map'>Site Map</Link>
                </span>
            </BottomContainer>
        </FooterContainer>
    );
}

export default FooterPro;
