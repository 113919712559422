import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import { Page } from "../styles/AllPage.style";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function SocialMedia() {
    return(
        <Page>
            <Helmet>
                <title>
                    Social Media Design Services Company India | Professional Social Media Creatives Design Services
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/social-media-design/" />
                <meta 
                    name="descriptions" 
                    content="Are you looking for Social Media Graphic Design Services in India? We are the best Social Media Graphic Design company for your business. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Social Media Design Services"
                text1= "Today, more than ever, social media has become a conduit between your customer and your business. The more you engage with the customer, the better the relationship becomes. Artographer provides social media graphic design services that are aimed at enhancing your business presence on social media."
                text2= "We deliver customized designs to fit your business so that your company pages can really evoke a feeling of quality and stand out against the competition."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Great Social Media Design that Makes Your Brand Stand Out"
                    content= "How you present yourself to the users of social media holds a lot of weight. For a social media marketing plan to work, consistent branding is required. No matter how good your social media marketing strategy is, without proper design nothing will work out because social media is a visual platform."
                    content2= "Our professional designers understand that everything you do online will affect your ROI and company growth. That is why, at Artographer, our designers create marketing materials that will work for you. From full corporate identity, business cards to just a simple icon, our talented social media designers can create something professional and eye-catchy, while ensuring your brand standards are met."
                    image= '../images/artographer-social-media-design.png'
                    alt= 'artographer social media design'
                />
            </MainContainer>
            <PageImage
                src='../images/graphic-service.webp'
                alt='graphic design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Social Media Design Process</SectionTitle>}
                    para1= "These days a strong presence on social media is more important than ever. If your social media branding design isn’t active on Facebook, Twitter, LinkedIn, Instagram, Pinterest, or any of the others, then you’re probably missing out on countless business opportunities even as you’re reading this."
                    para2= "Being on social media, posting updates regularly and providing value for your followers is all wonderful but how to go about forging a relationship. This is where social media graphic design comes in."
                    para3= "Let a social media design expert at Artographer help you leverage the power of social media graphic design to advance your business. A company like Artographer will provide dedicated and comprehensive social media design services, help you identify your social media strengths, and also formulate long-term solutions."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Social Media Design Services"
                content= "We offer custom social media design services to a wide array of industry verticals including all kinds of start-ups and small businesses. Our expert designers team is specialized in design as per client requirement."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Social Media Design Services"
                    title1= "Twitter Background & Header Design"
                    content1= "Almost 51% of Twitter users follow companies, brands, and products on social networks. Thus to enhance your business we create attractive and relevant Twitter graphic to keep your audience engaged."
                    title2= "Facebook Profile & Header Design"
                    content2= "Around 23% of the world’s population has an active Facebook account. Keeping your audience engaged with Facebook posts regularly is important. Our designers will help your audience understand your business through our creative designs."
                    title3= "Creatives for Social Media"
                    content3= "To develop a strong visual bond between your brand and your customer, leverage our social media post design services to publish engaging graphics at the right time."
                    title4= "Landing Page Design"
                    content4= "Smart designers will always be familiar with their target audience. A user-centered landing page design is based on the principles of knowing who your audience is. At Artographer, we design with your customers in mind."
                    title5= "Infographics Design"
                    content5= "Infographic is a powerful tool which allows people to easily understand information through visual data, charts, and statistics. We create infographics with proper color and proportion which will stick with the memories of your audience."
                    title6= "Other Marketing Related Designs"
                    content6= "Apart from the above design services, we do ad graphics, HTML ads to gain customer attention, microsites, blogs, forums to drive new audience to your website."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Social Media Design Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label social media design outsourcing is suitable for agencies that are looking for extra design resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits. Our expert social media designers will make sure all the pieces of your social media marketing are successfully implemented.</span>}
                    content2= "As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the social media design needs of any agency. We are happy to work under your branding to take care of your customers."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Social Media Design Services"
                    content= "We have worked with social media clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a social media services.If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Social Media Design Services'
                    title1="How much do social media graphics cost?"
                    content1="Our professional social media design services is charged depending on the size of your business, the collection of services, volume and frequency of the graphics you require."
                    title2="Do you offer social media marketing / management service?"
                    content2="Yes! We offer social media management services."
                    title3="What does a social media designer do?"
                    content3="Our social media designers generate designs in order to increase traffic and attract your target audience."
                    title4="What are other design services you provide?"
                    content4="Logo design, website design, 3D graphics flyer, packaging design, brochure design, business card design, banner design, label design, letter head design, catalogue design, book cover and more. You just name it, we can design it."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}


export default SocialMedia;