import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function VueJs() {
    return(
        <Page>
            <Helmet>
                <title>
                    Vue-JS Web Development Company India | Hire Vue-JS Developer
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/vuejs-development/" />
                <meta 
                    name="descriptions" 
                    content="Artographer is a well experienced Vue JS Web development company in India focused on developing feature-rich Vue JS solutions."
                />
            </Helmet>
            <PageTitle
                title= "Vue Js Development"
                text1= "Artographer is a well experienced Vue JS Web development company in India focused on developing feature-rich Vue JS solutions. Our professional Vue JS developers will utilize this performance-obsessed framework to design a futuristic front-end application that attracts users. Our Vue JS development company has many years of industry experience. "
                text2= "We have offered high-end Vue JS development services to a broad spectrum of industries from e-commerce, healthcare to education. Our clients, both start-ups and established have received amazing custom Vue JS development solutions that fulfilled their business requirements to a major extent."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Building Powerful Vue JS UI/UX that Enhance User Engagement"
                    content= "Vue JS has become one of the most popular JavaScript frameworks due to its progressive features. It is also quite easy to build interactive UI and single-page applications (SPA’s). This framework has dozens of features that will benefit businesses of all sizes and types. One of the main benefits of Vue JS is its ease of use and quick development time."
                    content2= "Our Vue JS team has significant experience in creating cutting edge design. The front-end designers at our Vue JS development agency utilize the flexibility of this framework to deliver effective designs. Our front-end designers combine user-centric designs, usability practices, and flexibility of Vue.js to deliver an exceptional experience."
                    listTitle= "With Vue JS library our front-end designers can build interactive UI/UX that really scale. We build amazing interfaces which users love. Our main goal is to fulfill your requirements efficiently."
                    image= '../images/artographer-vuejs-development.png'
                    alt= 'artographer vue-js development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Hire Our Vue JS Developer?</SectionTitle>}
                    para1= {<span>Artographer has an pool of Vue JS developers who are exceptionally skilled and ensure premium quality in services.<br /><br />With the help of our developers, our Vue JS web development company in India has created and delivered many solutions with outstanding performance.</span>}
                    para2= {<span>Our Vue JS development company in India has developers who use an agile approach to deliver all the projects ahead of the deadline to help clients save time and money.<br /><br />We follow high coding standards, latest tools and technology, proper planning, research, proven methodologies to deliver Vue JS solutions of your dreams.</span>}
                    para3= {<span>Additionally, the flexible hiring model we offer will provide you the most purpose-focused development options.<br /><br />The Vue JS developers you hire from Artographer will be dedicated to providing the pinnacle of satisfaction through our work.</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Vue JS Web Development Services"
                content= "Our Vue JS development team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Get Fully Functional Vue JS Development Services"
                    title1= "Custom Vue JS Development"
                    content1= "Our Vue JS development agency in India can build complex enterprise applications using elegant programming style & patterns,rich packages,Routing & state management for organizations who have unique business requirements."
                    title2= "Vue JS Virtual DOM /Component Based Development"
                    content2= "Hire a Vue JS developer from Artographer who can build DOM/component based development that will enhance the features of your application and take care of your web and mobile applications in a quick turn around time."
                    title3= "Interactive Vue Material UI with UX"
                    content3= "Our front-end designers at our Vue JS web development agency in India can customize advanced mobile apps and websites/enterprise applications in order to create exceptional UI along with UX."
                    title4= "Vue JS Conversion/Upgrade/Add-on/Plug & Play Services"
                    content4= "Our expert Vue JS developers at Artographer can help to seamlessly covert your existing website or application to this futuristic framework to multiply the benefits for your business."
                    title5= "Vue JS Maintenance"
                    content5= "Our team will assist to test the design idea in order to figure out if the concept can be achieved in development. This will verify the potential for real-world application."
                    title6= "Online Reputation Management"
                    content6= "We can give you extensive help for bug resolutions, enhancements, upgrades and technical improvements in the current website/application. Extra charges may apply."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "Professional Vue JS Development for B2B Outsourcing & Whitelabel Company Services"
                    content1= {<span>Vue JS web development outsourcing is suitable for agencies that are looking for extra development resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. Our Vue JS developers will make sure all the pieces of Vue JS website design, development and maintenance are successfully implemented.</span>}
                    content2= "As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the Vue JS development needs of any agency. We are happy to work under your branding to take care of your customers."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Verticals of Vue JS Web Development"
                    title1= "Media and Entertainment"
                    content1= "Our Android development services company in India can develop Wi-Fi and GPS enabled web apps that will help to offer amazing services to your end-users."
                    title2= "Travel & Tourism Solutions"
                    content2= "To improve the profitability, business outreach, and customer retention of your travel company, we offer superior travel & tourism app development solutions."
                    title3= "E-Learning Solutions"
                    content3= "Our Android development agency develops tailor-made, standard and user-friendly e-learning apps for institutes, universities, startups, schools, colleges, entrepreneur, and more."
                    title4= "Online Booking"
                    content4= "To improve your business growth, our Android application development company in India can automate booking system and integrate e-commerce models in Android apps."
                    title5= "Healthcare App Solutions"
                    content5= "Our Android App development services in India develop revolutionary healthcare Android apps which will help you to upsurge patient experience and better manage health data records."
                    title6= "Banking & Finance Solutions"
                    content6= "Our custom Android application development company in India develops scalable Android mobile apps to help financial organizations improve their efficiency."
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Vue Js Development Services"
                    content= {<span>Our Location-based / Localized Vue Js Development Services<br /><br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Vue JS Web Development Services'
                    title1="How long does it take to create a Vue.js web app?"
                    content1="The design and development process time frame will depend on your requirements. We can give you a rough time estimate after understating your needs."
                    title2="Can I select my preffered Vue.js developer?"
                    content2="Yes. You can interview our developers and hire them according to your preferences and requirements."
                    title3="Are your Vue.js site mobile-friendly?"
                    content3="Yes. Every website we build responsive design methods to ensure that the website works well on a variety of screen sizes."
                    title4="Is there any hidden cost involved in your Vue.js development?"
                    content4="No. Our Vue JS development company in India will discuss all your requirements and set a price. We will definitely adhere to the outlined price."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= " Membership Website Development"
                    subTitle3= "Creative Membership Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default VueJs;