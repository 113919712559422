import styled from "styled-components";


export const PrimaryButtonStyle = styled.button`
    text-align: left;
    padding: .8rem 1.5rem;
    background-color: var(--primary);
    border: 0;
    color: white;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;

    @media screen and (max-width: 1199px){
        padding: .8rem 1.2rem;
    }

    @media screen and (max-width: 480px){
        padding: .6rem 1rem;
    }

    &:hover {

        &::before {
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #D1510B;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
    }


    .small-text {
        font-size: .8rem;
        font-weight: 500;
        line-height: 1.2rem;
        display: block;

        @media screen and (max-width: 480px) {
            font-size: .7rem;
            line-height: 1rem;
        }
    }

    .main-text {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.5rem;
        display: block; 

        @media screen and (max-width: 480px) {
            font-size: .9rem;
            line-height: 1rem;
        }

        .icon {
            margin-left: .5rem;
        }
    }
`;



export const OutlineButtonStyle = styled.button`
    text-align: left;
    padding: .6rem 1.5rem;
    background-color: white;
    border: 2px solid var(--primary);
    color: var(--primary);
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;

    @media screen and (max-width: 1199px){
        padding: .8rem 1.2rem;
    }

    @media screen and (max-width: 991px){
        padding: .7rem 1.1rem;
    }

    @media screen and (max-width: 480px){
        padding: .6rem 1rem;
    }

    &:hover {

        .small-text {
            color: white;
        }

        .main-text {
            color: white;
        }

        &::before {
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--primary);
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
    }


    .small-text {
        font-size: .8rem;
        font-weight: 500;
        line-height: 1.3rem;
        display: block;
    }

    .main-text {
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5rem;
        display: block;

        @media screen and (max-width: 480px) {
            font-size: .9rem;
            line-height: 1rem;
        }

        .icon {
            margin-left: .5rem;
        }
    }
`;


export const SecondaryButtonStyle = styled.button`
    text-align: left;
    padding: .8rem 1.5rem;
    background-color: var(--secondary);
    border: 0;
    color: white;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;

    @media screen and (max-width: 1199px){
        padding: .8rem 1.2rem;
    }

    @media screen and (max-width: 480px){
        padding: .6rem 1rem;
    }

    &:hover {

        &::before {
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0475C2;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
    }


    .small-text {
        font-size: .8rem;
        font-weight: 500;
        line-height: 1.3rem;
        display: block;

        @media screen and (max-width: 480px) {
            font-size: .7rem;
            line-height: 1rem;
        }
    }

    .main-text {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.5rem;
        display: block;

        @media screen and (max-width: 480px) {
            font-size: .9rem;
            line-height: 1rem;
        }

        .icon {
            margin-left: .5rem;
        }
    }
`;

export const LightButton = styled.button`
    background: var(--background);
    color: var(--dark-grey);
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 12px;
    padding: 20px 20px 20px 30px;
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (max-width: 991px){
        font-size: .9rem;
        padding: 15px 15px 15px 20px;
        border-radius: 8px;
    }

    @media screen and (max-width: 480px){
        padding: 10px 10px 10px 15px;
        border-radius: 8px;
    }

    &::before {
        content: " ";
        left: 0;
        position: absolute;
        width: 0;
        height: 0;
        border-width: 21px 0 21px 15px;
        border-color: transparent transparent transparent var(--primary);
        border-style: solid;

        @media screen and (max-width: 991px){
            border-width: 16px 0 16px 12px;
        }

        @media screen and (max-width: 480px){
            border-width: 11px 0 11px 8px;
        }
    }
`;