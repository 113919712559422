import { OutlineButtonStyle } from '../styles/Buttons.style';

import React from 'react';

const OutlineButton = (props) => {
    return (
        <OutlineButtonStyle>
            <span className='small-text'>
                {props.smalltext}
            </span>
            <span className='main-text'>
                {props.maintext}
            </span>
        </OutlineButtonStyle>
    );
}

export default OutlineButton;

