import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { SectionContainer } from '../styles/OurProcess.style';
import { Link } from 'react-router-dom';


function OurProcess(props) {
    return(
        <SectionContainer>
            {props.title}
            <div className="oldp-content-container">
                <Row>
                    <Col md={4} sm={12}>
                        <p>{props.para1}</p>
                        {props.para1bold}
                    </Col>
                    <Col md={4} sm={12}>
                        <p>{props.para2}</p>
                    </Col>
                    <Col md={4} sm={12}>
                        <p>{props.para3}</p>
                    </Col>
                </Row>
            </div>
            <Link to= '/request-a-quote'>
                {props.button}
            </Link>
        </SectionContainer>
    );
}


export default OurProcess;