import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { SectionContainer } from "../styles/PlanCost.style";
import { FaDollarSign } from "react-icons/fa";
import PrimaryButton from "./PrimaryButton";


function PlanCost(props) {
    return(
        <SectionContainer>
            <div className="head-wrap">
                {props.head}
                {props.head3}
            </div>
            <div className="head-wrap-primary">
                {props.head2}
                {props.head4}
            </div>
            <div className="ldcs-tab-wrap">
                <h3 className="ldcs-plan-price"><span><FaDollarSign /></span>{props.price}</h3>
                <p className="ldcs-plan-intro">
                    {props.intro}
                </p>
                <div className="plan-details-wrap">
                    <li className="ldcs-list-item">{props.listItem1}</li>
                    <li className="ldcs-list-item">{props.listItem2}</li>
                    <li className="ldcs-list-item">{props.listItem3}</li>
                    <li className="ldcs-list-item">{props.listItem4}</li>
                    <li className="ldcs-list-item">{props.listItem5}</li>
                    <li className="ldcs-list-item">{props.listItem6}</li>
                    <li className="ldcs-list-item">{props.listItem7}</li>
                    <li className="ldcs-list-item">{props.listItem8}</li>
                </div>
                <div className="ldcs-btn-container">
                    <a href="#get_details_form">
                        <PrimaryButton
                            smalltext= 'For Details'
                            maintext= 'Talk to Us'
                        />
                    </a>
                </div>
                <span className="ldcs-discount">{props.discount}</span>
            </div>
        </SectionContainer>
    );
}


export default PlanCost;