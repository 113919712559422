import styled from "styled-components";


export const PageStyle = styled.div`

    .section-block {
        text-align: left;
        margin-bottom: 1rem;

        .block-head {
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.5rem;
            color: var(--dark-grey);
            margin-top: 4rem;
            margin-bottom: 1rem;
            display: block;

            @media screen and (max-width: 991px) {
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin-top: 3rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.7rem;
                line-height: 2.2rem;
                margin-top: 3rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1.6rem;
                line-height: 2.1rem;
            }
        }

        .block-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: var(--dark-grey);
            margin-top: 2rem;
            margin-bottom: 1rem;
            display: block;

            @media screen and (max-width: 991px) {
                font-size: 1.4rem;
                margin-bottom: .8rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.3rem;
                margin-bottom: .6rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1.2rem;
            }
        }

        .text-content {
            margin-bottom: 1rem;
            display: block;

            @media screen and (max-width: 991px) {
                margin-bottom: .6rem;
            }

            @media screen and (max-width: 767px) {
                margin-bottom: .5rem;
            }

            .bold-text {
                font-weight: 700;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            color: var(--primary);
        }
    }

    ul {
        padding: 0;
        margin-top: 1.8rem;

        @media screen and (max-width: 991px){
            margin-top: 1rem;
            margin-bottom: -.3rem;
        }

        @media screen and (max-width: 767px){
            margin-top: .8rem;
        }

        @media screen and (max-width: 480px){
            margin-top: .7rem;
        }

        li {
            list-style: none;
            display: flex;

            @media screen and (max-width: 991px) {
                margin-bottom: -.1rem;
            }

            @media screen and (max-width: 767px) {
                margin-bottom: -.2rem;
            }

            @media screen and (max-width: 480px) {
                margin-bottom: -.4rem;
            }

            .check-icon {
                color: var(--primary);
                margin-right: .8rem;

                @media screen and (max-width: 991px){
                    margin-right: .8rem;
                    font-size: .8rem;
                }

                @media screen and (max-width: 767px){
                    margin-right: .6rem;
                }

                @media screen and (max-width: 480px){
                    margin-right: .4rem;
                    font-size: .7rem;
                }
            }
        }
    }
`;