import React from 'react';
import PageTitle from '../../components/PageTitle';
import {MainContainer} from '../../globalStyles';
import PrimarySection from '../../components/PrimarySection';
import ResultDriven from '../../components/ResultDriven';
import { HireWrap } from '../../styles/HireFormat.style';
import { FaCheck } from "react-icons/fa";
import PrimaryButton from "../../components/PrimaryButton";





const HireFormat = (props) => {
    return (
        <HireWrap>
            <PageTitle
                title= {props.title}
                text1= {props.text}
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>{props.listitem1}</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>{props.listitem2}</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <div className='designer-image-container'>
                <img src={props.image} alt='artographer' style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
            </div>
            <MainContainer>
                <PrimarySection 
                    title= {props.primarytitle}
                    content= {props.primarycontent1}
                    listItem1= {props.primarylist1}
                    listItem2= {props.primarylist2}
                    listItem3= {props.primarylist3}
                    listItem4= {props.primarylist4}
                    listItem5= {props.primarylist5}
                    button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
                    image= {props.imagecol}
                    alt= {props.alt}
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= {props.header}
                    title1= {props.rdtitle1}
                    content1= {props.rdcontent1}
                    title2= {props.rdtitle2}
                    content2= {props.rdcontent2}
                    title3= {props.rdtitle3}
                    content3= {props.rdcontent3}
                    title4= {props.rdtitle4}
                    content4= {props.rdcontent4}
                    title5= {props.rdtitle5}
                    content5= {props.rdcontent5}
                    title6= {props.rdtitle6}
                    content6= {props.rdcontent6}
                />
            </MainContainer>
        </HireWrap>
    );
}

export default HireFormat;
