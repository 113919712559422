import styled from "styled-components";


export const TabTitles = styled.div`
    display: block;

    .tab-title-container {
        margin-bottom: 2rem;
        border-bottom: 1px solid #D3D3D3;
        text-align: center;

        .title-wrap {
                padding: .8rem 0rem;
                position: relative;

            .tab-name {
                font-size: 1.1rem;
                font-weight: 500;
                list-style: none;
                color: #989898;
                text-decoration: none;
                transition: .5s all ease;

                    &::after {
                        content: '';
                        width: 100%;
                        height: 7px;
                        background-color: var(--primary);
                        border-radius: 5px;
                        display: block;
                        position: absolute;
                        bottom: -4px;
                        transform: scaleX(0);
                    }
                
                &:hover {
                    color: var(--dark-grey);
                    cursor: pointer;

                    &::after {
                        transform: scaleX(1);
                        transition: 300ms ease transform;
                    }
                }

                &::before {
                    content: none;
                }

            }
            .active-name {
                font-size: 1.1rem;
                font-weight: 500;
                list-style: none;
                color: var(--dark-grey);
                text-decoration: none;
                transition: .5s all ease;
                
                &:hover {
                    cursor: default;
                }

                &::before {
                    content: none;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    bottom: -4px;
                }
            }
        }
    }
`;

export const TabContent = styled.div`
text-align: left;

    &::before, &::after {
        display: none;
    }

    .tab-content {
        margin-bottom: 4rem;
        display: none;
        transition: all 1s ease;

        .content-title {
            font-size: 2.5rem;
            font-weight: 600;
            letter-spacing: -1px;
            margin-bottom: 1.1rem;
            margin-top: 3rem;
        }

        .button-wrap {
            width: 100%;
            align-items: center;
            margin-top: 2.5rem;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 767px){
                margin-top: 1rem;
            }

            @media screen and (max-width: 480px){
                padding: 20px 0;
            }

            .button-inner-wrap {
                width: 20%;
                padding: 0 20px 0 0;
                margin-bottom: 2rem;

                @media screen and (max-width: 1199px){
                    width: 25%;
                }

                @media screen and (max-width: 991px){
                    width: 50%;
                }

                @media screen and (max-width: 767px){
                    width: 100%;
                    padding: 0;

                @media screen and (max-width: 480px){
                    margin-bottom: 1.5rem;
                }

                }

                .button-link {
                    min-height: 150px;
                    color: #333;
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 20px;

                    @media screen and (max-width: 480px){
                        flex-direction: unset;
                        align-items: center;
                        min-height: auto;
                        padding: 25px;
                    }

                    .button-main-text {
                        float: left;
                        display: block;
                        font-size: 1.5rem;
                        line-height: 1.9rem;
                        font-weight: 500;

                        @media screen and (max-width: 480px){
                            font-size: 1.3rem;
                            line-height: 1.7rem;
                        }
                    }

                    .button-right-icon {
                        font-size: 2rem;
                        float: right;

                        @media screen and (max-width: 480px){
                            font-size: 1.7rem;
                            margin-left: 1rem;
                        }
                    }
                }

                .bounce-to-right {
                    transform: perspective(1px) translateZ(0);
                    position: relative;
                    transition-property: color;
                    transition-duration: 0.5s;
                    transition-timing-function: ease-out;

                    &:hover {

                        &::before {
                            transform: scaleX(1);
                            transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
                        }

                        .button-main-text {
                            color: white;
                            transition: .5s ease;
                        }
                        
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: var(--primary);
                        transform: scaleX(0);
                        transform-origin: 0 50%;
                        transition-property: transform;
                        transition-duration: 0.5s;
                        transition-timing-function: ease-out;
                    }
                }

                .button-cream {
                    background-color: #FFF8EB;
                    border: 0;
                }
            }
        }
    }

    .active-content {
        display: block;
    }

    
`;