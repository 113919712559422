import React from 'react';
import { DarkContainerWrap, ButtonContainer } from '../styles/DarkContainer.style';
import { LightButton } from '../styles/Buttons.style';
import { Link } from 'react-router-dom';



const DarkContainerSection = () => {
    return (
        <DarkContainerWrap>
            <div className='inner-container'>
                <h3>Know us more...</h3>
                <h3>See what we have done</h3>
                <ButtonContainer>
                    <Link to='/case-studies' className='button-inner-wrap'>
                        <LightButton className='hover-button case-studies'>
                            Get Access to our Case Studies
                        </LightButton>
                    </Link>
                    <Link to='/portfolio' className='button-inner-wrap'>
                        <LightButton className='hover-button portfolio'>
                            View our Work. See our Portfolio
                        </LightButton>
                    </Link>
                </ButtonContainer>
            </div>
        </DarkContainerWrap>
    );
}

export default DarkContainerSection;
