import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function ThemeDevelopment() {
    return(
        <Page>
            <Helmet>
                <title>
                    Website Custom Theme Development Services Company India - Artographer
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/wordpress-theme-development/" />
                <meta 
                    name="descriptions" 
                    content="Website Theme Development - Artographer, a custom website theme development company offers premium website theme development services for India. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Theme Development Services"
                text1= "At Artographer, we understand that every aspect of your website must be unique and also match certain expectations of your product/service niche. Our quality focused PSD to website theme development services will develop a theme that endorses every functional feature while pleasing users visually."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-Service WordPress Theme Development Company Will Fuel the Growth of Your Business"
                    content= "Artographer provides WordPress theme and conversion services at par with current standards to give exceptional user experience. Whether you have a simple one-page photoshop (PSD design) mock-up or multiple pages UI designs, we can help."
                    content2= "Our PSD to WordPress theme development team in India delivers even complicated projects on time. We can transform your pixel-perfect designs into a W3C compliant, fast loading and responsive WordPress CMS theme. Each design undergoes the process of analysis, slicing of PSD files, creating separate file structure, cross-browser compatibility and validation."
                    image= '../images/artographer-theme-development.png'
                    alt= 'artographer theme development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>How do Our WordPress Theme Development Company Work?</SectionTitle>}
                    para1= "In the field of web development, we are witnessing a new trend in the market – more and more websites going to drag-and-drop builders."
                    para2= "This is because clients don’t want us to bill more hours for coding. They want a solution to their problem and make more sales. Our team wants to address this problem – we want to sell strategy and solve the problems of our clients."
                    para3= "Page builders like Elementor, Gutenberg, Beaver builder, and Divi Builder help to build complex websites quickly. But there will still be a need for custom coding and development work. This is where our team comes in. We will do the heavy lifting and deliver powerful WordPress websites."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Theme Development Services"
                content= "Our theme development team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer The Following Result-driven Custom WordPress Theme Development"
                    title1= "SKETCH to WordPress Theme Development"
                    content1= "We offer unparalleled Sketch to WordPress theme development services to help you attain great conversion rates and enhance the value of your business website."
                    title2= "HTML5 to WordPress Theme Conversion"
                    content2= "Regardless of the size of the project, our expert developers can convert HTML coding into a fully functioning WordPress theme. We are also capable of handling intricate HTML to WordPress conversion."
                    title3= "UI Prototypes to WordPress Theme"
                    content3= "In our custom WordPress theme development services, our experienced developers adhere to modern UI components to deliver the best possible UX without compromising on quality."
                    title4= "Squarespace to WordPress Theme Conversion"
                    content4= "Our team of developers specialized in converting Squarespace into pixel perfect and SEO friendly WordPress websites."
                    title5= "Wix to WordPress Theme Conversion"
                    content5= "You can move your Wix website to WordPress seamlessly. Our experienced developers can take care of the whole export process and get you back on track in no time."
                    title6= "Responsive WordPress Theme Development"
                    content6= "Our creative and world-class designers will create a unique, business-focused and easy to manage responsive WordPress theme without compromising on quality."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "Professional WordPress Agency Partner for Outsourcing Digital Agencies"
                    content1= "Artographer invites outsourcing digital agencies to partner with us for WordPress development projects. From white label partnership to NDA, Artographer is open to talk on how we can build our B2B partnership to better serve our customers."
                    content2= "In all our services, we strictly keep up with our values (commitment, quality, and integrity). Our mission is to ease up the workload of your agency by offering quality WordPress solutions. We will work hard for you so that you don’t have to."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Theme Development Services"
                    content= {<span>We have worked with theme developement clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a theme developement services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Theme Development Services'
                    title1="Will my custom WordPress theme be responsive, mobile and search engine friendly?"
                    content1="We design all the themes by keeping these three major aspects in mind. However, to gain maximum SEO visibility, we recommend you to utilize our comprehensive SEO services."
                    title2="Is your WordPress theme development work unique?"
                    content2="Yes, everything we build is 100% unique and custom."
                    title3="Your theme development prices are good, do you outsource your work?"
                    content3="NEVER. We do not outsource our work to any other company. Everything is built with our in-house developers. However, we are open to partnering with other B2B companies to off-load their WordPress theme development needs."
                    title4="I already have a WordPress theme will you customize it?"
                    content4="In most of the cases we do not take up such projects because it takes time to familiarize ourselves with the theme written by another developer."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Plugin Development Services"
                    subTitle3= "Creative Plugin Development Services"
                    title4= "PHP Website Development"
                    subTitle4= "Creative PHP Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Marketing Automation Services"
                    subTitle6= "Creative Marketing Automation Services"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default ThemeDevelopment;