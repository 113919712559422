import styled from "styled-components";



export const FooterContainer = styled.div`
    background-color: var(--dark-blue);
    padding: 60px 100px 15px 100px;
    color: #9DA1A4;

    @media screen and (max-width: 1399px){
        padding: 60px 50px 15px 50px;
    }

    @media screen and (max-width: 767px){
        padding: 60px 35px 15px 35px;
    }

    @media screen and (max-width: 575px){
        padding: 60px 20px 15px 20px;
    }

    .container-top {
        margin-bottom: 3rem;
        font-size: .9rem;
        font-weight: 500;
        text-align: center;
        display: none;
    }
`;


export const FooterTitle = styled.h2`
    display: block;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: #E1E1E1;
`;

export const MiddleContainer = styled.div`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3rem;
    text-align: left;
    text-decoration: none;

    .list-title {
        font-size: 1.1rem;
        color: #E1E1E1;
        font-weight: 600;
        margin-bottom: .6rem;
        display: block;
    }

    .contact-title {
            
        @media screen and (max-width: 1199px) {
            text-align: center;
        }

        @media screen and (max-width: 767px) {
            text-align: left;
        }

        @media screen and (max-width: 480px) {
            text-align: center;
        }
    }

    .contact-ul {

        @media screen and (max-width: 1199px) {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
        }

        @media screen and (max-width: 991px){
            justify-content: space-between; 
        }

        @media screen and (max-width: 767px) {
            display: block;
        }

        li {
            margin-bottom: .8rem;

            @media screen and (max-width: 1199px){
                &:first-child {
                    width: 35%;
                }

                &:nth-child(2) {
                    width: 35%;
                }

                &:last-child {
                    width: 25%;
                }
            }

            @media screen and (max-width: 767px){
                &:first-child {
                    width: 100%;
                }

                &:nth-child(2) {
                    width: 100%;
                }

                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            margin-top: .3rem;
            margin-bottom: .5rem;

            .menu-list-item {
                text-decoration: none;
                color: #9DA1A4;
                font-weight: 400;

                &:hover {
                    color: var(--primary);
                    cursor: pointer;
                }

                &:hover .menu-contact-icon {
                    color: #9DA1A4;
                    cursor: default;
                }

                .menu-contact-icon {
                    margin-right: .5rem;
                    font-size: 1.2rem;
                }

                .call {
                    font-size: 1rem;
                }
            }

            .address {

                &:hover {
                    color: #9DA1A4;
                    cursor: default;
                }
            }
            .email,
            .number {
                
                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .newsletter-container {

        @media screen and (max-width: 767px){
            margin-bottom: 2.5rem;
            text-align: center;
        }
        
        .artographer-text-only-logo {

            img {
                max-width: 250px;
                height: auto;
                object-fit: contain;
                margin-bottom: 1.5rem;

                @media screen and (max-width: 991px){
                    max-width: 200px;
                    margin-bottom: 1rem;
                }

                @media screen and (max-width: 480px){
                    max-width: 170px;
                }
            }
        }
        
        .newsletter-wrap {
            background-color: rgba(255, 255, 255, .08);
            border-radius: 50px;
            border: 1px solid #fff;
            height: 2.6rem;
            width: 90%;
            display: flex;
            align-items: center;
            position: relative;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            .newsletter-input {
                outline: none;
                color: #fff;
                border: none;
                padding-left: 1rem;
                background-color: transparent;

                @media screen and (max-width: 991px) {
                    &::placeholder {
                        font-size: .8rem;
                    }
                }
            }

            .newsletter-btn {
                border: none;
                background-color: transparent;
                color: var(--primary);
                position: absolute;
                right: 3px;
                font-size: 2.2rem;
            }
        }

        .subscribe {
            font-size: .9rem;
            font-weight: 400;
            font-style: italic;
            line-height: 1.5rem;
            margin-left: 1rem;
            color: var(--secondary);

            @media screen and (max-width: 767px){
                margin-left: 0;
            }
        }

        .social-media-icon-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 90%;

            @media screen and (max-width: 767px){
                width: 100%;
                justify-content: center;
                margin-top: 1rem;
            }


            .social-media-icon {
                font-size: 1.2rem;
                color: #9DA1A4 !important;
                margin-right: 1rem;


                &:hover {
                    color: var(--primary) !important;
                    cursor: pointer;
                }
            }

            .twitter {
                font-size: 1.5rem;
                margin-left: 5px;
            }

            .linkedin {
                font-size: 1.5rem;
            }

            .youtube {
                font-size: 1.6rem;
                margin: 0;
            }
        }
    }

    .footer-grid {
        margin-bottom: 2rem;

        @media screen and (max-width: 1199px){
            &:last-child {
                margin-bottom: -1rem;
            }
        }

        @media screen and (max-width: 1199px){
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: 480px){
            margin-bottom: 1rem;
            text-align: center;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export const BottomContainer = styled.div`
    font-size: .8rem;
    text-align: center;

    .footer-link {
        color: #9DA1A4 !important;

        &:hover {
            color: var(--secondary  ) !important;
            cursor: pointer;
        }
    }
`;