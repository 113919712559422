import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";





function MembershipWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Membership, Subscription Portal Website Design and Plugin Development Services Company India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/membership-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Hire a WordPress membership website development expert from Artographer that provides services in USA, India, UK. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Membership Website Development"
                text1= "At Artographer, we provide WordPress membership website design which will help you to build an influential online community. By creating a powerful membership website, we can grow your communities and extend the engagement with members."
                text2= "Our WordPress development company in India uses the perks of this amazing CMS to develop membership websites that include all the desired functionality we agreed to include during the briefing."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "WordPress Membership Website Development"
                    content= "Artographer is involved in WordPress membership website development that lets you build your own membership website. This means you can take full advantage of ‘web 2.0’ with a highly interactive website your users can create profiles on, post comments to, and more."
                    content2= "We have experienced web designers and developers who understand the membership sector to deliver a powerful website for your business."
                    image= '../images/artographer-membership-website.png'
                    alt= 'artographer membership website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Artographer’s WordPress Membership Website Development Services</SectionTitle>}
                    para1= "Artographer provides membership website development in conjunction with a host of other services. We can migrate your website to or from WordPress, we can build independent membership sites and more. Our services include but are not limited to:"
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>New WordPress membership website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Customization of existing membership websites</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>S2Membership WordPress website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Infusionsoft integration</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>iMember360 website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Memerium customization</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Upgrade membership website</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Migrate to and from WordPress</p></div>
                        </>
                    }
                    button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Membership Website Development Services"
                content= "Our membership website development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <LocationbasedService
                    title= "Best Membership Website Development Company"
                    content= "Artographer provides membership website development in conjunction with a host of other services. We can migrate your website to or from WordPress, we can build independent membership sites and more. Artographer is involved in WordPress membership website development that lets you build your own membership website. This means you can take full advantage of ‘web 2.0’ with a highly interactive website your users can create profiles on, post comments to, and more."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <WhiteLabel 
                    title= "Benefits of Outsourcing Membership Website Development to Artographer"
                    content1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Affordable services</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Membership website tailored for your business</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Can work with S2Membership, iMember 360, Memberium, and more</p></div>
                        </>
                    }
                    content2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Through understanding of your business</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Full support</p></div>
                        </>
                    }
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Membership Website Development Services"
                    content= {<span>We have worked with membership website developement clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a membership website developement services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "Shopify Website Development"
                    subTitle4= "Creative Shopify Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MembershipWeb;