import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Kenzi = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Kenzi</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/kenzi"
        />
        <meta
          name="descriptions"
          content="Our expert graphic designers created a stunning logo for Kenzi, leaving the client fully satisfied with the exceptional result."
        />
      </Helmet>

      <PortfolioPage
        title="Logo Design for Kenzi"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/kenzi-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Kenzi"
        industry="Salon and Spa Suppliers"
        requirement="The UK client is an organic cosmetic group that manufactures and supplies wide range of nails and nail implements, disposables and consumables, skin care spa and waxing. The client approached our team for branding elements including logo, brochures, pamphlets, stickers and labels."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/kenzi-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            As the products were spa and cosmetics related, we thought of
            choosing vibrant colors & blends. We picked some random colors from
            the watercolor palettes to further give the logo a subtle look.
            <br />
            <br /> As the client loved the logo we designed, they still continue
            with our team.
          </span>
        }
        bottomImage={<img src="../images/portfolio/kenzi-logo.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/boat-media">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/moexa">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Kenzi;
