import { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { MainContainer, SectionTitle } from "../globalStyles";
import { Helmet } from "react-helmet";
import { Row } from "react-bootstrap";
import { TabTitles, TabContent } from "../styles/TopTab.style";
import { FaCheck } from "react-icons/fa";
import SatisfactionImage from "../components/SatisfactionImage";

function About() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [toggleState, setToggleState] = useState("company-overview");

  const [activeMeta, setActiveMeta] = useState({});

  useEffect(() => {
    const active = searchParams.get("active");
    if (active) {
      setToggleState(String(active));

      if (String(active) == "company-overview") {
        setActiveMeta({
          title:
            "Artographer: Premier Web Design, Branding & Digital Marketing Services in Delhi",
          content:
            "Discover Artographer, your premier Delhi-based provider of web design, branding, and digital marketing, delivering exceptional, client-focused online solutions.",
          url: "https://www.artographertacts.com/about-artographer/?active=company-overview"
        });
      }
      if (String(active) == "engagement-models") {
        setActiveMeta({
          title:
            "Flexible Business Engagement Models: Fixed Cost, Hourly and White Label Services",
          content:
            "Explore Artographer's flexible business engagement models: fixed cost, time-based, hourly, and package-based plans tailored to meet your specific project needs.",
          url: "https://www.artographertacts.com/about-artographer/?active=engagement-models"
        });
      } else if (String(active) == "our-values") {
        setActiveMeta({
          title:
            "Artographer Values: Commitment, Quality, Integrity and Genuine Care for Your Success",
          content:
            "Discover Artographer's core values: Commitment, Quality, Integrity, and Care. We prioritize your success with transparent, professional, and passionate service.",
          url: "https://www.artographertacts.com/about-artographer/?active=our-values"
        });
      }
    } else {
      setToggleState("company-overview");
      setActiveMeta({
        title:
          "About Us | Your Trusted Partner in Web Design, Graphic Design, and Digital Marketing",
        content:
          "Learn about our passion for delivering top-notch web design, graphic design, and digital marketing services, tailored to help your brand thrive and grow.",
        url: "https://www.artographertacts.com/about-artographer/"
      });
    }
  }, [searchParams, setSearchParams]);

  const toggleTab = (e) => {
    setToggleState(e);
  };

  return (
    <>
      <Helmet>
        <title>{activeMeta.title}</title>
        <link rel="canonical" href={activeMeta.url} />
        <meta name="descriptions" content={activeMeta.content} />
      </Helmet>
      <PageTitle title="About - Artographer Tacts" />
      <MainContainer>
        <SectionTitle>
          The place where your search ends and professionalism begins
        </SectionTitle>
      </MainContainer>
      <MainContainer>
        <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
          <SatisfactionImage />
          <TabTitles>
            <div className="tab-title-container">
              <Row className="justify-content-center">
                <div className="col-md-3 col-sm-4 col-7">
                  <div className="title-wrap">
                    <Link to='/about-artographer/?active=company-overview'
                      className={
                        toggleState === "company-overview"
                          ? "active-name"
                          : "tab-name"
                      }
                      onClick={() => toggleTab("company-overview")}
                    >
                      Company Overview
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-7">
                  <div className="title-wrap">
                    <Link to='/about-artographer/?active=engagement-models'
                      className={
                        toggleState === "engagement-models"
                          ? "active-name"
                          : "tab-name"
                      }
                      onClick={() => toggleTab("engagement-models")}
                    >
                      Engagement Models
                    </Link>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-7">
                  <div className="title-wrap">
                    <Link to='/about-artographer/?active=our-values'
                      className={
                        toggleState === "our-values"
                          ? "active-name"
                          : "tab-name"
                      }
                      onClick={() => toggleTab("our-values")}
                    >
                      Our Values
                    </Link>
                  </div>
                </div>
              </Row>
            </div>
          </TabTitles>
          <TabContent>
            <div
              className={
                toggleState === "company-overview"
                  ? "tab-content active-content"
                  : "tab-content"
              }
            >
              <p>
                Operating out of Delhi, Artographer is your premier choice for
                online services. We provide a wide variety of digital services
                including web design, branding, digital marketing and much more.
                Our team has a wide range of skills and expertise and we always
                put our client's satisfaction first.
              </p>
              <p>
                This is what sets us apart from the competition - an eye for
                detail and the best service from the start to the completion of
                your project. Quite simply, we offer the most reliable and
                effective services to any company looking for a whole host of
                online solutions.
              </p>
            </div>
            <div
              className={
                toggleState === "engagement-models"
                  ? "tab-content active-content"
                  : "tab-content"
              }
            >
              <span className="title-dark">Business Engagement Models</span>
              <p>
                Depending on the requirements of your organization, we offer a
                number of business models and price plans. This makes
                Artographer one of the most flexible service provider in the
                industry.
              </p>
              <ul>
                <li>
                  <span>
                    <FaCheck className="check-icon" />
                  </span>
                  <p>
                    <span className="font-bold">Fixed Cost Model</span> - For
                    our fixed cost model, we will agree a fixed price that won't
                    change after a consultation. This works best for tech
                    companies and web entrepreneurs who know their precise
                    requirements and exactly which services they need.
                  </p>
                </li>
                <li>
                  <span>
                    <FaCheck className="check-icon" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Time and Material Based Model
                    </span>{" "}
                    - This is the most suitable model for long-term projects,
                    where scope is partially determined, so we discuss an hourly
                    rate and timescale. We will then discuss any other factors
                    that may affect the projected price.
                  </p>
                </li>
                <li>
                  <span>
                    <FaCheck className="check-icon" />
                  </span>
                  <p>
                    <span className="font-bold">Hourly Based Model</span> - When
                    there is an idea of scope but no price estimation has
                    occurred, we will work at an hourly model. This model is
                    flexible and suitable for a wide range of clients.
                  </p>
                </li>
                <li>
                  <span>
                    <FaCheck className="check-icon" />
                  </span>
                  <p>
                    <span className="font-bold">Package Based Model</span> - We
                    offer a variety of fixed-price packages tailored according
                    to your development needs and projects.
                  </p>
                </li>
              </ul>
              <div className="engagement-image">
                <img
                  src="../images/engagement-models.webp"
                  alt="engagement"
                  style={{
                    width: "100%",
                    height: "100%",
                    onjectFit: "contain",
                  }}
                />
              </div>
              <span className="title-light">Market Overview</span>
              <p>
                Web design and digital marketing is a rapidly growing industry
                that’s showing no sign of slowing down. Did you know that there
                are 70 new domains registered and 571 new websites created every
                single minute on the web? With many of these sites being owned
                by businesses, that’s a lot of competition for your
                organization. Companies with over 50 employees will spend around
                $63,000 on SEO, video production and web design; how much are
                you spending?
              </p>
              <p>
                Not only is this an industry that’s constantly growing, it’s
                also one that’s constantly changing and developing. Web design,
                development and digital marketing are always evolving and so
                being up-to-date is crucial.
              </p>
              <p>
                Many mobile users are forced to install hundreds of apps, simply
                because the websites they want to use don’t display correctly on
                their mobile devices. For example, today’s websites must use
                ‘responsive design’ to ensure that they can adapt their layout
                for both desktop and mobile devices of all sizes.
              </p>
              <span className="title-light">Reliable Website Outsourcing</span>
              <p>
                In order to meet these demands, businesses should outsource
                their web design and development requirements to companies that
                can offer the service at the best rate. That’s why many
                companies today turn to Indian vendors who can offer the very
                best value for money. India is well known for its IT services
                and the best Indian companies can offer highly-scalable
                solutions to meet any criteria within your budget.
              </p>
              <span className="title-light">
                White Label Development Services
              </span>
              <p>
                Artographer offers white label services, keeping our branding
                completely invisible. Your brand will be the only one that
                visitors see. And if you’re a web design or marketing agency
                looking to broker our skills, that’s perfectly fine too.
              </p>
            </div>
            <div
              className={
                toggleState === "our-values"
                  ? "tab-content active-content"
                  : "tab-content"
              }
            >
              <span className="title-light">Commitment</span>
              <p>
                Here at Artographer, we’re incredibly proud of what we do and
                highly passionate about providing the best possible services to
                help you take your business to the next level and increase your
                online visibility. That’s why we are 100% committed to your
                satisfaction and to meeting your goals. We ensure that the
                client is happy with the end product because we understand just
                how important your business is to you.
              </p>
              <span className="title-light">Quality</span>
              <p>
                Shopping online for web design and marketing services can
                sometimes bring a mixed quality of results. That’s why it’s so
                highly important to make sure you are discerning when choosing
                which companies to work with and to represent you. We promise
                you that we can deliver the very best quality of work every
                single time to make certain that you get the results you want
                and that your brand is represented with the utmost
                professionalism and care.
              </p>
              <span className="title-light">Integrity</span>
              <p>
                Integrity is the cornerstone of our business. We know just how
                much your company and your business mean to you and we are
                honored to be able to play a part in your success. That’s why we
                are completely transparent at every stage in the process. We
                will communicate with you at all times and make sure that you
                feel in control the entire time you work with us. Artographer is
                completely at your service and you can rely on us to get the job
                done to the very highest standard.
              </p>
              <span className="title-light">Care</span>
              <p>
                We offer such integrity, quality and commitment all for one
                important reason: we genuinely care. Our main priority is in
                ensuring that you are happy with your results so that your
                business can succeed and seek our digital web solutions time and
                time again. When you are working on a website or marketing
                yourself on social media, you need a company that cares as much
                as you do – and that’s precisely what we offer.
              </p>
            </div>
          </TabContent>
        </div>
      </MainContainer>
    </>
  );
}

export default About;
