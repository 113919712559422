import styled from "styled-components";


export const ScreenTitle =styled.h3`
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.7rem;
    letter-spacing: -1px;
    color: var(--dark-grey);

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
    }
`;

export const Screen1 = styled.div`
    display: block;

    .raq-form-control {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        flex-wrap: wrap;
    }
`;

export const ListItem = styled.div`
    margin-left: -8px;
    margin-right: -8px;
`;

export const Screen2 = styled.div`
    display: block;
    margin-top: 4rem;

    @media screen and (max-width: 480px){
        margin-top: 3rem;
    }

    .raq-form-control {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const Screen3 = styled.div`
    display: block;
    margin-top: 5rem;

    @media screen and (max-width: 480px){
        margin-top: 3rem;
    }

    .raq-dts-field-top {
        margin-top: 2rem;

        @media screen and (max-width: 480px){
            margin-top: 1rem;
        }

        .dts-field-block {
            display: block;
            text-align: left;
            padding: 0;

            @media screen and (max-width: 991px){
                margin-bottom: 1rem;
            }
        }

        .flag {

            .tel-container {
                display: flex;
                align-items: center;
                height: 2.7rem;
                border: 1px solid #D0D0D0;
                border-radius: 10px;
                background-color: white;
                    

                .tel-box {
                    display: flex;
                    margin-left: 1rem;

                    .select-box {
                        width: 1.5rem;
                        height: 1rem;
                        position: relative;
                    

                        .flag-img {
                            width: 1.5rem;
                            height: 1rem;
                            position: absolute;
                        }

                        select {
                            font-size: .9rem;
                            font-weight: 400;
                            width: 1.5rem;
                            height: 1rem;
                            background: none;
                            position: absolute;
                            padding-top: 1rem;
                            border: none;
                            outline: none;
                            appearance: none;
                            -moz-appearance: none;
                            -webkit-appearance: none;
                            cursor: pointer;
                        }
                    }
                }
            }

            .country-code {
                margin-left: .8rem;

                .result {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.2rem;
                    color: var(--dark-grey);
                }
            }

            input {
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.2rem;
                padding-left: .5rem;
                width: 100%;
                height: 2rem;
                border: none;
                color: var(--dark-grey);
                background: none;

                &:focus {
                    outline: none;
                }
            }
        }

        .dts-field-eg {
            display: block;
            font-size: .9rem;
            font-weight: 400;
            line-height: 1.4rem;
            font-style: italic;
        }

        input {
            border: 1px solid #D0D0D0;
            border-radius: 10px;
            height: 2.7rem;
            width: 100%;
            font-size: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;

            @media screen and (max-width: 480px){
                font-size: .8rem;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-size: .9rem;
                font-weight: 700;
                line-height: 1.2rem;
                font-style: italic;
                color: var(--light-gray);

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                    font-weight: 600;
                }
            }
        }

        .raq-dts-field-middle {
            margin-top: 2rem;
            text-align: left;

            @media screen and (max-width: 991px){
                margin-top: 0;
            }

            textarea {
                font-size: 1rem;
                font-weight: 400;
                height: 180px;
                width: 100%;
                border: 1px solid #D0D0D0;
                border-radius: 10px;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: .5rem;

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                }

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-size: .9rem;
                    font-weight: 700;
                    line-height: 1.2rem;
                    font-style: italic;
                    color: var(--light-gray);

                    @media screen and (max-width: 480px){
                        font-size: .8rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .raq-dts-field-bottom {
        text-align: center;
        margin-top: 1rem;

        @media screen and (max-width: 480px){
            margin-bottom: 4rem;
        }

        .lt-field-button-wrap {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                font-size: 1.2rem;
                font-weight: 600;
                color: white;
                background-color: var(--primary);
                border: none;
                padding: .7rem 4rem;

                @media screen and (max-width: 767px){
                    font-size: .8rem;
                    padding: .7rem 3rem;
                }

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                    padding: .6rem 2rem;
                }
            }

            .process-spinning-icon {
                display: inline-block;
                background-color: #23282d;
                opacity: .75;
                width: 24px;
                height: 24px;
                border: none;
                border-radius: 100%;
                padding: 0;
                margin: 0 24px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: #fbfbfc;
                    top: 4px;
                    left: 4px;
                    width: 6px;
                    height: 6px;
                    border: none;
                    border-radius: 100%;
                    transform-origin: 8px 8px;
                    animation: spin 1s infinite linear;
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
`;

export const ListItem2 = styled.div`
    margin-left: -8px;
    margin-right: -8px;
`;

export const WrapCheckbox1 = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1.4rem;
    

    label {}

    input {
        display: none;
    }

    .list-item-label {
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: var(--dark-grey);
        background-color: var(--background);
        padding: 1.6rem;
        width: 170px;
        height: 100px;
        border-radius: 10px;
        display: grid;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 480px){
            font-size: 1rem;
            width: 250px;
            height: 80px;
        }

        &:hover {
            color: white;
            background-color: var(--primary);
        }
    }

    .active {

        &::after {
            content: '';
            background-image: url('../images/check-icon.png');
            background-size: cover;
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            background-color: var(--secondary);
            left: 43%;
            bottom: -13px;
            z-index: 100;
        }
    }
`;

export const WrapCheckbox2 = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1.4rem;
    

    label {
        display: inline-block;
    }

    input {
        display: none;
    }

    .list-item-label {
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.4rem;
        color: var(--dark-grey);
        background-color: var(--background);
        padding: .9rem;
        width: 216px;
        height: 50px;
        border-radius: 10px;
        display: inline-block;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 480px){
            font-size: 1rem;
            width: 250px;
        }

        &:hover {
            color: white;
            background-color: var(--primary);
        }

    }

    .active {

        &::after {
            content: '';
            background-image: url('../images/check-icon.png');
            background-size: cover;
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            background-color: var(--secondary);
            left: 43%;
            bottom: -15px;
            z-index: 100;
        }
    }
`;
