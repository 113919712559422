import styled from "styled-components";



export const ProcessTabWrap = styled.div`
    margin-top: 8rem;

    @media screen and (max-width: 767px){
        margin-top: 5rem;
    }

    .process-title {
        display: flex;
        width: 100%;

        .process-title-tab {
            width: 33.3%;
            text-align: center;
            position: relative;

            .bold-number {
                font-size: 8rem;
                font-weight: 800;
                font-family: 'Poppins',sans-serif;
                color: transparent;
                position: absolute;
                top: -4.8rem;
                left: 5rem;
                z-index: -1;
                -webkit-text-stroke: 3px var(--primary);
                transition: .5s ease;

                @media screen and (max-width: 767px){
                    font-size: 6rem;
                    font-weight: 800;
                    -webkit-text-stroke: 2px var(--primary);
                    top: -3.9rem;
                    left: 2.23rem;
                }

                @media screen and (max-width: 480px){
                    font-size: 5rem;
                    font-weight: 800;
                    -webkit-text-stroke: 2px var(--primary);
                    top: -3.26rem;
                    left: .63rem;
                }
            }

            h4 {
                font-size: 3rem;
                font-weight: 700;
                font-family: 'Poppins',sans-serif;
                text-transform: uppercase;
                width: fit-content;
                margin: auto;

                @media screen and (max-width: 767px){
                    font-size: 2rem;
                    font-weight: 800;
                }

                @media screen and (max-width: 480px){
                    font-size: 1.7rem;
                    font-weight: 800;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .active {

            .bold-number {
                font-size: 8rem;
                font-weight: 800;
                color: var(--primary);
                position: absolute;
                top: -4.8rem;
                left: 5rem;
                z-index: -1;

                @media screen and (max-width: 767px){
                    font-size: 6rem;
                    font-weight: 800;
                    top: -3.85rem;
                    left: 2.23rem;
                }

                @media screen and (max-width: 480px){
                    font-size: 5rem;
                    font-weight: 800;
                    top: -3.26rem;
                    left: .63rem;
                }
            }
        }
    }

    .process-content {
        margin-top: 3rem;
        text-align: left;

        @media screen and (max-width: 480px){
            margin-top: 1.5rem;
        }

        .process-content-tab {
            display: none;

            .process-content-left {

                h4 {
                    font-size: 1.5rem;
                    line-height: 1.9rem;
                    font-weight: 600;
                    color: gray;
                    margin-bottom: .8rem;

                    @media screen and (max-width: 767px){
                        font-size: 1.1rem;
                        line-height: 1.4rem;
                        margin-bottom: .4rem;
                    }
                }

                .process-button {
                    font-size: 1.1rem;
                    font-weight: 500;
                    color: var(--secondary);
                    margin-top: 2rem;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    transition-property: transform;

                    @media screen and (max-width: 767px){
                        font-size: .9rem;
                        margin-top: 1rem;
                    }

                    &:hover {
                        transform: translateX(10px);
                    }

                    .link-btn {
                        cursor: pointer;
                        text-decoration: none;
                        color: var(--secondary);

                        &:hover {
                            color: var(--secondary);
                        }
                    }
                }
            }

            .process-content-right {
                margin-left: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 991px){
                    margin-left: 0;
                }

                @media screen and (max-width: 767px){
                    margin-left: 0;
                    margin-top: 2rem;
                }

                img {
                    max-height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .active {
        display: block;
        }
    }

`;
