import React from 'react';
import { PageStyle } from '../styles/privacy&Terms.style';
import PageTitleFullWidth from '../components/PageTitleFullWidth';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Helmet } from 'react-helmet';




const PrivacyPolicy = () => {
    return (
        <PageStyle>
            <Helmet>
                <title>
                    Our Privacy Policy | Artographer
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/privacy-policy/" />
                <meta 
                    name="descriptions" 
                    content="Artographer privacy policy statement."
                />
            </Helmet>
            <PageTitleFullWidth
                title= "Privacy Policy"
            />
            <MainContainer style={{textAlign: 'left'}}>
                <p className='text-content'>Updated on January 2022.</p>
                <p className='text-content'>We value the privacy of everyone who visits our website. Protecting your private information is our top priority. By submitting your personal data, you are giving us permission to use it as stated in the policy.</p>
                <p className='text-content'>We encourage you to carefully go through the privacy policy before you give any information.</p>
                <div className='section-block'>
                    <span className='block-title'>
                        What Does This Privacy Policy Cover?
                    </span>
                    <p className='text-content'>This privacy policy will explain how we use your personal data and other information when you access our website. This policy does not apply to third party websites that we do not own example, Google, Facebook, Twitter etc. We cannot take responsibility for their privacy policy and practices.</p>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>All the trademarks on this website belong to us or are licensed by those trademark owners for usage on our website. The trademarks present in our website that are not a property of Artographer e.g.Facebook, are given proper acknowledgement</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Illegal use of this website may give rise to a claim for damages or be a criminal offence</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You may not copy (print, store, download), distribute, broadcast, or tamper with any of the content present in the website</p>
                        </li>                    
                    </ul>
                </div>
                <div className='section-block'>
                    <span className='block-title'>
                        Information We Collect
                    </span>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>As we have partnered with HubSpot, we adhere to follow their terms and policies. On that note, we collect the following personal details for business purpose and non-personally identifiable information from the users via fillable forms</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>First name, last name, email, country, city and other information you provide</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Content and other information you provide when using our website. Eg. Comments you add on our blogs, information you submit when you participate in our surveys</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Browser version, OS type, pages you browse in our website, date and time of your visit, domain names, exit pages/URLs, interaction information, and other similar technical data</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Location information provided by a mobile device or associated with your IP address</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <span className='block-title'>
                        How We Collect Information From You?
                    </span>
                    <p className='text-content'>We collect information you submit to us. For example, when you request product or service information from us, register for an account, when you reply to emails we send, register to participate in conferences or webinars we conduct, or when you respond to our customer surveys. We also,</p>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Automatically collect usage, analytics, metrics, and other technical data</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Get information about you from our resellers or other third-party partners</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Get information from our advertising and market research partners</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Sources, such as public databases; joint marketing partners, social media platforms; or other third parties</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <span className='block-title'>
                        How We Use The Information?
                    </span>
                    <ul>
                        <p className='text-content'>We use the collected information to,</p>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Respond to queries/requests</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Process orders</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Resolve any problems with services/products provided</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Prevent activities that may violate our policies</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Generate services/products that meet your needs</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Enhance our services, we will get your consent to use your personal data for direct marketing</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <span className='block-title'>
                        Sharing Information With Others
                    </span>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>We do not share your personal information outside of Artographer. However, we may unfold your personal data in certain circumstances, that includes,</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Response to a lawful request by public authorities</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>A court order</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Safeguard the property or rights of our company</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Investigate/prevent violation in relation to the services we offer</p>
                        </li>
                    </ul>
                    <p className='text-content'>In the event of a reconstruction or liquidation, your personal information may be passed on to a successor in interest</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Cookies Policy
                    </div>
                    <span className='block-title'>
                        What Are Cookies?
                    </span>
                    <p className='text-content'>Cookies are nothing but small files that are stored in your computer that hold a meagre amount of data about the sites you visit. These files have information about the web page you visit, as well as any other information you’ve given such as name and interests.</p>
                    <span className='block-title'>
                        Why Do We Use Cookies?
                    </span>
                    <p className='text-content'>Cookies help us to know how you interact with our content and help us to improve your experience when you visit our site. Through cookies, we can know your language preference so that you needn’t change it for every visit. Also, from your activity on our website, we can target advertisements on third-party websites so that we can re-market our products/services to you.</p>
                    <p className='text-content'>In addition to this, there are the types of cookies you need to know about</p>
                    <span className='block-title'>
                        Session Cookies
                    </span>
                    <p className='text-content'>Session cookies are temporary and are used to remember you when you visit our site. They expire when the web browser is closed.</p>
                    <span className='block-title'>
                        Persistent Cookies
                    </span>
                    <p className='text-content'>Persistent cookies remain on your computer/mobile device even after you close the web browser or restart your computer. With this data, we come up with a visit pattern to improve the functionality of the site. These cookies are also used for targeted advertising.</p>
                    <span className='block-title'>
                        First Party and Third Party Cookies
                    </span>
                    <p className='text-content'>We use first-party as well as third-party cookies on our website.</p>
                    <p className='text-content'>First-party cookies mainly belong to the website you’re currently visiting. Third-party cookies are cookies that belong to other websites that are mainly used for outside advertising.</p>
                    <span className='block-title'>
                        How To Reject And Delete Cookies?
                    </span>
                    <p className='text-content'>Keep in mind that if cookies are disabled you might not take full advantage of a website you’re visiting and may impair your experience. You can reject and delete cookies by simply changing your browser settings.</p>
                    <p className='text-content'>Visit http://www.allaboutcookies.org/ to know more on how to reject or delete them</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Social Media
                    </div>
                    <p className='text-content'>Our website includes social media features like Facebook ‘like’ button and Twitter ‘share’ button. Your interactions with such type of features are governed either by the privacy policy or terms & conditions, of the company providing it.</p>
                    <span className='block-title'>
                        Third-Party Links
                    </span>
                    <p>Our website may contain links to third-party websites. If you click on those links, it will take you outside of our website. We have no control over the privacy policy or terms of those third-party websites. So we encourage you to read the privacy statements of every website you visit.</p>
                    <span className='block-title'>
                        Data Retention
                    </span>
                    <p className='text-content'>We may retain your personal information to provide the service you requested, to comply with legal/tax/accounting requirements or until it fulfills the purpose outlined in our privacy policy. When we have no business reasons to process your personal data, we will either delete it or securely store it and isolate it from further processing.</p>
                    <span className='block-title'>
                        Choice/Opt-Out Rights
                    </span>
                    <p className='text-content'>You have the following data protection rights.</p>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You can contact us to know the personal data we have collected about you and how we use it. You can request to access, correct or delete your personal data.</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You may restrict the processing of your personal data. In such a case, we will not be able to deliver our services to you</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You may object use of personal data if you think that it is used for other purposes set out above</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You have the right to receive your personal data from us in a structured and readable format</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You have the right to opt-out from receiving marketing communications from us. You can exercise this right by just clicking on ‘unsubscribe’ or ‘opt-out’ option in the emails we send you. If you want to opt-out of postal communications, please contact us</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Children’s Privacy
                    </div>
                    <p className='text-content'>Protecting the privacy of children is important to us. We do not collect or solicit personal information from users under the age of 18.</p>
                    <p className='text-content'>If we come to know that we have gathered personal information about children without parental or guardian consent, we will take the necessary steps to immediately remove the data from our server.</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        GDPR
                    </div>
                    <p className='text-content'>Artographer ‘Contact us’ form is compliant with GDPR regulations. If you proceed further, we will consider that you have given your consent to receive requested information/data. We do not make any assumptions, we take all the actions based on the transparent affirmation by users who agree to be physically contacted.</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Contact Us
                    </div>
                    <p className='text-content'>We may modify or update the privacy policy anytime. So, we advise you to review this policy periodically. We welcome your views on our website and privacy policy. Please <a href='/contact-us'>contact us</a> for any queries/clarifications.</p>
                </div>
            </MainContainer>
        </PageStyle>
    );
}

export default PrivacyPolicy;
