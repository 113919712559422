import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from 'react-gtm-module'
import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';




const tagManagerArgs = {
  gtmId: 'GTM-M429229'
}

TagManager.initialize(tagManagerArgs)


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//       <Router>
//         <App />
//       </Router>
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Router><App /></Router>, rootElement);
} else {
  render(<Router><App /></Router>, rootElement);
}

reportWebVitals();
