import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import HireFormat from './HireFormat';
import { SectionTitle } from '../../globalStyles';
import PlanCost from '../../components/PlanCost';
import { FaChevronCircleRight } from 'react-icons/fa';
import {MainContainer} from '../../globalStyles';
import { HireWrap } from '../../styles/HireFormat.style';
import Divider from '../../components/Divider'
import WhiteLabel from '../../components/WhiteLabel';
import Form from '../../components/Form';
import RelatedServices from '../../components/RelatedServices';
import OneRoof from '../../components/OneRoof';
import Certification from '../../components/Certification';
import {Helmet} from 'react-helmet';



const GraphicDesigner = () => {
    return (
        <>
            <Helmet>
                <title>
                    Hire Graphic Designer India, Monthly Dedicated Graphic Designers
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/hire-a-graphic-designer/" />
                <meta 
                    name="descriptions" 
                    content="Hire a graphic designer from Artographer, India. Hire a remote dedicated graphic designer from us for monthly dedicated graphic design works & services."
                />
            </Helmet>
            <HireWrap>
                <HireFormat
                    title= 'Hire a Graphic Designer'
                    text= 'With a perfect mixture of professionalism and sound knowledge, our graphic designers will put together a design that will create a powerful identity for your business.'
                    listitem1= 'White Label Partnerships'
                    listitem2= 'Startup Business Partnerships'
                    image= '../../images/hire-a-graphic-designer.webp'
                    primarytitle= ' Hire Graphic Designers For Monthly Services'
                    primarycontent1= 'Getting the right look and feel for your brand is what our expert graphic designers do best. Whether you want to hire one graphic designer or an entire team, we have a large pool of creative minds who think out of the box. No matter what your requirement is, our graphic designers will give you amazing solutions to help get the maximum ROI.'
                    primarycontent2= 'Our designer’s main goal is to create stunning visuals to communicate your message and reveal your brand’s unique identity. Whether you need a logo, brochure, or business card, our designers can deliver attention-grabbing creative designs.'
                    imagecol= '../images/artographer-hire-graphic-designer.png'
                    alt= 'artographer digital marketing'
                    header= 'What Artographer’s Remote Graphic Designer can do for You?'
                    rdtitle1= 'Logo Design'
                    rdcontent1= 'We understand that logos are an important part of creating brand identity. Our designers can help you stand out by creating a simple yet appealing logo. We give you final deliverable in the following formats – high-resolution JPG, transparent PNG, vector formats including .ai and PDF.'
                    rdtitle2= 'Email Template Designs'
                    rdcontent2= 'Hire a graphic designer from us to create hand-coded mailchimp friendly design templates that will help you engage with customers and generate leads.'
                    rdtitle3= 'Newsletter Designs'
                    rdcontent3= 'When you hire a graphic designer from us, we will create the best newsletter designs that will have consistency and responsiveness for a pleasant user experience. We design newsletters in magazine style, email design, hybrid style, single topic style, and brand focused designing.'
                    rdtitle4= 'Brochure Design'
                    rdcontent4= 'We can help you with your brochure marketing by designing brochures or flyers with unique cuts and folds.'
                    rdtitle5= 'Banner Ad Designs'
                    rdcontent5= 'To make your advertising more effective, our designers can create stunning creatives, GIF, and static banner design for web, social media and mobile apps to reflect your brand value in a positive way.'
                    rdtitle6= 'Website Designs'
                    rdcontent6= 'The first thing that attracts users to your business is your website. When you hire our graphic designers, we can design you the best possible responsive layout for multiple interactions. We use Sketch and top pro designer tools to create stunning designs. If required, we can give an output that is compatible with multiple devices for Sketch and developer friendly layered outputs.'
                />
                <MainContainer>
                    <SectionTitle className="plan-cost-section-title">Our Dedicated Graphic Designer Pricing & Packages</SectionTitle>
                    <div className="ldcs-section-container">
                        <Row>
                            <Col sm={12} md={4}>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Stratup Pack</span>}
                                    head3= {<span className="sub-head">More Than 10 hours</span>}
                                    price= "20"
                                    intro= "*Per hour"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Social Media Graphics</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Infographic Designs</span></span>}
                                    listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Website Designs</span></span>}
                                />
                            </Col>
                            <Col sm={12} md={4}>
                                <PlanCost
                                    head2= {<span className="ldcs-tab-head primary">Medium Pack</span>}
                                    head4= {<span className="sub-head">More Than 50 hours</span>}
                                    price= "199"
                                    intro= "*Per hour"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Logo Designs</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Email Template Designs</span></span>}
                                    listItem6= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Newsletter Designs</span></span>}
                                    discount= "* 10% Off on Quarterly Billing"
                                />
                            </Col>
                            <Col sm={12} md={4}>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Dedicated Designer</span>}
                                    head3= {<span className="sub-head">Full Month</span>}
                                    price= "--"
                                    intro= "Lets discuss"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Website Designs</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Brochure Designs</span></span>}
                                    listItem7= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Banner ad Designs</span></span>}
                                />
                            </Col>
                        </Row>
                    </div>
                </MainContainer>
                <Divider />
                <MainContainer>
                    <WhiteLabel 
                        title= 'Why Should You Hire a Graphic Designer from Artographer?'
                        contentbold1= 'Clean and unique graphic designs'
                        content1= 'Our designers will design you an impressive, clean, and unique designs that help to put across your business message.'
                        contentbold2= 'Timely communication'
                        content2= 'Our designers will communicate everything with you to make sure everything is going according to the plan and to fulfill your design goals and objectives.'
                    />
                </MainContainer>
                <Form />
                <MainContainer>
                    <RelatedServices
                        title= "Logo Design Services"
                        subTitle= "Creative Graphic Design Services"
                        title2= "Mobile App UI & UX Design"
                        subTitle2= "Mobile app development | Develop android app | IOS application development"
                        title3= "Startup Website Development"
                        subTitle3= "Creative Startup Website Development"
                        title4= "E-commerce Website Development"
                        subTitle4= "Creative E-commerce Website Development"
                        title5= "Digital Marketing Services"
                        subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                        title6= "Social Media Marketing"
                        subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                    />
                </MainContainer>
                <OneRoof />
                <Certification />
            </HireWrap>
        </>
    );
}

export default GraphicDesigner;
