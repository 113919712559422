import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import Hire from "../components/Hire";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from '../components/Divider';
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";


function WordPress() {
    return(
        <Page>
            <Helmet>
                <title>
                    WordPress Development Services Company India | Custom WordPress Website Design Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/wordpress-development-services/" />
                <meta 
                    name="descriptions" 
                    content="We are one of the top WordPress development company in India 💫 Contacts us to know more about our full-stack custom WordPress design and development services for businesses of all sizes. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "WordPress Development Services"
                text1= "We are a WordPress web development company in India with more than 10+ years of experience in developing custom WordPress websites. By harnessing the power of WordPress CMS, Artographer dominates the face of website development industry by delivering exceptional WordPress development services."
                text2= "We also facilitate our clients to hire WordPress developers to work on simple as well as complex WordPress project development requirements."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "About Our WordPress Development Services"
                    content= "Being a WordPress development company in India, Artographer has been successfully providing WordPress website solutions and continuously upgrading its skills to better serve client needs. With over 10+ years of experience in custom WordPress development, our developers have successfully executed and delivered more than 600+ projects."
                    content2= "Our WordPress developers are well-versed with the latest WordPress version releases and follow the community updates regularly. We always take an agile approach in every project we work in order to deliver responsive and fully functional WordPress websites. Our designers and developers follow a creative workflow with all our custom WordPress theme development projects to ensure successful completion of the work on the stipulated time frame."
                    image= '../images/artographer-wordpress-website.png'
                    alt= 'artographer wordpress website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    para1bold= {<span className="bold-text">How do Our WordPress Development Company Work?</span>}
                    para2= "BPerhaps you have heard! Gutenberg is the new visual block editor that was launched on December 6th, 2018 along with WordPress 5.0. This editor adds new abilities, libraries, toolsets within the WordPress CMS core. Since this is a core update, skills in JavaScript, React, PHP, and HTML/CSS are necessary to make use of the full potential of WordPress 5.0."
                    para3= "If you have no idea where to begin or face any unresolvable complications which you are too busy to fix it yourself, Artographer team is here to help. Our dedicated team of WordPress developers is always ready to support and bring you out of any complex situations."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our WordPress Development Services"
                content= "Our WordPress development team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven WordPress Development Solutions"
                    title1= "WordPress Web Development"
                    content1= "Secure and high-quality code is just the start. Our custom WordPress web development services company take an innovative approach to deliver fast, reliable and responsive websites in-par with your business needs."
                    title2= "WordPress Migration"
                    content2= "Migration no longer needs to be a daunting task. Through our professional WordPress migration services, you can convert your website to WordPress from Squarespace, Wix, Joomla, Drupal or any other system."
                    title3= "Custom Plugin Development"
                    content3= "Do you want to add indispensable features and functionalities to your WordPress website? Our WordPress plugin development services company can help to add a variety of features to your website apart from standard functions."
                    title4= "WordPress Theme Development"
                    content4= "Our team can create a website that is unique and eye-catchy through our WordPress theme development services. The aim of our WordPress developers is to build a professional website with a theme of your choice."
                    title5= "WordPress SEO"
                    content5= "It is no surprise that everyone wants to get a good search engine ranking. Our team of experienced WordPress SEO experts can help your website rank high on search engines like Google, Bing, Facebook, Yahoo, Baidu and others."
                    title6= "Third-Party API Integration"
                    content6= "We have long-term experience in integrating most-used and widely-accepted APIs to the WordPress CMS. Our expert WordPress REST API developers can make the APIs work perfectly as per your needs."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label WordPress Website Development Services"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label WordPress outsourcing is suitable for agencies that are looking for extra development resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits. Our expert WordPress developers will make sure all the pieces of WordPress website design, development and maintenance are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.<br /><br />All of our B2B services are designed to off-load the WordPress development needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized WordPress Development Services"
                    content= {<span>We have worked with WordPress developement clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a WordPress developement services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <Hire
                    title= "Hire Remote Working WordPress Developers"
                    content= "At Artographer, our WordPress developers have built over 1000+ WordPress websites and collected a wealth of experience that is integrated into our practices and capabilities. When you hire a remote WordPress developer from our team, you will get the capacity you need to deliver your projects on time."
                    image= {<img src="../images/Industries-we-serve.svg" alt=""/>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our WordPress Development Services'
                    title1="Which WordPress frameworks are you experienced in?"
                    content1="We excel at Elementor, Avada, Divi WP theme, StudioPress Genesis, Pro WP theme, and ACF WordPress development."
                    title2="How do I monitor the WordPress project development?"
                    content2="Don't panic about it! Other than your logo design package fee, we will not ask for any other hidden charges from you at any time."
                    title3="How long will it take to complete my WordPress development project?"
                    content3="WordPress websites can be developed within 3 business days too. But it depends on the details we have beforehand, the complexity and size of the WordPress website development project. So we cannot give an exact time limit."
                    title4="What is the payment process?"
                    content4="Payments are taken in two phases, 50% when the work starts and 50% after completion. We accept payments through Paypal, wire transfer, or direct bank account."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Logo Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default WordPress;