import styled from "styled-components";


export const Thankyoucontainer = styled.div`


    .right-container {
        text-align: left;

        p {
            margin-bottom: 1.5rem;
        }

        .btn-container {
            margin-top: 4rem;
        }
    }
`;