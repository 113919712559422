import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function BrochureDesign() {
    return(
        <Page>
            <Helmet>
                <title>
                    Brochure Design Company India | Best Brochure Design Services India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/brochure-design/" />
                <meta 
                    name="descriptions" 
                    content="Get Best Brochure Design Services India from Artographer Agency – Team of Professional Brochure Designers / Brochure Makers. We are the #1 Rated Online Brochure Design Company India. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Brochure Design Services"
                text1= "We are Brochure Guru offering state-of-the-art brochure design services. We are a brochure design India company providing you with a holistic range of design services. Our design proficiency expands to areas like, designing brochures or flyers for marketing, sales, etc."
                text2= "We think that brochure designing is not just about creativity and innovation, but also of skillfulness. A mixture of all the above and years of knowledge in the field have made us a sought-after firm."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Benefits of Our Custom Brochure Design Services"
                    content= "A brochure is a compact medium of marketing. Its basic purpose is not just to inform people about your products and services, but also to underline the fact that you have better solutions up your sleeve when compared to your competitors. It can be called the flag-bearer, as far as affordable and effective marketing is concerned. Our skilled, professional and consultative brochure design experts consider integrity as an important aspect of business."
                    content2= 'Brochures offer very small amount of data, yet, are perfect for grabbing the attention of the audiences. Thanks to the marketing power of the marketing tool, Artographer Tacts, a leading brochure designing company, believes that the designing of such a significant piece of marketing tool has to be left in the expert hands of professional brochure designers.'
                    listTitle= 'Our skillful designers create an impact on your target audiences, with their attention to detail and focus on the requirements of the clients.'
                    image= '../images/artographer-logo-design-services.png'
                    alt= 'logo design service'
                />
            </MainContainer>
            <PageImage
                src='../images/graphic-service.webp'
                alt='graphic design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Brochure Design Process</SectionTitle>}
                    para1= "Brochures are a great way of making people aware of your brand. It can be used to tell them who you are and what you do. It is a great way to let them know how your offerings can make their lives better, thus, creating the much required curiosity about your business."
                    para2= "Before embarking on the brochure design for your business we will completely research your company's history, brochure placement, brochure size, brochure style, and others."
                    para3= "Based on the market analysis and your suggestions, our logo designers create remarkable brochure designs to build a successful brand image for your business."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Brochure Design Services"
                content= "Our business brochure design team at Artographer are experts in various innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Brochure Design Services"
                    title1= "Custom Brochure Design"
                    content1= "Our expert brochure designers deliver customized brochure designs that particularly define your brand and that best reflects your business specifications and expectations."
                    title2= "Professional Brochure Design"
                    content2= "Our designers make sure that your marketing endeavor becomes a resounding success. Irrespective of the size of your business or your requirement, we can assist you to bring forth your message to the audience that matters."
                    title3= "Husslefree Brochure Design Service"
                    content3= "Our service is so hassle-free that once you have awarded us the project, you can comfortably concentrate on your core business."
                    title4= "Partner and Friend"
                    content4= "On your search for unparalleled professional brochure, we can be your partner and friend. Just tell us your requirements, and our experts will help you every step of the way."
                    title5= "Latest Designs"
                    content5= "Updated about the latest design trends, we make sure that your attempt to make a mark in the industry does not go unnoticed."
                    title6= "Various Design Options"
                    content6= "We offer the most appropriate designs in various cuts & folds and in the most appropriate themes, to deliver your business message in the most efficient manner."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Brochure Design Agency"
                    subtitle= "A Dedicated Partner of your Team"
                    content1= "We provide excellent white label brochure design services to tech-based companies looking to outsource their design services."
                    content2= "Our unique and impressive brochure support start-ups, online professionals, small and medium, and large-sized businesses to create an engaging experience for their customers in the online sphere."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Brochure design Services"
                    content= "We have worked with brochure design clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a digital marketing services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Brochure Design Services'
                    title1="What exactly is a brochure?"
                    content1="Professional logos symbolize your brand message to the audience. By having a logo you can easily gain a unique identity in the competitive business world."
                    title2="Can brochure be treated as an important instrument of communication?"
                    content2="Yes. This is why business organizations print it. It helps them attain their promotional goals in an effective way. It is designed and presented in a tempting way so that you are immediately hooked onto it. The information given out has to be correct; otherwise it won’t create the desired impact. The marketers are responsible for choosing a proper design for it that is perfectly suited to its purpose. The key to a successful brochure is an appropriate design."
                    title3="How much do you charge for a brochure design?"
                    content3="Our price depends on brochure placement, size, style and others. It might vary from business-to-business. We have several packages to meet your individual requirements. To know more about our brochure design pricing options, you can call us +91 881-043-7133.."
                    title4="Is the “less is more” formula applicable to brochure design?"
                    content4="Keep it as simple as it can be. Do not try to incorporate too many things into it. For example, try not to use more than two or three fonts. This will confuse the customer in the first crucial seven seconds and your purpose will not be served."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default BrochureDesign;