import { OneRoofContainer } from '../styles/OneRoof.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { FaCheck } from "react-icons/fa";

function OneRoof(){
    return(
        <OneRoofContainer>
            <h2 className='section-title'>
                Graphic Design, Custom Website Development, & Digital Marketing All Under One Roof!
            </h2>
            <p>All reasons why Artographer is a wise option</p>
            <div className='section-content'>
                <Row>
                    <Col md={4} sm={12}>
                        <img 
                            className='creative-studio-image'
                            src='../images/artographer-creative-studio.webp' 
                            alt='creative studio' 
                        />
                    </Col>
                    <Col md={4} sm={12} className='container-list'>
                        <li><p><FaCheck className='check-icon'/></p><p>We first understand our client's expectations and ensure to meet their objectives</p></li>
                        <li><p><FaCheck className='check-icon'/></p><p>We're specialized in offering quality services with uniqueness and on-time delivery</p></li>
                        <li><p><FaCheck className='check-icon'/></p><p>We always consider our clients to be our main priority</p></li>
                    </Col>
                    <Col md={4} sm={12} className='container-list'>
                        <li><p><FaCheck className='check-icon'/></p><p>We're loyal and do maintain transparency throughout the process</p></li>
                        <li><p><FaCheck className='check-icon'/></p><p>We work with 100% commitment towards our client's satisfaction</p></li>
                        <li><p><FaCheck className='check-icon'/></p><p>We don't slide from showing our utmost professional empathy to our clients</p></li>
                    </Col>
                </Row>
            </div>
        </OneRoofContainer>
    );
}


export default OneRoof;