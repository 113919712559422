

const PortfolioData = [
    {
        id: 1,
        image: "../images/portfolio/beepac-port-cover.jpg",
        name: "Bee Pac",
        category: "graphic",
        description: "Logo design for BeePac",
        link: "/portfolio/bee-pac"
    },{
        id: 2,
        image: "../images/portfolio/twinkle-port-cover.jpg",
        name: "Twinkle",
        category: "graphic",
        description: "Logo design for Twinkle Architecture Designs",
        link: "/portfolio/twinkle-architecture-designs"
    },{
        id: 3,
        image: "../images/portfolio/pup-port-cover.jpg",
        name: "Pick Ur Pet",
        category: "web",
        description: "Logo design and Website development for PickUrPet",
        link: "/portfolio/pick-ur-pet"
    },{
        id: 4,
        image: "../images/portfolio/braccio-port-cover.jpg",
        name: "Braccio",
        category: "graphic",
        description: "Logo, Branding and Packaging design for Braccio",
        link: "/portfolio/braccio"
    },{
        id: 5,
        image: "../images/portfolio/moexa-port-cover.jpg",
        name: "Moexa",
        category: "web",
        description: "Web Graphics and Website Development for Moexa",
        link: "/portfolio/moexa"
    },{
        id: 6,
        image: "../images/portfolio/kenzi-port-cover.jpg",
        name: "Kenzi",
        category: "graphic",
        description: "Logo and Packaging Design for Kenzi",
        link: "/portfolio/kenzi"
    },{
        id: 7,
        image: "../images/portfolio/boat-port-cover.jpg",
        name: "Boat Media",
        category: "web",
        description: "Website Development for Boat Media",
        link: "/portfolio/boat-media"
    },{
        id: 8,
        image: "../images/portfolio/wet-port-cover.jpg",
        name: "Water and Energy Technologies",
        category: "graphic",
        description: "Animated Logo design for WET Lab",
        link: "/portfolio/wet-lab"
    },{
        id: 9,
        image: "../images/portfolio/all-songs-lyrics.jpg",
        name: "All Song Lyrics",
        category: "graphic",
        description: "Logo and ads design for AllSongLyrics",
        link: "/portfolio/all-song-lyrics"
    },{
        id: 10,
        image: "../images/portfolio/vinay-arts-cover.jpg",
        name: "Vinay Arts",
        category: "web",
        description: "Website design for Vinay Arts",
        link: "/portfolio/vinay-arts"
    },{
        id: 11,
        image: "../images/portfolio/cure-heaven-port-cover.jpg",
        name: "Cure Heaven",
        category: "graphic",
        description: "Logo & Packaging Design for Cure Heaven",
        link: "/portfolio/cure-heaven"
    },{
        id: 12,
        image: "../images/portfolio/jm-travels-cover.jpg",
        name: "JM Travels",
        category: "web",
        description: "Website design for JM Travels",
        link: "/portfolio/jm-travels"
    },{
        id: 13,
        image: "../images/portfolio/dynavation-cover.jpg",
        name: "Dynavation Electronics",
        category: "web",
        description: "Website design for Dynavation Electronics",
        link: "/portfolio/dynavation-electronics"
    },{
        id: 14,
        image: "../images/portfolio/charter-aviation-cover.jpg",
        name: "Charter Aviation",
        category: "web",
        description: "Website design for Charter Aviation",
        link: "/portfolio/charter-aviation"
    }
]

export default PortfolioData;
