import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import { HeroContainer } from "../styles/Home.style";
import OutlineButton from "./OutlineButton";



const HeroTwo = () => {
    return (
        <HeroContainer>
                <Row>
                    <div className='hero-left-container col-md-5'>
                        <img className='desktop-hero-image' src='../images/hero-section-image.webP' alt='hero desktop'/>
                        <img className='mobile-hero-image' src='../images/hero-section-image-mobile.webP' alt='hero mobile'/>
                    </div>
                    <div className='hero-right-container col-md-7'>
                        <h1>Web Development for Growing Global Companies</h1>
                        <h2>CREATIVE DIGITAL SERVICES AGENCY</h2>
                        <h3>Graphic Design <span>|</span> Web Development <span>|</span> Digital Marketing</h3>
                        <p className='hero-desc-text'>We provides long-term, forward-looking growth consulting and tactical digital marketing services to small business clients that are poised for growth. We find success when a client has a small internal marketing team and a need for a hands-on, proactive partner that can handle the full digital marketing scope from research to execution.<br />We're not just a marketing agency you bring in to support your marketing initiatives, though we can do that too. We’re a collaborative partner that will dive into your company and become part of your core team. We’ve worked with our most successful clients to define how they do business, crystallize their brand messaging, help them fundraise, empower their sales teams with better data and tools, and even support their recruiting and hiring efforts as they grow.</p>
                        <div className="hero-buttons">
                            <div className="button-wrap">
                                <Link to='/services?active=graphic-design'>
                                    <OutlineButton
                                        maintext= 'Branding & Visuals'
                                    />
                                </Link>
                                <h5>Logo & Identity, Print Designs</h5>
                            </div>
                            <div className="button-wrap">
                                <Link to='/services?active=web-development'>
                                    <OutlineButton
                                        maintext= 'Web Development'
                                    />
                                </Link>
                                <h5>Wordpress, Laravel, APIs</h5>
                            </div>
                            <div className="button-wrap">
                                <Link to='/services?active=digital-marketing'>
                                    <OutlineButton
                                        maintext= 'Digital Marketing'
                                    />
                                </Link>
                                <h5>UX, SEO, Ads, Automation</h5>
                            </div>
                        </div>
                    </div>
                </Row>
            </HeroContainer>
    );
}

export default HeroTwo;
