import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";



function NonprofitWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Non Profit Web Design, Development Services Company India | NGO Website Development Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/non-profit-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Our non profit website design and development services company helps non profit organization to have their online presence at a low cost with our non profit web design and development services. Click to learn more"
                />
            </Helmet>
            <PageTitle
                title= "Non Profit Website Development"
                text1= "Non-profit organizations try to bring changes to our society. At Artographer, we understand the purpose of your organization and work accordingly to get your services known to the entire world."
                text2= "We have worked with many non-profitable organizations and enhanced them to raise more funds. Building a powerful website helps you to improve your online presence which will help you reach donors and those who need help."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-service Non-profit Website Development Company will Fuel Your NPO Growth"
                    content= "Our non-profit website design services includes all the elements of a business website in addition to features unique to non-profits."
                    content2= "We can maximize user experience and highly efficient security tools to ensure increased donations, interactive graphics, logo designs, and relationship building features for donors and supporters that capture the spirit of your organizations."
                    listTitle= "Artographer offers best in class non-profit website design services. Just because your business is a non-profit organization, that doesn’t mean you can afford to scrimp on your website design. A beautiful, intuitive design is extremely important to you."
                    image= '../images/artographer-non-profit-website.png'
                    alt= 'artographer non-profit website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">How do Our Online Marketing Company Work?</span>}
                    para2= {<span>Artographer, a non-profit website development company, provides the expertise you need for your nonprofit organization’s website.<br /><br />Experience of providing websites to numerous non-profit organizations has enhanced our understanding of the perfect non-profit website.</span>}
                    para3= {
                        <>
                            <span style={{marginBottom: "1rem", display: "block"}}>
                                We understand that your budget is limited and so offer premium website design services at low cost.
                            </span>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Responsive website design</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Custom theme development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Content Management Systems</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Website redesign</p></div>
                        </>
                    }
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Choose Artographer for Your Non-profit Website Development requirements?</SectionTitle>}
                    para1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Progressive approach to technology</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Creative and innovative solutions</p></div>
                        </>
                    }
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Experienced developers</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Top technology tools</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Transparency and ease of work</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Artographer’s Non-profit Website Design Services</SectionTitle>}
                    para1= {<span>Website development for non-profit organizations needs to have an attractive and unique look that stands out and catches the eye.<br /><br />Moreover, it needs to communicate your message and draw the user in so that they are invested in your cause.</span>}
                    para2= "We use clever UX to ensure that your visitors are led through your site in a way that holds their attention and then hits them with a clear call-to-action."
                    para3= "Whether you’re looking for a donation, an email address or you just want to change their outlook, our website design will ensure user engagement. Don’t turn down this opportunity to have modern and highly effective responsive website development for such an incredibly discounted price."
                    button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
                />
            </MainContainer>
            <MainContainer>
              <IndustriesBenefits 
                title= "Our Location-based / Localized Non-profit Website Development Services"
                content= {<span>We have worked with Non-profit website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a Non-profit website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
              />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Magento Website Development"
                    subTitle3= "Creative Magento Website Development"
                    title4= "Drupal Website Development"
                    subTitle4= "Creative Drupal Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default NonprofitWeb;