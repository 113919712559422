import styled from "styled-components";



export const NavTabs = styled.div`
margin: auto;
border-bottom: 1px solid #D3D3D3;
text-align: center;

    &::before, &::after {
        display: none;
    }

    ul {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;

        .title-wrap {
            position: relative;
            margin-right: .8rem;

            @media screen and (max-width: 767px){
                margin-right: .6rem;
            }

            @media screen and (max-width: 480px){
                margin-right: .5rem;
            }

            &:last-child {
                margin-right: 0;
            }


            .tab-item {
                font-size: 1.1rem;
                font-weight: 500;
                color: gray;
                padding: 1rem 1.5rem .5rem 1.5rem;
                transition: .5s all ease;

                @media screen and (max-width: 767px){
                    padding: 1rem .3rem .5rem .3rem;
                    font-size: .9rem;
                }

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: auto;
                    position: absolute;
                    bottom: -4px;
                    transform: scaleX(0);
                }

                &:hover {
                    color: var(--dark-grey);
                    cursor: pointer;

                    &::after {
                        transform: scaleX(1);
                        transition: 300ms ease transform;
                    }
                }

                &::before {
                    content: none;
                }
            }

            .active-tab {
                font-size: 1.1rem;
                font-weight: 500;
                color: black;
                padding: 1rem 1.5rem .5rem 1.5rem;
                transition: .5s all ease;

                @media screen and (max-width: 767px){
                    padding: 1rem .3rem .5rem .3rem;
                    font-size: .9rem;
                }

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                }

                &:hover {
                    cursor: default;
                }

                &::before {
                    content: none;
                    text-align: center;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: auto;
                    position: absolute;
                    bottom: -4px;
                }
            }
        }
    }
`;

export const TabContent = styled.div`
    margin-top: 1.5rem;
    padding: 0;
    width: 100%;

    @media screen and (max-width: 767px){
        margin-top: 1rem;
    }

    @media screen and (max-width: 480px){
        margin-top: .7rem;
    }

    &::before, &::after {
        display: none;
    }

    .tab-pane {
        display: none;
    }

    .active {
        display: block;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    .tab-pane-icons {
        font-size: 3rem;
        margin: .5rem;
        padding: .8rem 1rem;
        color: var(--primary);
        list-style: none;
        border: 0px solid grey;
        border-radius: 1rem;
        background-color: #F2F2F2;

        @media screen and (max-width: 767px){
            font-size: 2.2rem;
            margin: .4rem;
            padding: .5rem .6rem;
        }

        @media screen and (max-width: 480px){
            font-size: 1.5rem;
            margin: .3rem;
            padding: .4rem .5rem;
        }
    }
`;