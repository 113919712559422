import styled from "styled-components";
import { motion } from 'framer-motion';



export const NavbarContainer = styled.nav`
    padding: 15px 0;
    background: transparent;
    position: absolute;
    z-index: 9999;
    width: 100%;
    float: left;
    top: 0px !important;

    @media screen  {
        
    }

    .navbar-inner-Container {
        width: 100%;
        max-width: 1300px;
        padding-left: .75rem;
        padding-right: .75rem;
        margin-right: auto;
        margin-left: auto;
        position: relative;

        @media screen and (max-width: 1199px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media screen and (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media screen and (max-width: 480px) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
`;

export const MenuWrap = styled.div`
    display: flex;
    width: 100%;
    float: left;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px){
        flex-direction: column;
        justify-content: center;
        text-align: center;

    }

    .left-container {
        float: left;
        width: 25%;
        position: relative;

        @media screen and (max-width: 767px){
            width: 100%;
        }
    }

    .right-container {
        float: left;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 991px){
            flex-direction: row-reverse;
            justify-content: flex-start;
        }

        @media screen and (max-width: 767px){
            width: 100%;
            justify-content: center;
            margin-top: .5rem;
            position: relative;
        }
    }
`;

export const Logo = styled.img`
    margin: 10px;
    max-width: 300px;
    height: auto;
    object-fit: contain;

    @media screen and (max-width: 767px){
        max-width: 350px;
    }

    @media screen and (max-width: 480px){
        max-width: 250px;
    }
`;

export const QuicklinkCont = styled.div`

    @media screen and (max-width: 991px){
        display: none;
    }

    .desktop-only-wrap{
        position: relative;
        padding-top: 15px;

        .quick-links {
            margin: 0 25px;
            font-size: 1.2rem;
            padding: 12px 2px;
            overflow: hidden;
            position: relative;
            height: auto;
            background-color: transparent;
            color: var(--dark-grey);
            display: block;
            cursor: auto;
        }

        .on-hover-menu-wrap {
            list-style: none;
            margin: 0;
            padding-left: 0;
            width: 530px;
            box-shadow: 0 6px 16px #9f9e9e29;
            position: absolute;
            left: -180px;
            z-index: 99999;

            li {
                width: 50%;
                display: inline-block;
                padding-left: 25px;

                &:nth-child(2n) {
                    background: #FFF8EB;
                }

                &:nth-child(2n+1) {
                    background-color: white;
                }

                &:nth-last-child(2),
                &:nth-last-child(1) {
                    padding-bottom: 15px;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    padding-top: 15px;
                }

                .list-item-link {
                    padding: 10px 0px;
                    font-size: 1.1rem;
                    width: 220px;
                    text-decoration: none;
                    outline: none;
                    display: block;
                    color: var(--dark-grey);

                    &:hover {
                        cursor: pointer;
                        color: var(--primary);
                    }
                }
            }
        }
    }
`;

export const MenuTriggerWrap = styled.div`
    float: left;
    margin-right: 20px;

    @media screen and (max-width: 991px){
        display: none;
    }

    img {

        &:hover {
            cursor: pointer;
        }
    }
`;

export const RaqWrap = styled.div`
    float: left;
`;

export const MainNavContainer = styled(motion.div)`
    background-color: black;
    width: 100vw;
    height: 100vh; 
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 991px){
        display: none;
    }

    .navbar-container {
        background-color: white;
        width: 100vw;
        height: 100vw; 
        z-index: 999999999;
        padding: 2rem 0;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: var(--paragraph);
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;

        .nav-inner-container {
            max-width: 1300px;


            .nav-head-wrap {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                position: relative;
                width: 100%;

                .nav-title-wrap {
                    float: left;
                    width: 40%;

                    @media screen and (max-width: 1199px){
                        width: 50%;
                    }

                    .nav-title {
                        font-size: 2.8rem;
                        font-weight: 800;
                        line-height: 3.3rem;
                        letter-spacing: -1px;
                        color: var(--dark-grey);
                    }
                    
                    .nav-desc {
                        margin: 0;
                        margin-top: 1.5rem;
                        padding: 0;
                        font-size: 1.1rem;
                        line-height: 1.6rem;
                    }
                }

                .close-button-wrap {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 2.5rem;
                    color: var(--dark-grey);

                    &:hover {
                        cursor: pointer;
                    }
                }

                .btn-container {
                    display: flex;
                    justify-content: flex-end;
                    float: right;
                    width: 40%;

                    @media screen and (max-width: 1199px){
                        width: 50%;
                    }

                    .google-reviews-wrap {
                        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            .nav-bottom-wrap {
                margin-top: 4rem;

                .align-top {
                    display: flex;
                }
            }
        }
    }
`;

export const MenuItems = styled.nav`
    width: 25%;
    margin: 0 1rem;

    &:first-child {
        margin-left: 0;
    }


    .list-title {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: -1px;
        color: var(--dark-grey);
    }
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
            margin-bottom: .5rem;

            .nav-link-item {
                font-size: 1rem;
                font-weight: 500;
                text-decoration: none;
                color: var(--paragraph);
                transition: .3s ease;
                cursor: default;

                &:hover {
                    cursor: pointer;
                    color: var(--primary);
                }
            }
        }
    }
`;

export const MobileMenuContainer = styled.div`

    @media screen and (min-width: 991px) {
        display: none;
    }

    @media screen and (max-width: 991px) {
        display: block;
    }

    @media screen and (max-width: 767px) {
        display: block;
        position: absolute;
        right: 0;
    }

    .toggle-btn-wrap {
        float: left;
        margin-left: 20px;

        @media screen and (max-width: 991px) {
            margin-right: 20px;
        }

        @media screen and (max-width: 767px) {
            margin-right: 0;
        }

        img {

            &:hover {
                cursor: pointer;
            }
        }
    }

`;

export const MobileMenuWrap = styled(motion.div)`
    width: 80%;
    max-width: 25rem;
    height: 100vh; 
    z-index: 9999999;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #333333;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: var(--paragraph);
    padding: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    border-left: 15px solid var(--primary);

    @media screen and (max-width: 480px){
        width: 100%;
        border-left: 15px solid var(--primary);
    }

    .menu-outer-container {
        
        
        .mobile-close-button-wrap {
            text-align: right;
            font-size: 2rem;
            color: white !important;

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
            }

            .mobile-menu-close-icon {

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .mobile-link-container {
            list-style: none;
            margin: 0;
            padding-left: 1rem;
            margin-top: 1rem;

            .mobile-only-menu {
                margin-bottom: 2rem;

                @media screen and (max-width: 480px){
                    margin-bottom: 1.5rem;
                }

                .mobile-menu-link {
                    text-decoration: none !important;
                    color: white;

                    &:hover {
                        color: var(--primary);
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;