import React from "react";
import { DarkContainer2 } from "../styles/PageTitle.style";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col } from 'react-bootstrap';

const PageTitleFullWidth = (props) => {
    return (
        <DarkContainer2>
            <Col className="pt-title-wrap">
                <h1>{props.title}</h1>
            </Col>
        </DarkContainer2>
    );
}

export default PageTitleFullWidth;
