import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const PickUrPet = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | PickUrPet</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/pick-ur-pet"
        />
        <meta
          name="descriptions"
          content="Check out our stunning and fast eCommerce website design for PickUrPet, where our skilled developers delivered a solution that exceeded client expectations."
        />
      </Helmet>
      <PortfolioPage
        title="Logo Design and Website Development for PickUrPet"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/pup-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="PickUrPet"
        industry="Pet Care"
        requirement="The client wanted to create a professional e-commerce website to sell their product and services."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/pickurpet-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            We built a minimalistic website design with all the trendy features.
            Adding to it, we also performed tactics optimizing the site for
            search engines.
            <br />
            <br />
            We gave a good look and feel to the website which best suited their
            specialized professional services. We developed a website with
            features like smooth navigation, mobile responsiveness, and so on.
          </span>
        }
        bottomImage={<img src="../images/portfolio/pickurpet-web.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/braccio">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/twinkle-architecture-designs">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default PickUrPet;
