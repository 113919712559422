import styled from "styled-components";


export const SectionContainer = styled.div`


    .bfol-left-container {
        text-align: left;

        .bfol-section-title {
            text-align: left;
            margin-bottom: 1.2rem;
        }

        .bfol-para-text {
            margin-bottom: 2rem;
        }
    }

    .bfol-right-container {

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

`;