


const CaseStudiesData = [
    {
        id: 1,
        image: "../images/case-studies/boat-media-cover.webP",
        name: "boat media",
        link: "/error-404"
    },
    {
        id: 2,
        image: "../images/case-studies/cure-heaven-cover.webp",
        name: "Cure Heaven",
        link: "/error-404"
    },
    {
        id: 3,
        image: "../images/case-studies/jm-travels-cover.webP",
        name: "JM Travels",
        link: "/error-404"
    },
    {
        id: 4,
        image: "../images/case-studies/pick-ur-pet-cover.webP",
        name: "Pick Ur Pet",
        link: "/error-404"
    },
    {
        id: 5,
        image: "../images/case-studies/moexa-cover.webP",
        name: "Moexa",
        link: "/error-404"
    },
    {
        id: 6,
        image: "../images/case-studies/kenzi-cover.webP",
        name: "Kenzi",
        link: "/error-404"
    },
    {
        id: 7,
        image: "../images/case-studies/dentpro-cover.webP",
        name: "Kenzi",
        link: "/error-404"
    },
    {
        id: 8,
        image: "../images/case-studies/yoga-cover.webP",
        name: "Dental",
        link: "/error-404"
    }
];

export default CaseStudiesData;