import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Dynavation = () => {
  return (
    <>
      <Helmet>
        <title>
          Portfolio Archive - Artographer | Dynavation Electronics Pvt. Ltd.
        </title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/dynavation-electronics"
        />
        <meta
          name="descriptions"
          content="Our expert web developers crafted a stunning, fast website for Dynavation Electronics, ensuring client satisfaction with top-notch design and performance."
        />
      </Helmet>

      <PortfolioPage
        title="Website Development for Dynavation Electronics"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/dynavation-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Dynavation Electronics Pvt. Ltd."
        industry="Testing & Measurement Instruments Manufacturer"
        requirement="A client from Bangalore, India, sought our collaboration for the complete development of their e-commerce website to effectively sell products online and expand their business reach."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/dynavation-parall2.webp"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            The client wanted to revamp their existing website with a fresh,
            appealing look. After a detailed discussion with their team, we
            carefully analyzed their needs and vision for the new site. Our team
            designed a custom e-commerce website that aligned with their brand
            identity and business goals.
            <br />
            <br />
            We focused on enhancing user experience by implementing a clean,
            modern design and integrating features like a customized icon-type
            list menu, which allowed for easy navigation and product discovery.
            We ensured that the website was responsive, fast-loading, and
            optimized for both desktop and mobile users. Additionally, we
            integrated secure payment gateways and streamlined the checkout
            process to improve conversion rates. <br />
            <br />
            By considering every aspect of their requirements, we delivered a
            high-quality e-commerce platform that not only met but exceeded
            their expectations, helping them effectively sell their products
            online.
          </span>
        }
        bottomImage={
          <img src="../images/portfolio/dynavation-menu.webp" alt="" />
        }
        preButton={
          <Link to="/portfolio/charter-aviation">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/jm-travels">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Dynavation;
