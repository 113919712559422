import styled from "styled-components";


export const SitemapCont = styled.div`
    text-align: left;
    font-size: 1.1rem;

    @media screen and (max-width: 1199px){
        font-size: 1rem;
    }

    @media screen and (max-width: 767px){
        font-size: .9rem;
    }


    ul {
        padding: 0;
        margin-left: 2.5rem;

        @media screen and (max-width: 480px){
            margin-left: 1.5rem;
        }

        @media screen and (max-width: 1199px){
            margin-left: 2rem;
        }

        li {
            list-style: none;
            margin-bottom: 1.5rem;
            position: relative;

            @media screen and (max-width: 480px){
                margin-bottom: .8rem;
            }

            @media screen and (max-width: 1199px){
                margin-bottom: .9rem;
            }

            .sm-main-head {

                
                &::before {
                    content: '';
                    width: 2rem;
                    height: .1rem;
                    background-color: var(--primary);
                    position: absolute;
                    left: -2.5rem;
                    top: 10px;

                    @media screen and (max-width: 480px){
                        width: 1rem;
                        left: -1.5rem;
                    }

                    @media screen and (max-width: 1199px){
                        width: 1.5rem;
                        left: -2rem;
                    }
                }


                &:hover {
                    color: var(--primary);
                }
            }

            .sm-head-child {
                margin-left: 2.5rem;

                @media screen and (max-width: 480px){
                    margin-left: 1.5rem;
                }

                @media screen and (max-width: 1199px){
                    margin-left: 2rem;
                }

                &::before {
                    content: '';
                    width: 4.5rem;
                    height: .1rem;
                    background-color: var(--primary);
                    position: absolute;
                    left: -2.5rem;
                    top: 10px;

                    @media screen and (max-width: 1199px){
                        width: 3rem;
                        left: -2rem;
                    }

                    @media screen and (max-width: 480px){
                        width: 2.5rem;
                        left: -1.5rem;
                    }
                }

                &:hover {
                    color: var(--primary);
                }
            }
        }
    }

    
`