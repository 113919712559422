import React from 'react';
import { SitemapCont } from '../styles/SiteMap.style';
import { MainContainer } from '../globalStyles';
import { Link } from 'react-router-dom';
import PageTitleFullWidth from '../components/PageTitleFullWidth';
import {Helmet} from 'react-helmet';



const SiteMap = () => {
    return (
        <>
            <Helmet>
                <title>
                    Artographer Web Development Partnership Agency - Sitemap
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/site-map/" />
                <meta 
                    name="descriptions" 
                    content="Are you a professional service or product company looking for a partner in the highly technical and evolving web space? Artographer will be perfect for you."
                />
            </Helmet>
            <PageTitleFullWidth
                title= 'Pages'
            />
            <MainContainer>
                <SitemapCont>
                    <ul>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/about-artographer'>
                                <span className='sm-main-head'>
                                    About-Artographer Tacts
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/privacy-policy'>
                                <span className='sm-main-head'>
                                    PrivacyPolicy
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/'>
                                <span className='sm-main-head'>
                                    Home
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/request-a-quote'>
                                <span className='sm-main-head'>
                                    Request A Quote
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services'>
                                <span className='sm-main-head'>
                                    Services
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/graphic-design-company'>
                                <span className='sm-head-child'>
                                    Graphic Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/logo-design-company'>
                                <span className='sm-head-child'>
                                    Logo Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/business-stationery-design'>
                                <span className='sm-head-child'>
                                    Business Stationery Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/brochure-design'>
                                <span className='sm-head-child'>
                                    Brochure Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/social-media-design'>
                                <span className='sm-head-child'>
                                    Social Media Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/hire-a-graphic-designer'>
                                <span className='sm-head-child'>
                                    Hire a Graphic Designer
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/website-redesign-company'>
                                <span className='sm-head-child'>
                                    Website Redesign
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/mobile-app-ui-design'>
                                <span className='sm-head-child'>
                                    Mobile App UI/UX Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/wordpress-development-services'>
                                <span className='sm-head-child'>
                                    Wordpress Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/wordpress-theme-development'>
                                <span className='sm-head-child'>
                                    WordPress Theme Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/marketplace-website-design'>
                                <span className='sm-head-child'>
                                    Marketplace Website Develop
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/membership-website-design'>
                                <span className='sm-head-child'>
                                    Memebership Website Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/magento-website-design'>
                                <span className='sm-head-child'>
                                    Magneto Website Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/woocommerce-website-design'>
                                <span className='sm-head-child'>
                                    WooCommerce Website Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/mobile-app-development'>
                                <span className='sm-head-child'>
                                    Mobile App Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/php-website-design'>
                                <span className='sm-head-child'>
                                    Php Website Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/non-profit-website-design'>
                                <span className='sm-head-child'>
                                    Non-Profit Website Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/startup-website-design'>
                                <span className='sm-head-child'>
                                    Startup Website Design
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/android-app-development'>
                                <span className='sm-head-child'>
                                    Android App Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/drupal-website-development'>
                                <span className='sm-head-child'>
                                    Drupal Website Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/ecommerce-website-development'>
                                <span className='sm-head-child'>
                                    Ecommerce Website Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/shopify-website-development'>
                                <span className='sm-head-child'>
                                    Shopify Website Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/web-app-development'>
                                <span className='sm-head-child'>
                                    Web App Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/reactjs-development'>
                                <span className='sm-head-child'>
                                    ReactJs Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/vuejs-development'>
                                <span className='sm-head-child'>
                                    VueJs Development
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/hire-a-web-developer'>
                                <span className='sm-head-child'>
                                    Hire a Web Developer
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/digital-marketing-services'>
                                <span className='sm-head-child'>
                                    Digital Marketing
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/seo-services'>
                                <span className='sm-head-child'>
                                    SEO Services
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/social-media-marketing'>
                                <span className='sm-head-child'>
                                    Social Media Marketing
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/pay-per-click-services'>
                                <span className='sm-head-child'>
                                    Pay Per Click Service
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/facebook-marketing-company'>
                                <span className='sm-head-child'>
                                    Facebook Marketing
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/content-marketing-services'>
                                <span className='sm-head-child'>
                                    Content Marketing
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/services/marketing-automation'>
                                <span className='sm-head-child'>
                                    Marketing Automation
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/terms-conditions'>
                                <span className='sm-main-head'>
                                    Terms & Conditions
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/portfolio'>
                                <span className='sm-main-head'>
                                    Portfolio
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/case-studies'>
                                <span className='sm-main-head'>
                                    Case Studies
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/testimonials'>
                                <span className='sm-main-head'>
                                    Testimonials
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link style={{textDecoration: 'none'}}
                                to='/thank-you'>
                                <span className='sm-main-head'>
                                    Thank You Contacting Us
                                </span>
                            </Link>
                        </li>
                    </ul>
                </SitemapCont>
            </MainContainer>
        </>
    );
}

export default SiteMap;
