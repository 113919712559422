import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import PageTitleFullWidth from '../components/PageTitleFullWidth';
import { PageWrap } from '../styles/PortfolioPage.style';
import { Parallax } from 'react-parallax';
import { MainContainer } from '../globalStyles';




const PortfolioPage = (props) => {
    return (
        <div>
            <PageTitleFullWidth
                title= {props.title}
            />
            <PageWrap>
                <MainContainer>
                    <Row className='pp-top-row d-flex justify-content-center gx-5'>
                        <Col md={3}>
                            <div className='pp-title-image-wrap'>
                                {props.titleImage}
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className='pp-title-wrap'>
                                <div className='pp-item-list'>
                                    <p className='pp-line-head'>Business name:</p>
                                    <p className='pp-line-desc bg'>{props.bName}</p>
                                </div>
                                <div className='pp-item-list'>
                                    <p className='pp-line-head'>Industry:</p>
                                    <p className='pp-line-desc bg'>{props.industry}</p>
                                </div>
                                <div className='pp-item-list bg-cont'>
                                    <p className='pp-line-head'>Client's requirement:</p>
                                    <p className='pp-line-desc'>{props.requirement}</p>
                                </div>
                                <div className='pp-item-list bg-cont' style={{marginBottom: "0"}}>
                                    <p className='pp-line-head'>Process followed:</p>
                                    <p className='pp-line-desc'>{props.process}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </MainContainer>
                <Parallax bgImage={props.bgImage} bgImageAlt='my img' strength={200}>
                    <div style={{height: "450px"}} />
                </Parallax>
                <MainContainer>
                    <Row className='d-flex justify-content-center'>
                        <Col md={9}>
                            <div className='pp-content-wrap'>
                                <span className='pp-bottom-title'>{props.bTitle}</span>
                                <p className='pp-bottom-content'>{props.bContent}</p>
                            </div>
                            <div className='pp-bottom-wrap'>
                                <Row className='d-flex justify-content-center'>
                                    <Col md={10}>
                                        <div className='bottom-image-wrap'>
                                            {props.bottomImage}
                                        </div>
                                        <hr />
                                        <div className='pp-button-wrap d-flex justify-content-between mt-4'>
                                            <div className='navigation-button previous'>{props.preButton}</div>
                                            <div className='navigation-button next'>{props.nxtButton}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </MainContainer>
            </PageWrap>
        </div>
    );
}

export default PortfolioPage;
