import styled from "styled-components";


export const TabTitles = styled.div`

    .tab-title-container {
        margin-bottom: 2rem;
        border-bottom: 1px solid #D3D3D3;
        text-align: center;

        .title-wrap {
            position: relative;

            .tab-name {
                font-size: 1.1rem;
                font-weight: 500;
                list-style: none;
                color: #989898;
                padding: .8rem 0rem;
                transition: .5s all ease;

                @media screen and (max-width: 767px){
                    font-size: .9rem;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    bottom: -4px;
                    transform: scaleX(0);
                }
                
                &:hover {
                    color: var(--dark-grey);
                    cursor: pointer;

                    &::after {
                        transform: scaleX(1);
                        transition: 300ms ease transform;
                    }
                }

                &::before {
                    content: none;
                }

            }
            .active-name {
                font-size: 1.1rem;
                font-weight: 500;
                color: var(--dark-grey);
                transition: .5s all ease;
                list-style: none;
                padding: .8rem 0rem;

                @media screen and (max-width: 767px){
                    font-size: .9rem;
                }
                
                &:hover {
                    cursor: default;
                }

                &::before {
                    content: none;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    bottom: -4px;
                }
            }
        }
    }
`;

export const TabContent = styled.div`
    text-align: left;

    &::before, &::after {
            display: none;
    }

    .tab-content {
        margin-bottom: 4rem;
        display: none;

        .test-container {
            margin-top: 4rem;

            @media screen and (max-width: 767px){
                margin-top: 2rem;
            }

            .test-title {
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 2.3rem;
                font-style: italic;
                letter-spacing: -1px;
                background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 767px){
                    font-size: 1.4rem;
                }
            }

            .test-content {
                margin-top: 1rem;

                @media screen and (max-width: 767px){
                    margin-top: .5rem;
                }

                a{
                    text-decoration: none;
                    cursor: pointer;

                    &:hover {
                        color: var(--primary);
                    }
                }
            }

            .client-partner {
                font-size: 1.2rem;
                font-weight: 600;
                font-style: italic;
                color: var(--light-gray);
                letter-spacing: -1px;

                @media screen and (max-width: 767px){
                    font-size: 1rem;
                }
            }
        }

        .youtube-test-container {
            margin-top: 4rem;

            @media screen and (max-width: 767px){
                margin-top: 3rem;
            }

            .test-message-wrap {

                @media screen and (max-width: 767px){
                    margin-top: 1.5rem;
                }

                .test-client-desc {
                    font-size: 1.8rem;
                    font-weight: 500;
                    font-style: italic;
                    letter-spacing: -1px;
                    color: var(--dark-grey);
                    margin-bottom: .7rem;
                    display: block;

                    @media screen and (max-width: 767px){
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    .active-content {
        display: block;
    }
`;