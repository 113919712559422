import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavTabs, TabContent, } from "../styles/Technologies.style";
import { FaWordpress, FaHubspot, FaBehanceSquare, FaSlack, FaShopify, FaSalesforce, FaAngular, FaEmber, FaVuejs, FaMagento, FaReact, FaDrupal, FaInvision, FaFigma, FaJoomla } from 'react-icons/fa';
import { SiPhp, SiAdobexd, SiTodoist, SiAdobeaftereffects, SiGoogletagmanager, SiHtml5, SiAdobeindesign, SiAdobeillustrator, SiAdobephotoshop, SiJquery, SiCss3, SiSketch } from "react-icons/si";
import { MainContainer, SectionTitle } from "../globalStyles";



function Technologies() {

    const [ toggleState, setToggleState ] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
      <>
        <MainContainer>
            <SectionTitle style={{textAlign: 'center'}}>
                Technologies We Work With
            </SectionTitle>
            <div className="col-md-8 col-sm-11 m-auto">
                <NavTabs>
                    <ul>
                        <div className='title-wrap'>
                            <li className = {toggleState === 1 ? "active-tab" : "tab-item"} 
                                onClick={() => toggleTab(1)}>
                                    Web
                            </li>
                        </div>
                        <div className='title-wrap'>
                            <li className = {toggleState === 2 ? "active-tab" : "tab-item"}
                                onClick={() => toggleTab(2)}>
                                    UI/UX
                            </li>
                        </div>
                        <div className='title-wrap'>
                            <li className = {toggleState === 3 ? "active-tab" : "tab-item"}
                                onClick={() => toggleTab(3)}>
                                    JS
                            </li>
                        </div>
                        <div className='title-wrap'>
                            <li className = {toggleState === 4 ? "active-tab" : "tab-item"}
                                onClick={() => toggleTab(4)}>
                                    Graphic
                            </li>
                        </div>
                        <div className='title-wrap'>
                            <li className = {toggleState === 5 ? "active-tab" : "tab-item"}
                                onClick={() => toggleTab(5)}>
                                    Marketing
                            </li>
                        </div>
                    </ul>
                </NavTabs>
                <TabContent>
                        <div className={toggleState === 1 ? "active-pane" : "tab-pane"}>
                            <ul>
                                <li className="tab-pane-icons"><FaWordpress /></li>
                                <li className="tab-pane-icons"><SiPhp /></li>
                                <li className="tab-pane-icons"><FaJoomla /></li>
                                <li className="tab-pane-icons"><FaShopify /></li>
                                <li className="tab-pane-icons"><FaMagento /></li>
                                <li className="tab-pane-icons"><FaDrupal /></li>
                            </ul>
                        </div>
                        <div className={toggleState === 2 ? "active-pane" : "tab-pane"}>
                        <ul>
                            <li className="tab-pane-icons"><SiAdobexd /></li>
                            <li className="tab-pane-icons"><FaFigma /></li>
                            <li className="tab-pane-icons"><FaInvision /></li>
                            <li className="tab-pane-icons"><SiSketch /></li>
                            <li className="tab-pane-icons"><SiHtml5 /></li>
                            <li className="tab-pane-icons"><SiCss3 /></li>
                        </ul>
                        </div>
                        <div className={toggleState === 3 ? "active-pane" : "tab-pane"}>
                            <ul>
                                <li className="tab-pane-icons"><FaReact /></li>
                                <li className="tab-pane-icons"><FaAngular /></li>
                                <li className="tab-pane-icons"><FaVuejs /></li>
                                <li className="tab-pane-icons"><SiJquery /></li>
                                <li className="tab-pane-icons"><FaEmber /></li>
                            </ul>
                        </div>
                        <div className={toggleState === 4 ? "active-pane" : "tab-pane"}>
                            <ul>
                                <li className="tab-pane-icons"><SiAdobeillustrator /></li>
                                <li className="tab-pane-icons"><SiAdobephotoshop /></li>
                                <li className="tab-pane-icons"><SiAdobeindesign /></li>
                                <li className="tab-pane-icons"><SiAdobeaftereffects /></li>
                                <li className="tab-pane-icons"><SiSketch /></li>
                            </ul>
                        </div>
                        <div className={toggleState === 5 ? "active-pane" : "tab-pane"}>
                            <ul>
                                <li className="tab-pane-icons"><FaSalesforce /></li>
                                <li className="tab-pane-icons"><FaHubspot /></li>
                                <li className="tab-pane-icons"><SiGoogletagmanager /></li>
                                <li className="tab-pane-icons"><FaBehanceSquare /></li>
                                <li className="tab-pane-icons"><FaSlack /></li>
                                <li className="tab-pane-icons"><SiTodoist /></li>
                            </ul>
                        </div>
                </TabContent>
            </div>
        </MainContainer>
      </>
    );
  }
  


  
  export default Technologies;