import styled from "styled-components";


export const SectionContainer = styled.div`


    .digital-marketing-tools {

        .marketing-image {
            margin-bottom: 6rem;
        }

        .bottom-wrap {

            .list-item-container1 {
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 5px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 10px solid rgba(51, 153, 255, .5);
            }

            .list-item-container2 {
                border-left: 5px solid rgba(51, 153, 255, .5);
                border-right: 5px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 10px solid rgba(51, 153, 255, .5);
            }

            .list-item-container3 {
                border-left: 5px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 10px solid rgba(51, 153, 255, .5);
            }

            .list-item-wrap{
                padding: 1.5rem;
                padding-bottom: .2rem;

                .list-item {
                    display: flex;

                    .check-icon {
                        color: var(--primary);
                        margin-right: .8rem;
                        margin-top: .4rem;
                    }
        }
            }
    }


        }

    
    }
    
`;