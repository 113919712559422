import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { SectionContainer } from "../styles/PrimarySection.style";
import { SectionTitle } from '../globalStyles';
import PrimaryButton from "./PrimaryButton";




function PrimarySetion(props) {
    return(
        <SectionContainer>
            <Row>
                <Col sm={12} md={6} className="blds-left-container">
                    <SectionTitle className="title">{props.title}</SectionTitle>
                    <p>{props.content}</p>
                    <p>{props.content2}</p>
                    <p>{props.listTitle}</p>
                    <ul>
                        <li><p>{props.listItem1}</p></li>
                        <li><p>{props.listItem2}</p></li>
                        <li><p>{props.listItem3}</p></li>
                        <li><p>{props.listItem4}</p></li>
                        <li><p>{props.listItem5}</p></li>
                    </ul>
                    <p>{props.content3}</p>
                    <PrimaryButton
                        smalltext= 'Its Free'
                        maintext= 'Get Custom Quote'
                    />
                </Col>
                <Col sm={12} md={6} className="blds-right-container">
                    <img 
                        src={props.image} 
                        alt={props.alt} 
                    />
                </Col>
            </Row>
        </SectionContainer>
    )
}


export default PrimarySetion;