import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";



function DrupalWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Drupal Website Development Services Company India - Drupal Plugin, Theme Development
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/drupal-website-development/" />
                <meta 
                    name="descriptions" 
                    content="Drupal Web Development Services Company India - Artographer is a drupal website design company provides wide range of services which includes custom drupal theme design and plugin development services. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Drupal Website Development"
                text1= "Artographer has delivered several innovative and engaging Drupal-based projects. We have developed a good reputation among clients through our dedicated services. We help you to build Drupal websites which will definitely enhance your business."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-service Drupal Website Development Company will Fuel the Growth of Your Business"
                    content= "Artographer, a premier Drupal website design company in India offers expert CMS theming with Drupal."
                    content2= "Artographer understands your business from inside out before developing the Drupal solution. We are experts in Drupal website and theme development."
                    listTitle= "If you want advanced features and functionalities that modern websites require, you have to get help from a professional. Our Drupal CMS design, development, & maintenance services will help you get off the ground for a unique website that resonates with your business."
                    image= '../images/artographer-drupal-website.png'
                    alt= 'artographer drupal website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Drupal Website Services Include</SectionTitle>}
                    para1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Drupal development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Drupal consulting</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Drupal installation and migration</p></div>
                        </>
                    }
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Custom website design with Drupal CMS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Custom module development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEO and online marketing</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>PSD to Drupal conversion</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Drupal theme design</p></div>
                        </>
                    }
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Drupal Website Development Services"
                content= "Our Drupal website development team at Artographer can develop, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>We Provide Variety of Drupal Website Solutions</SectionTitle>}
                    para1= "White label drupal website development services are suitable for agencies that are looking for extra resources to share their workload. Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits."
                    para2= "Our professional Drupal developers will make sure all the pieces are successfully implemented. As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team."
                    para3= "All of our B2B services are designed to off-load the Drupal development needs of any agency. We are happy to work under your branding to take care of your customers."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">Why Choose Drupal for Your Website Development Requirement?</span>}
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Highly flexible and extensible</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Website works with precision</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Interactive features</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Built-in caching and blogging</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Cross browser compatible</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Meets web standards</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEO friendly</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Rich content and easy to manage</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>From small to big enterprises, all can deploy Drupal solution for their website</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Drupal Development Services"
                    content= {<span>We have worked with Drupal development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a Drupal development services.<br /><br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Logo Design Services"
                    subTitle= "Creative Logo Design Services"
                    title2= "Marketplace Website Development"
                    subTitle2= "Creative Marketplace Website Development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "Content Marketing Services"
                    subTitle4= "Creative Content Marketing Services"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default DrupalWeb;