import styled from "styled-components";


export const TabTitles = styled.div`
    display: block;
    margin-top: 2rem;

    .tab-title-container {
        margin-bottom: 2rem;
        border-bottom: 1px solid #D3D3D3;
        text-align: center;

        .title-wrap {
            position: relative;
            padding: .8rem 0rem;

            .tab-name {
                font-size: 1.1rem;
                font-weight: 500;
                list-style: none;
                color: #989898;
                transition: .5s all ease;
                text-decoration: none;

                @media screen and (max-width: 767px){
                    font-size: .9rem;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    bottom: -4px;
                    transform: scaleX(0);
                }
                
                &:hover {
                    color: var(--dark-grey);
                    cursor: pointer;

                    &::after {
                        transform: scaleX(1);
                        transition: 300ms ease transform;
                    }
                }

                &::before {
                    content: none;
                }

            }
            .active-name {
                font-size: 1.1rem;
                font-weight: 500;
                list-style: none;
                color: var(--dark-grey);
                transition: .5s all ease;
                text-decoration: none;

                @media screen and (max-width: 767px){
                    font-size: .9rem;
                }
                
                &:hover {
                    cursor: default;
                }

                &::before {
                    content: none;
                }

                &::after {
                    content: '';
                    width: 100%;
                    height: 7px;
                    background-color: var(--primary);
                    border-radius: 5px;
                    display: block;
                    position: absolute;
                    bottom: -4px;
                }
            }
        }
    }
`;

export const TabContent = styled.div`
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    color: var(--paragraph);
    
    @media screen and (max-width: 991px) {
        font-size: .9rem;
        line-height: 1.4rem;
    }

    @media screen and (max-width: 480px) {
        font-size: .8rem;
        line-height: 1.3rem;
    }

    &::before, &::after {
            display: none;
    }

    .tab-content {
        margin-bottom: 4rem;
        display: none;

        .title-dark {
            display: block;
            font-size: 1.8rem;
            font-family: 600;
            line-height: 3rem;
            margin-bottom: 1rem;

            @media screen and (max-width: 480px){
                font-size: 1.2rem;
                margin-bottom: 0;
            }
        }

        ul {
            padding: 0;

            li {
                list-style: none;
                display: flex;
                margin-bottom: 1rem;

                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                }


                .check-icon {
                    font-size: 1rem;
                    margin-right: .8rem;

                    @media screen and (max-width: 767px) {
                        font-size: .8rem;
                        margin-right: .4rem;
                    }
                }

                .font-bold {
                    font-weight: 700;
                }
            }
        }

        .title-light {
            display: block;
            font-size: 1.4rem;
            font-family: 500;
            line-height: 2rem;
            margin-top: 2rem;
            margin-bottom: .3rem;

            @media screen and (max-width: 767px) {
                font-size: 1.2rem;
                line-height: 1.6rem;
                margin-top: 1.5rem;
                margin-bottom: .6rem;
            }
        }

        p {
            margin-bottom: 1rem;
        }
    }

    .active-content {
        display: block;
    }
`;