import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from "../components/PageTitle";
import { MainContainer } from "../globalStyles";
import PrimaryButton from '../components/PrimaryButton';

const style = {
    textDecoration: 'none',
    '&:hover': {
        color: '#F47935'
    }
}

function PageNotFound() {
    return(
        <>
            <PageTitle 
                title= 'Page Not Found'
            />
            <MainContainer>
                <Row className='justify-content-center'>
                    <Col>
                        <img 
                            src='../images/group-1824.svg' 
                            style={{marginTop: '-2rem'}}
                            alt='erro 404'
                        />
                    </Col>
                    <Col>
                        <div 
                            style={{
                                textAlign: 'left'
                            }}
                        >
                            <p>Sorry, It's broken…</p>
                            <p style={{marginBottom: '1.5rem'}}>The link you are trying is not available. No worries you can just click the logo and head back home. Or click the below links for access to more resources.</p>
                            <ul style={{listStyle: 'none', padding: '0', margin: '0'}}>
                                <li style={{marginBottom: '.8rem'}}>
                                    <Link 
                                        to='/case-studies'
                                        style={style}
                                    >
                                        <img src='../images/bulb-icon.svg' 
                                            style={{width: 'auto', maxHeight: '1.5rem', marginRight: '.7rem'}}
                                            alt='artographer'
                                        />
                                        Read Case Studies
                                    </Link>
                                </li>
                                <li style={{marginBottom: '.8rem'}}>
                                    <Link 
                                        to='/portfolio'
                                        style={style}
                                    >
                                        <img src='../images/bulb-icon.svg' 
                                            style={{width: 'auto', maxHeight: '1.5rem', marginRight: '.7rem'}}
                                            alt=''
                                        />
                                            View Our Portfolio
                                    </Link>
                                </li>
                                <li style={{marginBottom: '4.5rem'}}>
                                    <Link 
                                        to='/contact-us'
                                        style={style}
                                    >
                                        <img src='../images/bulb-icon.svg' 
                                            style={{width: 'auto', maxHeight: '1.5rem', marginRight: '.7rem'}}
                                            alt=''
                                        />
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                            <Link to='/'>
                                <PrimaryButton
                                    smalltext= 'Just simple :)'
                                    maintext= 'Go Back Home Page'
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
}

export default PageNotFound;