import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col } from 'react-bootstrap';
import { CsContainer } from '../../styles/CaseStudiesPageFormat.style';
import PageTitleFullWidth from '../../components/PageTitleFullWidth';
import { Helmet } from 'react-helmet';


const CsPageFormat = (props) => {
    return (
        <>
            <Helmet>
                    <title>
                        {props.helmetTitle}
                    </title>
                    <meta 
                        name="descriptions" 
                        content={props.helmetContent}
                    />
                </Helmet>
            <PageTitleFullWidth
                title= {props.title}
            />
            <CsContainer>
                <Col lg={10} className= 'justify-content-center'>
                    
                </Col>
            </CsContainer>
        </>
    );
}

export default CsPageFormat;
