import React from 'react';
import { DividerStyle } from '../globalStyles';

const Divider = () => {
    return (
        <DividerStyle>
            <img className='desktop_divider'
                src='../images/divider.svg'
                style={{maxWidth: '100%', height: 'auto'}}
                alt='desktop divider'
            />
            <img className='mobile_divider'
                src='../images/divider-mobile.svg'
                style={{maxWidth: '100%', height: 'auto'}}
                alt='mobile divider'
            />
        </DividerStyle>
    );
}

export default Divider;
