import styled from "styled-components";


export const SectionContainer = styled.div`
    text-align: left;

    .grid-wrap {

        @media screen and (max-width: 767px){
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .wlld-section-title {
        text-align: left;
        margin-bottom: 1rem;

        @media screen and (max-width: 480px){
            margin-bottom: .5rem;
        }
    }

    .wlld-section-subtitle {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: -1px;

        @media screen and (max-width: 480px){
            font-size: 1.1rem;
            line-height: 1.5rem;
            margin-bottom: -.5rem;
        }
    }

    .bold-content {
        font-size: 1.7rem;
        line-height: 2rem;
        color: #333333;
        margin-bottom: 1.5rem;
    }

    .wlld-quote-button {
        display: block;
        text-align: center;
        margin-top: 1.5rem;

        @media screen and (max-width: 480px){
            margin-top: .5rem;
            text-align: left;
        }
    }

    img {
        margin-top: -1.5rem;
    }

    .list-item {
        display: flex;
        margin-bottom: 1rem;

        @media screen and (max-width: 767px){
            margin-bottom: .5rem;
        }

        .check-icon {
            color: var(--primary);
            margin-right: .8rem;
            margin-top: .4rem;
        }
    }

    .column-third {

        @media screen and (max-width: 480px){
            margin-top: -1.5rem;
        }    
    }
`;