import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { SectionTitle } from '../globalStyles';
import { SectionContainer } from "../styles/ResultDriven.style";



function ResultDriven(props) {
    return(
        <SectionContainer>
            <SectionTitle className="worf-section-title">{props.header}</SectionTitle>
                <div className="worf-block-container">
                    <Row>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-1">
                            <h4 className="wofr-block-title">{props.title1}</h4>
                            <p className="wofr-block-text">{props.content1}</p>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-2">
                            <h4 className="wofr-block-title">{props.title2}</h4>
                            <p className="wofr-block-text">{props.content2}
                            </p>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-3">
                            <h4 className="wofr-block-title">{props.title3}</h4>
                            <p className="wofr-block-text">{props.content3}</p>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-4">
                            <h4 className="wofr-block-title">{props.title4}</h4>
                            <p className="wofr-block-text">{props.content4}</p>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-5">
                            <h4 className="wofr-block-title">{props.title5}</h4>
                            <p className="wofr-block-text">{props.content5}</p>
                        </Col>
                        <Col lg={4} md={6} sm={12} className="wofr-block br-6">
                            <h4 className="wofr-block-title">{props.title6}</h4>
                            <p className="wofr-block-text">{props.content6}</p>
                        </Col>
                    </Row>
                </div>
        </SectionContainer>
    );
}


export default ResultDriven;