import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  position: absolute;
  left: 23rem;
  top: -2.5rem;

  .try {
    display: inline-block;
    transform-origin: 50% 0;
    animation: moveIt .5s ease-in-out infinite;

    img {
        width: 100%;
        height: auto;
    }
  }

  @keyframes moveIt {
    0%,
    100% {
      transform: rotate(3deg);
    }
    50% {
      transform: rotate(-3deg);
    }
  }
`;

const HiringAnimated = () => {
  return (
    <Styles>
      <div className="try">
        <img src="/images/hiring.webp" alt="artographer tacts" />
      </div>
    </Styles>
  );
};

export default HiringAnimated;
