import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import About from "./pages/About";
import React from "react";
import Home from "./pages/Home"
import NavbarPro from "./components/NavbarPro";
import Quote from "./pages/Quote";
import LogoDesign from "./pages/LogoDesign";
import GraphicDesign from "./pages/GraphicDesign";
import WebRedesign from "./pages/WebRedesign";
import SocialMedia from "./pages/SocialMedia";
import MobileAppUI from "./pages/MobileAppUI";
import WordPress from "./pages/WordPress";
import ThemeDevelopment from "./pages/ThemeDevelopment";
import MarketPlace from "./pages/MarketPlace";
import MembershipWeb from "./pages/MembershipWeb";
import MagentoWeb from "./pages/MagentoWeb";
import WooCommerce from "./pages/WooCommerce";
import MobileApp from "./pages/MobileApp";
import PhpWeb from "./pages/PhpWeb";
import NonprofitWeb from "./pages/NonprofitWeb";
import StartupWeb from "./pages/StartupWeb";
import AndroidApp from "./pages/AndroidApp";
import DrupalWeb from "./pages/DrupalWeb";
import EcommerceWeb from "./pages/EcommerceWeb";
import ShopifyWeb from "./pages/ShopifyWeb";
import WebApp from "./pages/WebApp";
import ReactJs from "./pages/React";
import VueJs from "./pages/VueJs";
import DigitalMarketing from "./pages/DigitalMarketing";
import Seo from "./pages/Seo";
import SocialMediaMarketing from "./pages/SocialMediaMarketing";
import Ppc from "./pages/Ppc";
import Facebook from "./pages/Facebook";
import ContentMarketing from "./pages/ContentMarketing";
import MarketingAutomation from "./pages/MarketingAutomation";
import PageNotFound from "./pages/404";
import Portfolio from "./pages/Portfolio";
import CureHeaven from "./pages/portfolio_pages/CureHeaven";
import ThankYou from "./pages/ThankYou";
import TestimonialPage from "./pages/TestimonialPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermConditions from "./pages/TermConditions";
import SiteMap from "./pages/SiteMap";
import Services from "./pages/Services";
import FooterPro from "./components/FooterPro";
import BackToTopButton from './components/BackToTopButton';
import CaseStudiesPage from "./pages/CaseStudiesPage";
import JmTravels from "./pages/case_studies_pages/JmTravels";
import Contact from "./pages/Contact";
import GraphicDesigner from "./pages/hire/GraphicDesigner";
import WebDeveloper from "./pages/hire/WebDeveloper";
import AllSongLyrics from "./pages/portfolio_pages/AllSongLyrics";
import WetLab from "./pages/portfolio_pages/WetLab";
import Kenzi from "./pages/portfolio_pages/Kenzi";
import PickUrPet from "./pages/portfolio_pages/PickUrPet";
import Moexa from "./pages/portfolio_pages/Moexa";
import Braccio from "./pages/portfolio_pages/Braccio";
import BeePac from "./pages/portfolio_pages/BeePac";
import Boat from "./pages/portfolio_pages/Boat";
import Twinkle from "./pages/portfolio_pages/Twinkle";
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import StationeryDesign from "./pages/StationeryDesign";
import BrochureDesign from "./pages/BrochureDesign";
import DigitalMarketingManager from "./pages/hire/DigitalMarketingManager";
import ReactGA from 'react-ga';
import VinayArts from "./pages/portfolio_pages/VinayArts";
import JmTravelsPortfolio from "./pages/portfolio_pages/JmTravelsPortfolio";
import Dynavation from "./pages/portfolio_pages/Dynavation";
import CharterAviation from "./pages/portfolio_pages/CharterAviation";


function App() {

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }
    document.addEventListener("contextmenu", handleContextMenu)
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu)
    }
  }, [])

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-236278048-1', {
      gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
      }
    });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div className="App">
      <Helmet>
          <title>Web Development Company, Website Design Services, Digital Agency India, Graphic Design Company</title>
          <link rel="canonical" href="https://www.artographertacts.com/" />
          <meta 
            name="descriptions" 
            content="Artographer, an India based web development company focusing global web design services (USA, UK, Europe) for ✅ custom web design, ✅ website development, ✅ digital marketing, and ✅ SEO services digital agency white label partners" 
          />
          <meta 
            name="keywords"
            content="website development, website design, seo, graphic design, logo design, digital marketing, web app development"
          />
      </Helmet>
      <ScrollToTop />
      <GlobalStyle />
      <NavbarPro />
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-artographer/" element={<About />} />
          <Route exact path="/request-a-quote/" element={<Quote />} />
          <Route exact path="/services/logo-design-company/" element={<LogoDesign />} />
          <Route exact path="/services/website-redesign-company/" element={<WebRedesign />} />
          <Route exact path="/services/social-media-design/" element={<SocialMedia />} />
          <Route exact path="/services/mobile-app-ui-design/" element={<MobileAppUI />} />
          <Route exact path="/services/wordpress-development-services/" element={<WordPress />} />
          <Route exact path="/services/wordpress-theme-development/" element={<ThemeDevelopment />} />
          <Route exact path="/services/marketplace-website-design/" element={<MarketPlace />} />
          <Route exact path="/services/membership-website-design/" element={<MembershipWeb />} />
          <Route exact path="/services/magento-website-design/" element={<MagentoWeb />} />
          <Route exact path="/services/woocommerce-website-design/" element={<WooCommerce />} />
          <Route exact path="/services/mobile-app-development/" element={<MobileApp />} />
          <Route exact path="/services/php-website-design/" element={<PhpWeb />} />
          <Route exact path="/services/non-profit-website-design/" element={<NonprofitWeb />} />
          <Route exact path="/services/startup-website-design/" element={<StartupWeb />} />
          <Route exact path="/services/android-app-development/" element={<AndroidApp />} />
          <Route exact path="/services/drupal-website-development/" element={<DrupalWeb />} />
          <Route exact path="/services/ecommerce-website-development/" element={<EcommerceWeb />} />
          <Route exact path="/services/shopify-website-development/" element={<ShopifyWeb />} />
          <Route exact path="/services/web-app-development/" element={<WebApp />} />
          <Route exact path="/services/reactjs-development/" element={<ReactJs />} />
          <Route exact path="/services/vuejs-development/" element={<VueJs />} />
          <Route exact path="/services/digital-marketing-services/" element={<DigitalMarketing />} />
          <Route exact path="/services/seo-services/" element={<Seo />} />
          <Route exact path="/services/social-media-marketing/" element={<SocialMediaMarketing />} />
          <Route exact path="/services/pay-per-click-services/" element={<Ppc />} />
          <Route exact path="/services/facebook-marketing-company/" element={<Facebook />} />
          <Route exact path="/portfolio/" element={<Portfolio />} />
          <Route exact path="/portfolio/cure-heaven/" element={<CureHeaven />} />
          <Route exact path="/thank-you/" element={<ThankYou />} />
          <Route exact path="/testimonials/" element={<TestimonialPage />} />
          <Route exact path="/privacy-policy/" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions/" element={<TermConditions />} />
          <Route exact path="/site-map/" element={<SiteMap />} />
          <Route exact path="/services/" element={<Services />} />
          <Route exact path="/services/marketing-automation/" element={<MarketingAutomation />} />
          <Route exact path="/services/content-marketing-services/" element={<ContentMarketing />} />
          <Route exact path="/services/graphic-design-company/" element={<GraphicDesign />} />
          <Route exact path="/case-studies/" element={<CaseStudiesPage />} />
          <Route exact path="/case-studies/jm-travels/" element={<JmTravels />} />
          <Route exact path="/contact-us/" element={<Contact />} />
          <Route exact path="/services/hire-a-graphic-designer/" element={<GraphicDesigner />} />
          <Route exact path="/services/hire-a-web-developer/" element={<WebDeveloper />} />
          <Route exact path="/portfolio/all-song-lyrics/" element={<AllSongLyrics />} />
          <Route exact path="/portfolio/wet-lab/" element={<WetLab />} />
          <Route exact path="/portfolio/kenzi/" element={<Kenzi />} />
          <Route exact path="/portfolio/pick-ur-pet/" element={<PickUrPet />} />
          <Route exact path="/portfolio/moexa/" element={<Moexa />} />
          <Route exact path="/portfolio/braccio/" element={<Braccio />} />
          <Route exact path="/portfolio/bee-pac/" element={<BeePac />} />
          <Route exact path="/portfolio/boat-media/" element={<Boat />} />
          <Route exact path="/portfolio/vinay-arts/" element={<VinayArts />} />
          <Route exact path="/portfolio/jm-travels/" element={<JmTravelsPortfolio />} />
          <Route exact path="/portfolio/dynavation-electronics/" element={<Dynavation />} />
          <Route exact path="/portfolio/charter-aviation/" element={<CharterAviation />} />
          <Route exact path="/portfolio/twinkle-architecture-designs/" element={<Twinkle />} />
          <Route exact path="/services/business-stationery-design/" element={<StationeryDesign />} />
          <Route exact path="/services/brochure-design/" element={<BrochureDesign />} />
          <Route exact path="/services/hire-digital-marketing-manager/" element={<DigitalMarketingManager />} />
          <Route exact path="/error-404" element={<PageNotFound />} />
          <Route path="/*" element={<Navigate to='/error-404' />} />
      </Routes>
      <BackToTopButton />
      <FooterPro />
    </div>
  );
}

export default App;