import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import { ProcessTabWrap } from '../styles/ProcessTab.style';
import { FaChevronCircleRight } from "react-icons/fa";
import { MainContainer } from "../globalStyles";
import { Link } from "react-router-dom";


function ProcessTab() {

    const [ toggleState, setToggleState ] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return(
        <MainContainer>
            <ProcessTabWrap>
                <div className='process-title'>
                    <div className= {toggleState === 1 ? "process-title-tab active" : "process-title-tab"} 
                    onClick={() => toggleTab(1)}>
                        <h3 className="bold-number">1</h3>
                        <h4>Brand</h4>
                    </div>
                    <div className= {toggleState === 2 ? "process-title-tab active" : "process-title-tab"} 
                    onClick={() => toggleTab(2)}>
                        <h3 className="bold-number">2</h3>
                        <h4>Build</h4>
                    </div>
                    <div className= {toggleState === 3 ? "process-title-tab active" : "process-title-tab"} 
                    onClick={() => toggleTab(3)}>
                        <h3 className="bold-number">3</h3>
                        <h4>Grow</h4>
                    </div>
                </div>
                <div className='process-content'>
                    <div className= {toggleState === 1 ? "process-content-tab active" : "process-content-tab"}>
                        <Row>
                            <div className="col-md-7 col-sm-12">
                                <div className='process-content-left'>
                                    <h4>Define your message and captivate your audience.</h4>
                                    <p>
                                        Our team analyzes and measures your brand's message and online 
                                        health with an in-depth brand analysis. We highlight your brand's 
                                        strengths, weaknesses, threats, and opportunities. Then we develop 
                                        a brand story and strategy so you can truly connect with your 
                                        future loyal customers.
                                    </p>
                                    <div className='process-button'>
                                        <Link className="link-btn" to="/">
                                            <FaChevronCircleRight /> &nbsp;Know More About Brand
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className='process-content-right'>
                                    <img 
                                        src="../images/artographer-brand-image.webP" 
                                        alt="brand"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className= {toggleState === 2 ? "process-content-tab active" : "process-content-tab"}>
                        <Row>
                            <div className="col-md-7 col-sm-12">
                                <div className='process-content-left'>
                                    <h4>Develop a world-class website, connected to the best digital tools.</h4>
                                    <p>
                                        Our team of design and content experts rebuild your website and other 
                                        online pages and connect them to the best digital tools and automations, 
                                        saving you time, money, and frustration. We partner with a wide array 
                                        of world-class solutions meaning you don't have to pay top-dollar for 
                                        top-notch service.
                                    </p>
                                    <div className='process-button'>
                                        <Link className="link-btn" to="/">
                                            <FaChevronCircleRight /> &nbsp;Know More About Build
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className='process-content-right'>
                                    <img 
                                        src="../images/artographer-build-image.webP"
                                        alt="build"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className= {toggleState === 3 ? "process-content-tab active" : "process-content-tab"}>
                        <Row>
                            <div className="col-md-7 col-sm-12">
                                <div className='process-content-left'>
                                    <h4>Enjoy on-going digital support and strategy with our team of experts.</h4>
                                    <p>
                                        Grow your business through on-going support and strategy with our experts. We 
                                        don't deal with expensive agency contracts or complicated subscriptions. We 
                                        train your team on how to manage your brand and tools, so you grow autonomously. 
                                        You can also choose to connect with one of our trusted digital marketing 
                                        partners. It's time to brand, build, and grow.
                                    </p>
                                    <div className='process-button'>
                                        <Link className="link-btn" to="/">
                                            <FaChevronCircleRight /> &nbsp;Know More About Grow
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <div className='process-content-right'>
                                    <img 
                                        src="../images/artographer-growth-image.webP"
                                        alt="growth"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </ProcessTabWrap>
        </MainContainer>
    );
}

export default ProcessTab;





