import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Twinkle = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Twinkle Architecture</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/twinkle-architecture-designs"
        />
        <meta
          name="descriptions"
          content="Our talented graphic design team created a logo for Twinkle Architecture, and the client is thrilled with the result. Elegant design, satisfying outcome."
        />
      </Helmet>
      <PortfolioPage
        title="Logo design for Twinkle Architecture Designs"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/twinkle-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Twinkle Architecture Designs"
        industry="Architecture"
        requirement="The client was a female architect and approached us for a bold logo and website. She was a referral client by our existing client."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/twinkle-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            After a brainstorming session, we created a bold logo for the client
            with a combination of text and related design.
            <br />
            <br />
            We chose green and blue for the logo specifically as the client
            asked for a exotic color combination. Then, we submitted a few
            options initially to the client. After a few iterations, the client
            was happy with the final logo that we designed.
          </span>
        }
        bottomImage={<img src="../images/portfolio/twinkle-logo.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/pick-ur-pet">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/bee-pac">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Twinkle;
