import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import { SectionTitle } from "../globalStyles";
import { SectionContainer } from "../styles/DigitalMarketingTools.style";



function DigitalMarketingTools(props) {
    return(
        <SectionContainer>
            <div className="digital-marketing-tools">
                <SectionTitle>{props.title}</SectionTitle>
                {props.image}
                <Container className="bottom-wrap">
                    <Row>
                        <Col className="list-item-container1">
                            <div className="list-item-wrap">
                                <p>{props.column1}</p>
                            </div>
                        </Col>
                        <Col className="list-item-container2">
                            <div className="list-item-wrap">
                                <p>{props.column2}</p>
                            </div>
                        </Col>
                        <Col className="list-item-container3">
                            <div className="list-item-wrap">
                                <p>{props.column3}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SectionContainer>
    );
}

export default DigitalMarketingTools;