import React from 'react';
import CsPageFormat from './CsPageFormat';


const JmTravels = () => {
    return (
        <CsPageFormat 
            title= 'Taxi Booking Website from Scratch Case Studies'
        />
    );
}

export default JmTravels;