import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function MarketPlace() {
    return(
        <Page>
            <Helmet>
                <title>
                    Online Multi Vendor eCommerce Marketplace Website Design and Development Services Company India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/marketplace-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Artographer is a best marketplace website design and development company in India. with the ability to carter to your website design services requirements. Click to learn more"
                />
            </Helmet>
            <PageTitle
                title= "Marketplace Website Development"
                text1= "Artographer has a solid track record in marketplace website and CMS development and has covered a large array of applications."
                text2= "If you want to build a marketplace website, our expert team will help you to step ahead from the competition by providing you with a stunning design."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Are Online Marketplace Websites the Future?"
                    content= "Yes, they are! The e-commerce marketplace is expected to reach $27.7 trillion in 2020. A multi-vendor marketplace is a platform that helps you build a community of sellers that can offer their product/service on your behalf."
                    content2= "A well-designed/developed marketplace website can almost have every feature. And advancements in technology have opened up opportunities to minimize and overcome traditional business risks. Even buyer opts for marketplaces as they get a large selection of products and services, compare items, get user reviews and conduct an online research prior to buying anything."
                    image= '../images/artographer-marketplace-website.png'
                    alt= 'artographer marketplace website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <LocationbasedService
                    title= "How do Our Marketplace Development Company Work?"
                    content= "Marketplace and listing sites are designed to present information in a format that’s easy to browse so that visitors can find what they’re looking for quickly and efficiently. As a rule of thumb, marketplace websites can be difficult to integrate if not properly done by website design and development experts. Our ColorWhiste team can build you an online marketplace website with all the basic and advanced features to deliver a high-end user experience."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Multi Vendor E-commerce Marketplace Services"
                content= "Our marketplace website development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Why Choose Artographer for Designing and Developing Your Online Marketplace Website?"
                    title1= "One Stop Solution"
                    content1= "You need not go elsewhere as our end-to-end marketplace website design and development services cover a wide range of solutions."
                    title2= "Deliver Real Value"
                    content2= "Our developers have immense knowledge in various techniques and add real value to your project."
                    title3= "Straightforward Advice"
                    content3= "Our developers give honest advice before and during the development process."
                    title4= "Quality Coding"
                    content4= "Websites developed by our expert marketplace developers are well-coded, reliable and secure."
                    title5= "Transparency"
                    content5= "We involve you throughout the design and development process, give you regular updates, and get your feedback."
                    title6= "Timely Delivery"
                    content6= "Our team of experienced marketplace designers and developers completes each project on the set time frame as they follow proven techniques and a result-oriented approach."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Online Marketplace Website Development"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label marketplace website is suitable for agencies that are looking for extra development resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits. Our WordPress developers will make sure all the pieces of marketplace website design, development and maintenance are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.<br /><br />All of our B2B services are designed to off-load the marketplace development needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Marketplace Website Development Services"
                    content= {<span>We have worked with marketplace website clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a marketplace website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MarketPlace;