import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { TabContent, TabTitles } from "../styles/TestimonialPage.style";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import Iframe from '../components/Iframe';
import {Helmet} from 'react-helmet';




const TestimonialPage = () => {

    const [ toggleState, setToggleState ] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }
    
    return (
        <div>
            <Helmet>
                <title>
                    Artographer Reviews: Customer Reviews / Client Testimonials
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/testimonials/" />
                <meta 
                    name="descriptions" 
                    content="Artographer Reviews - Customer reviews & testimonials for Artographer - With 20+Years of domain expertise we have a staggering 95%+ customer satisfaction rate!"
                />
            </Helmet>
            <PageTitle
            title= "Client Testimonials"
            />
            <MainContainer>
                <SectionTitle style={{textAlign: 'center'}}>
                    A place for everything;<br />everything in its place
                </SectionTitle>
                <div className="col-md-10 col-sm-12 mx-auto">
                    <TabTitles>
                        <div className="tab-title-container">
                            <Row className="justify-content-center">
                                <div className='col-5 col-md-4 col-sm-5'>
                                    <div className='title-wrap'>
                                        <li className= {toggleState === 1 ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab(1)}
                                        >
                                            Written Reviews
                                        </li>
                                    </div>
                                </div>
                                <div className='col-5 col-md-4 col-sm-5'>
                                    <div className='title-wrap'>
                                        <li className= {toggleState === 2 ? "active-name" : "tab-name"} 
                                            onClick={() => toggleTab(2)}
                                        >
                                            Video Reviews
                                        </li>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </TabTitles>
                    <TabContent>
                        <div className= {toggleState === 1 ? "tab-content active-content" : "tab-content"}>
                            <div className='col-lg-9 col-md-10 col-sm-12 mx-auto'>
                                <div className='test-container'>
                                    <span className='test-title'>
                                        Known for their professionalism
                                    </span>
                                    <p className='test-content'>
                                        We were looking for a professional web development team to partner with. Their instant reply to my enquiry and level of thought made them stand out on our shortlist. We were inspired by their clear, concise, and timely communication with us.
                                    </p>
                                    <p className='client-partner'>
                                        Client Partner – Project Manager, Water and Energy Technologies USA
                                    </p>
                                </div>
                                <div className='test-container'>
                                    <span className='test-title'>
                                        Very Positive experience
                                    </span>
                                    <p className='test-content'>
                                        This was one of the most fun piece of starting a new business. I would highly recommend Artographer for creative and custom designs.
                                    </p>
                                    <p className='client-partner'>
                                        Client Partner – Managing Director, Prakam Aura Pvt. Ltd.
                                    </p>
                                </div>
                                <div className='test-container'>
                                    <span className='test-title'>
                                        Excellent services
                                    </span>
                                    <p className='test-content'>
                                        Great design team and quick turn around on all projects and request. With their help we have improved our google search results.  My company Titan has depended on them for quite sometime now and we have been doing business with them for over 3 years. Their web design team is very knowledgeable and they always let us know when a new Google update is coming up before anyone even knows. If you want a reliable Web Design Company all them! I recommend 100%
                                    </p>
                                    <p className='client-partner'>
                                        Client Partner – Marketing Head, BOAT Media Pvt. Ltd.
                                    </p>
                                </div>
                                <div className='test-container'>
                                    <span className='test-title'>
                                        Best Web Designer
                                    </span>
                                    <p className='test-content'>
                                        My company just wanted to say that we were very impressed with the quality of the website, the price we paid compared to other web design company was extremely low. I love the finished product you provided us. I highly recommend you as a top website designer.
                                    </p>
                                    <p className='client-partner'>
                                        Client Partner – Director, Studio99.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 2 ? "tab-content active-content" : "tab-content"}>
                            <div>
                                <div className='youtube-test-container'>
                                    {/* <Row className='align-items-center'>
                                        <Col md={6} sm={12}>
                                            <div className='youtube-thumb'>
                                                <Iframe 
                                                    src= 'https://www.youtube.com/embed/E7wJTI-1dvQ'
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div className='test-message-wrap'>
                                                <h5 className='test-client-desc'>
                                                    Client Partner - Armin Graf
                                                </h5>
                                                <p className='test-client-message'>
                                                    We have known Armin for more than a year now. Armin is a very professional and an energetic partner to work with. We have worked together on multilingual PHP projects. Watch the video to see what Armin has to say about us. Thanks a lot Armin!
                                                </p>
                                            </div>
                                        </Col>
                                    </Row> */}
                                </div>
                            </div>
                        </div>
                    </TabContent>
                </div>
            </MainContainer>       
        </div>
    );
}

export default TestimonialPage;
