import styled from "styled-components";


export const PhilosophyWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px){
        margin-top: -2rem;
    }

    .philo-image {
        padding-right: 50px;
        margin: auto;
        height: 350px;

        @media screen and (max-width: 991px){
            height: auto;
        }


        @media screen and (max-width: 767px){
            width: 80%;
            height: 350px;
            padding: 0;
        }

        @media screen and (max-width: 480px){
            height: auto;
        }


        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }

    .philo-content {
        text-align: left;
        padding-left: 50px;

        @media screen and (max-width: 991px){
            padding: 0;
        }

        @media screen and (max-width: 767px){
            padding: 0;
            margin-top: 2.5rem;
        }

        @media screen and (max-width: 480px){
            margin-top: 1.5rem;
        }
    }

    h1 {
        margin-bottom: 2rem;
        font-size: 2.5rem;
        font-weight: 600;
        letter-spacing: -1px;
        color: var(--dark-grey);
    }
`;