import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const CharterAviation = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Charter Aviation</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/charter-aviation"
        />
        <meta
          name="descriptions"
          content="Our skilled web developers crafted a stunning and fast website for Charter Aviation, resulting in a highly satisfied client. Explore our successful project!"
        />
      </Helmet>

      <PortfolioPage
        title="Branding and Website Development for Charter Aviation"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/charter-aviation-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Charter Aviation"
        industry="Aviation Company"
        requirement="A client from Kolkata, India, sought our collaboration for their comprehensive website development project, encompassing web design and content creation to enhance their online presence."
        process="We adapted our standard process to meet the client's unique requirements by brainstorming within our team, crafting a customized approach that perfectly aligned with the client's goals and objectives."
        bgImage={"../images/portfolio/charter-aviation-hero1.webp"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            After carefully listening to the client's detailed requirements, we
            presented three unique UI designs for their website. Each design was
            crafted to reflect the client's vision and brand identity.
            <br />
            <br />
            Once the client selected their preferred design, we promptly began
            developing the website, ensuring it aligned perfectly with the
            aviation industry's standards. The website was tailored to allow the
            client to upload detailed trip descriptions and display live flight
            and helicopter statuses on the admin panel.
            <br />
            <br />
            After a few revisions to fine-tune the final product, the client was
            fully satisfied with the results, achieving a seamless online
            experience.
          </span>
        }
        bottomImage={
          <img src="../images/portfolio/charter-aviation-mobile.webp" alt="" />
        }
        preButton={
          <Link to="/" style={{ visibility: "hidden" }}>
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/dynavation-electronics">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default CharterAviation;
