import styled from "styled-components";


export const DarkContainerWrap = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
    background-color: #121A24;
    max-width: 1300px;
    padding: 0;
    
    .inner-container {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
        padding: 3rem 70px 2.5rem 70px;
        text-align: left;
        color: white;

        @media screen and (max-width: 480px){
            padding: 2rem 15px 1.5rem 15px;
        }

        h3 {
            font-size: 2.8rem;

            @media screen and (max-width: 767px){
                font-size: 2rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
            }
        }
    }
`;


export const ButtonContainer = styled.div`
    margin-top: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0;

    @media screen and (max-width: 767px){
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 0;
    }

    @media screen and (max-width: 480px){
        margin: 0;
    }

    .button-inner-wrap {
        width: auto;
        float: left;
        text-decoration: none;

        &:first-child {
            margin-right: 3rem;

            @media screen and (max-width: 767px){
                margin: 0;
                margin-bottom: 1.5rem;
            }

            @media screen and (max-width: 480px){
                margin-bottom: 1rem;
            }
        }

        .hover-button {
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: transform;

            &:hover {
                transform: translateX(10px);
            }

            .button-enter-icon {
                font-size: 1.5rem;
                color: #121A24;
            }
        }
    }
`;