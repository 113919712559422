import React from 'react';
import { ClientsLogoStyle } from '../globalStyles';

const ClientsLogo = () => {
    return (
        <ClientsLogoStyle>
            <img 
                src='../images/artographer-clients-logo.svg' 
                alt='artographer-clients' 

            />
        </ClientsLogoStyle>
    );
}

export default ClientsLogo;
