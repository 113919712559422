import styled from "styled-components";


export const PageWrap = styled.div`

.pp-title-image-wrap {
        width: auto;

        .title-image {
            width: auto;
            max-height: 298px;
            object-fit: cover;
        }
    }

    .pp-title-wrap {
        text-align: left;
        display: block;
        
        .pp-item-list{
            font-size: 1.2rem;
            letter-spacing: -1px;
            line-height: 1.7rem;
            display: flex;
            margin-bottom: .5rem;

            @media screen and (max-width: 480px){
                font-size: .9rem;
                line-height: 1.4rem;
            }

            .pp-line-head {
                font-weight: 700;
                margin-right: .3rem;
                display: block;
            }

            .pp-line-desc {
                font-weight: 400;
                margin-top: -.2rem;
                display: block;
            }

            .bg {
                margin-top: 0;
            }
        }

        .bg-cont {
            display: block;
        }
    }

    .pp-content-wrap {
        text-align: left;

        .pp-bottom-title {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 2rem;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            display: block;

            @media screen and (max-width: 767px){
                font-size: 1.3rem;
            }
            
            @media screen and (max-width: 480px){
                font-size: 1.1rem;
            }
        }
    }

    .pp-bottom-wrap {
        margin-top: 2rem;
        margin-bottom: 2rem;

        .bottom-image-wrap {
            margin-bottom: 4rem;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .pp-button-wrap {

            .previous {
                margin-left: .7rem;
                transition: all .3s ease;

                &:hover {
                    margin-left: 0;
                    transition: all .5s ease;
                }
            }

            .next {
                margin-right: .7rem;
                transition: all .2s ease;

                &:hover {
                    margin-right: 0;
                    transition: all .3s ease;
                }
            }

            button {
                font-size: 1.2rem;
                font-weight: 500;
                color: var(--paragraph);
                border: none;
                background-color: transparent;

                &:hover {
                    color: var(--primary);
                }

                .icon-left {
                    font-size: 2rem;
                    color: var(--paragraph);
                    margin-right: .5rem;
                }

                .icon-right {
                    font-size: 2rem;
                    color: var(--paragraph);
                    margin-left: .5rem;
                }
            }
        }
    }
`;