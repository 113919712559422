import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function MobileAppUI() {
    return(
        <Page>
            <Helmet>
                <title>
                    Mobile App UI, UX Design, Development Services Company India | Outsource Mobile App Development Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/mobile-app-ui-design/" />
                <meta 
                    name="descriptions" 
                    content="Mobile App UI Design If you’re looking to create a mobile app for your business, then let Artographer handle the UI for a truly professional look and feel. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Mobile App UI & UX Design"
                text1= "At Artographer, there are expert UI/UX developers who design interfaces which can be easily understood by everyone including beginners."
                text2= "The services provided here satisfied a lot of clients. We have received better feedbacks every time we work in UI/UX designs."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Who can Benefit from Our Mobile App UI & UX Design Services?"
                    content= "An app not just serves as a portfolio or an advertisement for your business but it also mainly allows your customers to order products more quickly and easily with a single click and enhance your service in a million other ways."
                    content2= "Many companies, irrespective of their size, are now catching up with the app-trend by outsourcing mobile app UI & UX designs to professional mobile app designers."
                    listTitle= "Some of the industries that can benefit from our mobile app UI & UX design services are"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Restaurants and fast food companies</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>B2B businesses</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Taxi firms and much more</span></span>}
                    image= '../images/artographer-mobile-app-ui-ux-design.png'
                    alt= 'mobile app ui/ux development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-design-service.webp'
                alt='web design design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Mobile App UI & UX Design Process</SectionTitle>}
                    para1= "Artographer is a mobile app design company that takes the pride in designing some of the best mobile interfaces available. We know precisely what makes an app a joy to use and we’ll ensure that it looks better than the competition. We pay close attention to the minute details, ensuring that the finished product will be the top of its class."
                    para2= {<span>Don’t settle for less! A poorly designed app not only goes unused, but it can damage your reputation and make you lose your customers.<br /><br />That’s why it’s so important to outsource mobile app UI designs and work to a mobile app design company. Your customers will feel the difference for sure!</span>}
                    para3= "If you’d like Artographer to handle your mobile app design, then just get in touch. We will be happy to start discussing your mobile app design over a no-obligation consultation and to help you decide to proceed."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits
                title= "Industries that can Benefit from Our Mobile UI & UX Design Services"
                content= "Our mobile app design team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <WhiteLabel
                    title= "White Label Mobile App UI/UX Design Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>Tech-based companies looking to outsource their website design services, we provide white label mobile app UI & UX design services.<br /><br />Any small or medium size business that is looking to create a custom mobile app design or redesign their old one.</span>}
                    content2= {<span>Start-ups and online professionals who need to create a unique mobile app design.<br /><br />Established businesses who may be opting for re-branding or logo redesign and want a fresh perspective for their customers.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Mobile App UI & UX Design Services"
                    content= "We have worked with mobile app clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a mobile app services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MobileAppUI;