import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";





function MobileApp() {
    return(
        <Page>
            <Helmet>
                <title>
                    Mobile App Development Services Company India | Mobile Application Development
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/mobile-app-development/" />
                <meta 
                    name="descriptions" 
                    content="Artographer is a leading Mobile App Design and Development company offers Windows Application Development, IPhone App Development, UI/UX design services and more. Click to learn more"
                />
            </Helmet>
            <PageTitle
                title= "Mobile App Development"
                text1= "Our dedicated team will provide you end-to-end solutions for all your mobile app requirement needs. We conduct extensive marketing research about clients, competitor’s strategy and industry leaders in order to develop a unique, leading, high-quality mobile app design."
                text2= "We also deliver innovative solutions across various industries such as healthcare, travel & tourism, real estate and more. Our company has evolved into a one-stop destination for mobile application creation to the final steps of app marketing."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Mobile App UI & UX Design Service Providers"
                    content= "At Artographer, we create intuitive and compelling mobile UI & UX development as it acts as an important criterion in any application. We understand the demands of the online market and ensure to use visually appealing colors, fonts, themes, logos, emoticons, and much more."
                    content2= "Our designs will be adaptive and responsive, compatible with modern design frameworks, and have a unique design. The designs we create will help to offer a delightful experience to users and have a huge impact on brand perception and conversion rates."
                    image= '../images/artographer-mobile-app-ui-ux-design.png'
                    alt= 'mobile app development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Leading Mobile App Development Company for Small Businesses & Startups</SectionTitle>}
                    para1= "There are over 2.7 billion smartphone users across the world. With the smartphone penetration growing at a steady rate, it’s no surprise that the mobile app industry is thriving with no signs of slowing down in the foreseeable future."
                    para2= "Artographer is a trusted mobile app design and development agency in India helping startups and small businesses. Our world-class designers and developers have the caliber to sail across the thunders and storms in the global market."
                    para3= "Right from mobile strategy, design, development and deployment, we adhere to global quality standards and methodologies. Through our expertise and innovation, we can deliver amazing apps that are suited to your requirements."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Mobile App Development Services"
                content= "Our mobile app development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Our Mobile App Development Services for Different “OS”"
                    title1= "iPhone App Development"
                    content1= "Stay unique in the online market with business-centric iPhone applications. At Artographer, we have a team of expert developers who follow coding rules to develop and design innovative iPhone applications."
                    title2= "Android App Development"
                    content2= "Reach the widest user base with scalable Android application. The Android app developers at Artographer develop end-to-end applications that can seamlessly run on any of your favorite Android devices."
                    title3= "Tablet Application Development"
                    content3= "Increase user engagement and business development with useful tablet applications. At Artographer, we have dedicated developers who leverage their skills to help clients to gain a competitive advantage."
                    title4= "Hybrid App Development"
                    content4= "Our hybrid mobile application development services are robust, high-performing, feature-packed and secure enough to handle any business and IT needs."
                    title5= "Progressive Web Apps (PWA)"
                    content5= "Our expert developers at Artographer can create secure and fast loading web pages to ensure great performance that is identical to native mobile apps."
                    title6= "Cross-Platform App Development"
                    content6= "Our mobile app development company can also create cross-platform mobile apps that run on multiple platforms by using best coding practices."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "Professional Mobile App Development for B2B Outsourcing & White label Company Services"
                    content1= {<span>White label mobile application design and development services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. You take all the credits. Our highly skilled developers will make sure all the pieces of the development process are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.<br /><br />All of our B2B services are designed to off-load the mobile application design and development needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Mobile App Development Services'
                    title1="What are the securuty measure you follow in order to make my app idea secure?"
                    content1="We ensure the complete security of your app idea by signing an NDA with you."
                    title2="How much do you charge for developing an application?"
                    content2="Application development cost starts from $5000 and may differ as per the size and complexity of the project."
                    title3="Will you help me after the application is live on the App Store?"
                    content3="Sure, we will assist you whenever you’re looking forward to resolving issues. But, the additional modifications made to live applications are charged separately."
                    title4="Will you assist me in the app submission process?"
                    content4="Ofcourse yes, we do travel with you in the submission journey."
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized Mobile App Development Services"
                    content= {<span>We have worked with mobile app development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a mobile app development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MobileApp;