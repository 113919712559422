import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Moexa = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Moexa</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/moexa"
        />
        <meta
          name="descriptions"
          content="Our skilled team crafted a beautiful, fast website for Moexa, resulting in complete client satisfaction. Discover our exceptional web development services."
        />
      </Helmet>
      <PortfolioPage
        title="Web Graphics and Website Development for Moexa"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/moexa-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Moexa Pharmaceuticals Limited"
        industry="Drug Research and Development"
        requirement="This multi national drug research and development client was from Connecticut, USA. The client approached our team for a website that could be educative material for their visitors."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/moexa-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            After understanding the requirements of the client, we started with
            a logo design. Then we kickstarted developing the website. We
            created a myriad of images and placed them across the pages relating
            to drug research.
            <br />
            <br /> As the client asked for, we added hexagone shapes on the
            website as buttons, images and icons. This hexagone demand was very
            challenging for us, as we created an high defination image of DNA
            structure using hexagone as cells was time and energy consuming.
            <br />
            <br />
            But after a few iterations, the client approved the website with
            warm wishes which was a great reward for us.
          </span>
        }
        bottomImage={<img src="../images/portfolio/moexa-web.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/kenzi">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/braccio">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Moexa;
