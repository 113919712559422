import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck, FaChevronCircleRight } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import PlanCost from "../components/PlanCost";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function GraphicDesign() {
    return(
        <Page>
            <Helmet>
                <title>
                    Graphic Design Services Company India | Online Graphic Design Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/graphic-design-company/" />
                <meta 
                    name="descriptions" 
                    content="Looking for graphic design services company near you. Artographer in India offers affordable creative graphic design services to agencies all around the world. Click to learn more."
                />
            </Helmet>
            <PageTitle
            title= "Graphic Design Services"
            text1= "Artographer is a passionate graphic design service provider in the online market. Our creative designers offer outstanding and attention-grabbing visuals for brochures, banners, flyers, business cards, e-books, and many more."
            text2= "Our graphic designs can develop a great impression on your prospect’s mind. With a complete mixture of professionalism and stunning creativity skills, our expert designers can establish a bright profile for your business in the online world."
            listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
            listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
            button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Benefits of Our Graphic Design Service Company"
                    content= "Being a pioneer in graphic design, Artographer creates magnificent designs that transform the standard of your online business across various platforms. Our designers do smart and creative work to convey your business ideas to the audience through impressive graphic designs. Moreover, we work with graphic design tools including Adobe Creative Cloud, Corel Draw, Google Slides, Canva, and many more."
                    listTitle= "Our Graphic Design Commitment Includes"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Professional and unique graphic designs at affordable price</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Streamlined communication</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Regular follow-ups for design approval</span></span>}
                    listItem4= {<span><FaCheck className="blds-list-icon"/><span>Timely delivery of projects</span></span>}
                    image= '../images/artographer-graphic-design.png'
                    alt= 'artographer graphic design'
                />
            </MainContainer>
            <PageImage
                src='../images/graphic-service.webp'
                alt='graphic design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Graphic Design Process</SectionTitle>}
                    para1= "Great website design will make your visitors stay active on your site for a long time. Our graphic designers are experts in various designing tools and have remarkable experience in handling graphic design projects across various industries."
                    para2= "Before working on designs, we collect information about your business requirements and objectives. Following that, we research your brand visions & values, competition in your market, target audience demographics, and many more."
                    para3= "Based on the market analysis and your suggestions, our designers start creating innovative and personalized designs that exceed your expectation levels."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Graphic Design Services"
                content= "Our graphic design team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Graphic Design Services"
                    title1= "Logo Design & Branding"
                    content1= "We offer the best logo design services at an attractive quality and affordable price. Our logo design experts are highly creative and create a logo the best reflects your brand message."
                    title2= "Social Media Design"
                    content2= "Our skilled designers deliver personalized social media design solutions to expand your visibility on multiple social media platforms and develop a powerful bond between your brand and the customers."
                    title3= "Website UI & UX Design"
                    content3= "With our user-centric graphic design solutions, you can win the hearts of many new & current audience. We create graphic designs with the best user experience that boost the engagement of your visitors."
                    title4= "Mobile App Design"
                    content4= "We deliver attractive and intuitive mobile app designs that lay down an engaging platform to your customers and enhance your online business growth."
                    title5= "Presentation Design"
                    content5= "Our creative presentation designers give a professional look to your typical presentation. We design visual illustrations, animations, infographics, motion graphics, and more on your presentation slides to capture the attention of your audience."
                    title6= "E-book Design"
                    content6= "We specialize in designing professional e-books and add various elements including text, audio, image, and video. Our design experts create e-books that can be viewed on web browsers, Kindle, iOS, Blackberry, Sony Reader, etc."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Logo Design Agency"
                    subtitle= "A Dedicated Partner of your Team"
                    image= {<img src="../images/graphic-design.svg" alt=""/>}
                    content2= {<span>We provide excellent white label graphic design services to tech-based companies looking to outsource their design services.<br /> <br />Our unique and impressive graphics support start-ups, online professionals, small and medium, and large-sized businesses to create an engaging graphics experience for their customers in the online sphere.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <SectionTitle className="plan-cost-section-title">Monthly Graphic Design Cost for Startups, Small Businesses, and Online Businesses</SectionTitle>
                <div className="ldcs-section-container">
                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col md={4} sm={12}>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">QUICK MINI</span>}
                                    price= "149"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>2 Infographics</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>3 Blog Images</span></span>}
                                    listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Social Media Images</span></span>}
                                    listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>1 Landing Page Design</span></span>}
                                />
                            </Col>
                            <Col md={4} sm={12}>
                                <PlanCost
                                    head2= {<span className="ldcs-tab-head primary">QUICK PRO</span>}
                                    price= "249"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Infographics</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>5 Blog Images</span></span>}
                                    listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>6 Social Media Images</span></span>}
                                    listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>2 Landing Page Design</span></span>}
                                    discount= "* 10% Off on Quarterly Billing"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Graphic Design Services"
                    content= "We have worked with graphic design clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a graphic design services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Graphic Design Services'
                    title1="How long will you take to deliver the graphic design project?"
                    content1="Frequently Asked Questions about Our Graphic Design Services Time-frame will be determined based on the size of your project and the complexity of your requirement. Normally, it takes 2-3 weeks for us to complete your graphic design that best matches your expectations."
                    title2="How much do you charge for graphic design?"
                    content2="Our price depends on the nature of your expectations and business requirements. It varies from business-to-business. To know more about our graphic design pricing options, you can drop us your message at any time. After initial discussions, we will provide you a ballpark figure of our charges."
                    title3="What if I am not satisfied with your graphic design services?"
                    content3="God forbid! We certainly do not want that to happen at any point. Unfortunately, if it happens, we will refund the payment within 90 days according to our business agreement and policy. It normally takes that time to get our accounts set right and return the funds to you."
                    title4="Will I be updated on the graphic design process?"
                    content4="Of course! Our team keeps you regularly updated on your graphic design process. Without your knowledge, we will not perform any activities in your projects."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Logo Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default GraphicDesign;