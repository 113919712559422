import styled from "styled-components";



export const Page = styled.div`

    .plan-cost-section-title{
        text-align: center;
    }
   
   .ldcs-section-container {
        padding-right: 120px;
        padding-left: 120px;

        @media screen and (max-width: 991px){
            padding: 0 60px;
        }

        @media screen and (max-width: 767px){
            padding: 0 80px;
        }

        @media screen and (max-width: 480px){
            padding: 0;
        }
    }
`;

export const CaseStudiesPageContainer = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    text-align: center;

    @media screen and (max-width: 1199px){
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .row {
        display: flex;
        flex-wrap: wrap;

        .cs-thumb-container {
            margin-bottom: 30px;
            width: 100%;
            float: left;
            position: relative;

            .cs-thumb-wrap {
                position: relative;
                margin: 0px !important;
                overflow: hidden;
                z-index: 1;

                img {
                    width: 100%;
                    float: left;
                    transition: transform 1s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
`;
