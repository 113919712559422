import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck, FaChevronCircleRight } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import PlanCost from "../components/PlanCost";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function MarketingAutomation() {
    return(
        <Page>
            <Helmet>
                <title>
                    Marketing Automation Services Company India | Whitelabel Digital Marketing Automation Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/marketing-automation/" />
                <meta 
                    name="descriptions" 
                    content="Our marketing automation company in India offers the best marketing automation services like email marketing and much more. Hire our marketing automation agency to boost your sales. Click to know more."
                />
            </Helmet>
            <PageTitle
                title= "Marketing Automation Services"
                text1= "Artographer is a professional marketing automation agency in India that can streamline your sales and marketing activities, segment your audience, target them with personalized experiences, save your time and enhance results. We can help to gather and use information that will allow you to understand your customers based on their actions."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Why Should You Outsource to Our Marketing Automation Agency?"
                    content= "Artographer's expertise in marketing technologies — combined with leveraging marketing automation to amplify customer acquisition, CRM and retention — makes us the right marketing automation agency to reach your goals."
                    listTitle= "Our team of professional marketing automation consultants can create a custom strategy that is tailored according to your needs. Whether you’re just getting started with marketing automation or looking to outsource marketing automation work to take your existing campaigns to the next level, you need Artographer."
                    image= '../images/artographer-marketing-automation.png'
                    alt= 'artographer marketing automation'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Logo Design Process</SectionTitle>}
                    para1= "A logo is the visual entry point for customers, hence, it is crucial to design appealing logos that effectively captivate the audience. At Artographer, we are specialized in designing eye-catchy logos for any business type and size."
                    para2= "Before embarking on the logo design for your business we will completely research your company's history, logo placement, logo size, logo style, and others."
                    para3= "Based on the market analysis and your suggestions, our logo designers create remarkable logo designs to build a successful brand image for your business in the online world."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Marketing Automation Services"
                content= "Our marketing automation team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <SectionTitle>Marketing Automation Platforms We Work With</SectionTitle>
                <img className="marketing-image" src="../images/digital-marketing-tools.svg" alt=""/>
                <ResultDriven 
                    title1= "Marketo"
                    content1= "Our professional marketing automation specialists can work with Marketo as it has many features that will help improve, streamline and automate your marketing."
                    title2= "ActiveCampaign"
                    content2= "Being a leading marketing automation company in India, we can work with ActiveCampaign to saves you time, money and generates more revenue for your business!"
                    title3= "Constant Contact"
                    content3= "Our marketing automation services company are experienced in Constant Contact which offers plenty of features to boost your marketing campaigns, build your brand and get more leads."
                    title4= "HubSpot"
                    content4= "As a leading marketing automation agency in India, we work with HubSpot to build a special arena for your business that makes things far easier and less cumbersome."
                    title5= "Eloqua"
                    content5= "Our marketing automation company in India can work in Eloqua and track a lead’s various areas of engagement to sway the lead to choose your business over the competition."
                    title6= "Get Response"
                    content6= "Being a professional marketing automation agency, we can work in Get Response so you can build relationships with your leads and customers while selling your goods and services."
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Our Marketing Automation Services Include"
                    title1= "Marketing Automation Platform Evaluation"
                    content1= "At Artographer, our team deals with advanced SEO practices and utilizes the leading SEO tools to produce the best results for our clients."
                    title2= "Platform Set Up and Support"
                    content2= "After our marketing automation company in India access your requirements, our team will configure the marketing automation platform and help you get started."
                    title3= "Marketing Automation Platform Integration"
                    content3= "Our professional marketing automation specialists will help to get more out of your marketing automation, customer retention management and data systems integrations."
                    title4= "Marketing Automation Platform Assessment"
                    content4= "If you are already using a marketing automation platform, our custom marketing automation company can make changes so it generates the highest ROI."
                    title5= "Demand Generation Strategies"
                    content5= "Being a marketing automation company in India, we can create custom demand generation strategies to help you make the most of your online marketing efforts."
                    title6= "Marketing Automation Management"
                    content6= "As a marketing automation agency, we can manage your entire marketing automation system from the top of the marketing funnel to the sales-ready process."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Digital Marketing Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label digital marketing services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. Our professional digital marketers will make sure all the pieces of digital marketing are successfully implemented.</span>}
                    content2= "As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the digital marketing needs of any agency. We are happy to work under your branding to take care of your customers."
                />
            </MainContainer>
            <MainContainer>
                <SectionTitle className="plan-cost-section-title">Monthly Digital Marketing Cost for Startups, Small Business and Online Businesses</SectionTitle>
                <div className="ldcs-section-container">
                    <Container>
                        <Row>
                            <Col>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Organic Born</span>}
                                    head3= {<span className="sub-head">For Beginners</span>}
                                    price= "249"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>DM Setup</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Monitor</span></span>}
                                    listItem3= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Report</span></span>}
                                />
                            </Col>
                            <Col>
                                <PlanCost
                                    head2= {<span className="ldcs-tab-head primary">Optimal Boost</span>}
                                    head4= {<span className="sub-head">For Startups and Maintenance</span>}
                                    price= "449"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Advanced SEO</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Blogging</span></span>}
                                    listItem3= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Reports</span></span>}
                                    discount= "* 10% Off on Quarterly Billing"
                                />
                            </Col>
                            <Col>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Turbo Start</span>}
                                    head3= {<span className="sub-head">For Long Term Benefits</span>}
                                    price= "649"
                                    intro= "*Starts from / Monthly + 10% Ad Fee"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Ad Campaigns & Monitoring</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Blogging + Paid Directories</span></span>}
                                    listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Marketing Consulting</span></span>}
                                    listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Marketing Automation</span></span>}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized Marketing Automation Services"
                    content= {<span>We have worked with marketing automation clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a marketing automation services.<br /> If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Digital Marketing Services'
                    title1="Are there any hidden costs?"
                    content1="As a leading marketing automation company in India, we do not charge any additional charges. Once we analyze your requirements, we will tell you the actual cost upfront and will never charge any additional cost when the project is in progress."
                    title2="Which industries benifits the most from your marketing automation services?"
                    content2="Our marketing automation services are beneficial for any industry. If you are not sure if your business will benefit from marketing automation, contact us anytime, we will help you."
                    title3="If we have any issues, how easily is it to reach your team for assistance?"
                    content3="When you face any issues and inform us, our professional marketing automation specialists in India will analyze the issue and fix it as soon as possible. We are available to answer your questions and concerns on demand."
                    title4="Does marketing automation really work?"
                    content4="Absolutely! According to Moz, companies that use marketing automation have 53% higher conversion rates and an annual revenue growth rate of 3.1% which is higher compared to companies that don’t. If you work with a professional marketing automation agency like Artographer, marketing automation can be useful in helping sales and marketing teams do their jobs more effectively and reach their goals."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MarketingAutomation;