

import React from 'react';

const SatisfactionImage = () => {
    return (
        <div className="logo-sample">
            <img 
                src="../images/satisfaction-guaranteed-image.webp" 
                alt="img" 
                style={{height: '100%', width: '100%', objectFit: 'contain'}}
            />
        </div>
    );
}

export default SatisfactionImage;
