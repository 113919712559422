import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { CaseStudiesPageContainer } from '../styles/AllPage.style';
import PageTitle from '../components/PageTitle';
import { Link } from 'react-router-dom';
import CaseStudiesData from '../components/CaseStudiesData';
import {Helmet} from 'react-helmet';



const CaseStudiesPage = () => {
    return (
        <>
            <Helmet>
                <title>
                    Best Web Design, Development, Digital Marketing Case Studies 2022
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/case-studies/" />
                <meta 
                    name="descriptions" 
                    content="Access our case studies to know about our expertise in process execution, problem handling and building custom solutions across our website design, development and marketing services. Click to know more"
                />
            </Helmet>
            <PageTitle 
                title= 'Case Studies'
                text1= 'We are dynamic about taking challenges. We are adroit at listening and implementing tailored actions by keenly understanding our clients. With a collaboration that is as flat as a pancake, we are ready to deliver a wide range of services with winning solutions. We ensure to deliver an outstanding digital experience for every project that we are responsible for.'
                text2= 'We serve every client with 360 degree solutions and at the same time we are experts in suggesting the best solutions/techniques by understanding our client’s objectives. But, don’t just take our words, browse through some of our projects’ case studies to know more about how and what we have done for our clients.'
            />
            <CaseStudiesPageContainer>
                <Row className='row'>
                    {
                        CaseStudiesData.map((elem) => {
                            const { name, image, link } = elem;

                            return (
                                <Col sm={12} md={6} lg={4}>
                                    <div className='cs-thumb-container'>
                                        <Link to={link}>
                                            <div className='cs-thumb-wrap'>
                                                <img src={image} alt={name} style={{maxWidth: '100%', height: 'auto'}} />
                                            </div>
                                        </Link>
                                    </div>
                                </Col>       
                            )
                        })
                    }
                </Row>
            </CaseStudiesPageContainer>
        </>
    );
}

export default CaseStudiesPage;
