import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function Facebook() {
    return(
        <Page>
            <Helmet>
                <title>
                    Facebook Marketing Services Company India - Facebook Advertising Agency India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/facebook-marketing-company/" />
                <meta 
                    name="descriptions" 
                    content="We, an India based facebook marketing company provides custom facebook advertising and management services to B2B partners and direct niche customers to improve their ROI. Click to learn more"
                />
            </Helmet>
            <PageTitle
                title= "Facebook Marketing Company"
                text1= "Facebook has proven to be a great marketing platform as it allows interacting with existing and prospective customers in a better way. Whether you want to generate sales, drive more mobile app installs, or promote your content, our professional Facebook marketing services will get the results you want."
                text2= "Our experienced team comprises of strategists, social media specialists, content writers, and designers, who can plan the best campaign to achieve your business goals."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-Service Facebook Marketing Company Will Fuel the Growth of Your Business"
                    content= "The foremost reason is the sheer number of people who use Facebook on a daily basis. It is also a company that understands the ever-changing dynamic of technology-human interaction."
                    listTitle= "They have kept abreast of changing trends by acquiring WhatsApp and Instagram – both platforms have proven successful in business marketing individually and in conjunction with Facebook. Some of the major advantages of using Facebook for business are,"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Learn customer buying trends</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Connect to your customers</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Gain valuable feedback</span></span>}
                    image= '../images/artographer-facebook-marketing.png'
                    alt= 'artographer facebook marketing'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>How do Our Facebook Marketing Company Work?</SectionTitle>}
                    para1= "We believe that only through proper measuring, tracking and marketing efforts can be maximized. So our approach to Facebook marketing is quite general: we track, test, tweak and repeat everything."
                    para2= "Our team at Artographer will assist you to set up, monitor, and maintain the entire Facebook ad campaign and help you to meet your expectation. First of all, we will help you build or alter your Facebook page which is the best way to communicate and connect with the target group of customers."
                    para3= "Then, we will promote the page by using exclusive and unique Facebook ad strategies for maximum audience engagement. This will bring users directly from Facebook to either your website or the online space you wish to promote. As a result, your business revenue will be increased and you can achieve maximum ROI."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Facebook Marketing Services"
                content= "Our Facebook marketing team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <DigitalMarketingTools
                    title= "Social Media Marketing Tools We Use"
                    image= {<img className="marketing-image" src="../images/digital-marketing-tools.svg" alt=""/>}
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer The Following Result-driven Facebook Advertising Services"
                    title1= "Facebook Campaign Management"
                    content1= "Traditional advertising lost its position as online advertising is blooming. Through our Facebook campaign management services, we will lead your business through the path of online success by following enhanced, attractive and engaged strategies."
                    title2= "Facebook Ad Audience Targeting"
                    content2= "Placing your ads in front of the right audience plays an important role in promoting the business. At Artographer, we make ads reach in a better way by focusing on attracting your ideal customers."
                    title3= "Facebook Ad Design"
                    content3= "To create a long lasting impression among people, you have to deliver the best design. We provide most effective ad formats to ensure that your Facebook ads are making the right impression among your ideal consumers."
                    title4= "Facebook Ad Copy Writing"
                    content4= "Copywriting helps the audience to better understand about your business and services. Our team at Artographer has immense experience in spotting out which ad drives more conversions and create impressive ad copies that attract the audience."
                    title5= "Traffic Diversion"
                    content5= "To get more leads, our Facebook marketers will formulate the appropriate strategy to redirect traffic to the appropriate landing page of your choice."
                    title6= "App/Event/Post Engagement"
                    content6= "We will craft and publish engaging new content, public events, applications, and company announcements to create awareness and engagement among customers."
                />
            </MainContainer>
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>White Label Facebook Marketing Agency – An Extension of Your Team</SectionTitle>}
                    para1= {<span>White label Facebook outsourcing is suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients.</span>}
                    para2= {<span>Our Facebook marketers will make sure all the pieces of Facebook marketing are successfully implemented.<br /><br />As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.</span>}
                    para3= "All of our B2B services are designed to off-load the Facebook marketing needs of any agency. We are happy to work under your branding to take care of your customers. Let’s Connect."
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized Facebook Marketing Services"
                    content= {<span>We have worked with Facebook marketing clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a Facebook marketing services.<br /> If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Facebook Marketing Services'
                    title1="I already do paid search ads on search engines? Why should I opt for Facebook advertising?"
                    content1="When compared to Google AdWords or Bing Ads, the cost per interaction on Facebook is quite low. If you’re looking for a cost-effective alternative to PPC, give Facebook advertising a try!"
                    title2="How much does advertising on Facebook cost?"
                    content2="You can run ads for even $1. But, it depends on the ROI you want out of the spending. If you want more ROI, you need to invest more to get the desired results."
                    title3="Will Facebooh marketing be helpful in growing my business?"
                    content3="Absolutely. Facebook marketing defines the buyer’s persona which is the major requirement of a business. It separates customers according to their age, type, group, gender, educational qualifications, and thus you can market your products accordingly."
                    title4="Why do I need Facebook advertising? Why cant'n I just post on my facebook page?"
                    content4="Initially, reaching people organically is hard. Paid advertising will help you reach the right audience even if they don’t like your page or know who you are, but are interested in the type of things you offer."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default Facebook;