import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function MagentoWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Custom Magento Ecommerce Website Design, Development Services Company India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/magento-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Artographer, is well known for best Magento E Commerce website development and design company in India, provides services all over the world. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Magento Website Development"
                text1= "At Artographer, we provide customized Magneto e-commerce solutions along with Magneto SEO services. Our developers know how to integrate custom features, designs, and functionalities which will take your website to a higher position."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Magento Website Design and Development Company"
                    content= "Artographer is a premier website design company that offers expert services in e-commerce design and development with Magento. We provide customized Magento website development, third party extensions that allow your business to leverage the flexibility and scalability of the internet to grow your business. Artographer provides best in class responsive website design service to businesses and entrepreneurs worldwide."
                    image= '../images/artographer-drupal-website.png'
                    alt= 'artographer magento website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    para1bold= {<sapn className="bold-text">Our Magento Website Development Expertise</sapn>}
                    para2= {<span>Our developers have extensive experience in Magento website development and are knowledgeable of the latest versions.<br /><br />We understand your business before designing the web solutions.</span>}
                    para3= {<span>Artographer’s specialty in business website design services enables us to provide cost effective solutions regardless of your business size.<br /><br />We design websites that take your business forward.</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Magento Website Development Services"
                content= "Our Magento website development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>    
            <Divider />
            <MainContainer>
                <WhiteLabel 
                    title= "Magento Website Design Services from Artographer"
                    content1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Magento store development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Magento theme design</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Magento mobile app development, design, & integration</p></div>
                        </>
                    }
                    content2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Magento extension development & integration</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Magento custom module development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Site migration, maintenance, & support</p></div>
                        </>
                    }
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Outsource to Artographer for Your Magento Solution?</SectionTitle>}
                    para1= {<span>
                        <p>Artographer offers comprehensive website design and development services:</p>
                        <div className="list-item"><FaCheck className="check-icon"/><p>Logo design</p></div>
                    </span>}
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Graphic design</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>E-commerce development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Web application development</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Responsive website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEO services</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Social networking solutions</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Magento Website Development Services"
                    content= {<span>We have worked with Magento website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a Magento website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "Search Engine Optimization Services"
                    subTitle4= "Creative Search Engine Optimization Services"
                    title5= "Content Marketing Services"
                    subTitle5= "Creative Content Marketing Services"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default MagentoWeb;