import { ExpertiseCertifications } from "../styles/Certification.style"


function certification() {
    return (
            <ExpertiseCertifications >
                <img src="../images/our-expertise-certifications-image.svg" alt="our certification"/>
            </ExpertiseCertifications>
        );
    }
      
export default certification;