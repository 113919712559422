import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const CureHeaven = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Cure Heaven</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/cure-heaven"
        />
        <meta
          name="descriptions"
          content="Our talented graphic design team created a stunning logo, branding, and packaging for Cure Heaven, exceeding client expectations and ensuring satisfaction."
        />
      </Helmet>
      <PortfolioPage
        title="Logo and Packaging Design for Cure Heaven"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/cure-heaven.jpg"
            alt="cure-heaven"
          />
        }
        bName="Prakam Aura Pvt. Ltd."
        industry="Personal Care"
        requirement="The client is a personal care products-based startup from Noida, India. The client wanted a logo for their business and more than ten packaging designs that would look attractive."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/cure-heaven-packaging-design.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            After analyzing the client’s requirements and interests, we came up
            with a butterfly-shaped logo.
            <br />
            <br />
            The logo was created using pink color to make it more attractive and
            passionate. Then, we submitted a few options initially to the
            client. After a few iterations, the client was happy with the final
            logo that we designed and came back for further revisions.
            <br />
            <br />
            After finalising the logo we created packaging designs for multiple
            products like sanitary pads, sweat pads, face masks, toilet sheet
            cover, hand sanitizer etc.
          </span>
        }
        bottomImage={
          <img src="../images/portfolio/cure-heaven-logo.jpg" alt="" />
        }
        preButton={
          <Link to="/portfolio/jm-travels">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/vinay-arts">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default CureHeaven;
