import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";





function ShopifyWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Shopify Web Design & Development Company India - Artographer
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/shopify-website-development/" />
                <meta 
                    name="descriptions" 
                    content="Our shopify web design company helps you to built brilliant shopping website services with better performance and traffic conversions. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Shopify Website Development"
                text1= "Our team at Artographer has dedicated proactive developers and they follow the best coding practices to deliver amazing Shopify e-commerce stores."
                text2= "We have worked together with a number of business professionals and enhanced their stability to run their online stores successfully. We grow our business by providing flexibility and feature-rich experience to our clients."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-service Shopify Website Development Company will Fuel the Growth of Your Business"
                    content= "Artographer provides sensational, customized Shopify websites for your e-commerce venture. Your satisfaction and our attention to detail is guaranteed. We provide you with great designs that keep the visitor engaged enough to make a purchase."
                    content2= "Our prices are competitive and the quality of our website design and website development services is at par with the best in the market."
                    image= '../images/artographer-shopify-website.png'
                    alt= 'artographer shopify website development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Choose Shopify for Your E-commerce Requirements?</SectionTitle>}
                    para1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Offers some of the best features for your e-commerce store</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Streamlined CMS or content management system</p></div>
                        </>
                    }
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Customizable views</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Automated chargeback</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Customizable templates</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Streamlined admin panel</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Interactive real-time stats</p></div>
                        </>
                    }
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Shopify Website Development"
                content= "Our Shopify website development team at Artographer can develop innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">Our Shopify Website Development Services Include</span>}
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>E-commerce solutions with Shopify</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Shopify website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Shopify customization</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Data migration to Shopify</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Support and maintenance</p></div>
                        </>
                    }
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Artographer’s Approach to Your Shopify Website Solutions</SectionTitle>}
                    para1= "When you outsource to us you will see that Artographer is a small firm that is big on professionalism. Our approach to your Shopify store is centered around:"
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Domain expertise</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Complete understanding of your business</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Coding standards & market research</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Customized solutions</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Affordable services</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Shopify Website Development Services"
                    content= {<span>We have worked with Shopify website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in Shopify website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Social Media Design Services"
                    subTitle2= "Creative Social Media Design Services"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default ShopifyWeb;