import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function WebApp() {
    return(
        <Page>
            <Helmet>
                <title>
                    Web Application Development Services Company India | Custom Web App Development Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/web-app-development/" />
                <meta 
                    name="descriptions" 
                    content="At Artographer, we provide robust web applications development services with our proficient web application development team. As a premium web development agency, we have conceived rich web app solutions across a global clientele for various industries. Click to know more."
                />
            </Helmet>
            <PageTitle
                title= "Web App Development"
                text1= "Our web app development company in India has a long-standing presence on the IT market and great experience in cracking the most complex web application development projects. We can also completely redesign web interfaces to align them with the evolving needs of end users and current technologies."
                text2= "We provide flexible teams, ensure a transparent development process, and react promptly to changing requirements, so you can always count on us for custom web application development services."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Progressive Web App Development Agency"
                    content= "At Artographer, we are at the forefront of building faster, reliable and engaging progressive web apps. As a leading provider of progressive web app development services, we deliver user-centric designs and reliable PWA solutions with structured quality assurance and monitoring process."
                    content2= "We have a dynamic team of progressive web app developers who have been creating amazing solutions since the inception of this innovative technology. By leveraging the technology of PWA, our team can create solutions that will make you stand out in the world of millions."
                    image= '../images/artographer-web-app-design.png'
                    alt= 'artographer web app design'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <ResultDriven 
                    header= "Our Web Application Development Services"
                    title1= "B2B & B2C Web Portal Development"
                    content1= "Our professional web app development agency can build an easy and intuitive platform where you can easily keep in touch with your customers."
                    title2= "Custom Marketplace"
                    content2= "As a leading web app development agency we can build a unique online marketplace that will be beneficial for all the members of the process."
                    title3= "Online Store/E-commerce Solutions"
                    content3= "Our web app development services can build amazing e-commerce solutions using technologies like, Magento, ASP. Net, PHP, Java, JavaScript etc."
                    title4= "Technology Consulting"
                    content4= "Our custom web app development company in India is focused to become your reliable IT partner so you can gain a favourable ROI."
                    title5= "Product Re-Engineering"
                    content5= "From design to deployment, our professional web app development services can address all your requirements to connect functionality and data with modern platforms."
                    title6= "Blockchain, ML & Chatbots"
                    content6= "Our professional web app development agency in India can understand your business requirements and build solutions with blockchain, ML and chatbots."
                />
            </MainContainer>
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Web Application Development Services"
                content= "Our web app development team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Our Web Application Development Benefits"
                    title1= "Cost Effective Development"
                    content1= "Through our custom web application development services, we can create and deploy applications quickly which will directly impact your competitive advantage."
                    title2= "Cross-Platform Capabilities"
                    content2= "Our web app development agency can create cross platform web applications which will function similarly across all devices to give the desired user experience."
                    title3= "Prototyping & UXD"
                    content3= "Our web app development company in India can run the design process in a thoughtful way so we can shape the final designs according to your expectations."
                    title4= "Simple, Customisable and Scalable"
                    content4= "As our professional web app development agency custom designs the web application, the app will be totally adaptable and versatile to your business’ requests."
                    title5= "Highly Secure"
                    content5= "Our web app development agency offers impressive and secure web experience while also ensuring flexibility and faster delivery."
                    title6= "Mature Processes"
                    content6= "Our web app development company in India follows complex architecture planning, high quality coding, and thorough testing to deliver high quality web apps"
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Web App Development Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label web app development outsourcing is suitable for agencies that are looking for extra development resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. Our web app developers will make sure all the pieces of web app design, development and maintenance are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.<br /><br />All of our B2B services are designed to off-load the web app development needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Web App Development Services"
                    content= {<span>We have worked with web app development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a web app development services.<br /><br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Web Application Development Services'
                    title1="Will I get copyright of the source code?"
                    content1="As a leading web development company in India we maintain complete transparency. You will be the owner of the source code that our developers wrote for you."
                    title2="Can I see the web app during the develop phase?"
                    content2="Yes, you can. Our web development company in India will provide the login credentials so you can login and monitor the progress."
                    title3="Will there be any undisclosed charges?"
                    content3="No, we will discuss everything before we start the project. Once you are satisfied with the price, our dedicated web application developers will start the project."
                    title4="Are you the best web app development company in india?"
                    content4="We have worked around 30+ web app development projects and worked with satisfied clients from over 10+ countries like USA, UK, Canada, Singapore and more. If you choose to work with us, you will find out how good we are."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Logo Design Services"
                    subTitle= "Brand logo design | Creative logo design | Best logo designers | Logo Redesign"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "WooCommerce Website Development"
                    subTitle3= "Creative WooCommerce Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default WebApp;