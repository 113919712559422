import { MainContainer, SectionTitle } from "../globalStyles";
import { TestimonialWrap } from "../styles/Testimonial.style"
import PrimaryButton from "./PrimaryButton";
import { Link } from "react-router-dom";





function Testimonials() {
    return (
        <MainContainer>
            <TestimonialWrap>
                <SectionTitle className="title">What Our Clients Say</SectionTitle>
                <h3>"Known for their professionalism"</h3>
                <div className="section-text">
                    <p>We were looking for a professional web development team to partner with. Their instant reply to my enquiry and level of thought made them stand out on our shortlist. We were inspired by their clear, concise, and timely communication with us.</p>
                </div>
                <h5>Client Partner - Project Manager, Water and Energy Technologies USA</h5>
                <div className="button-wrap">
                    <Link to= '/testimonials'>
                        <PrimaryButton
                            maintext= 'View All Testimonials'
                        />
                    </Link>
                </div>
            </TestimonialWrap>
        </MainContainer>
    );
}
      
export default Testimonials;