import styled from "styled-components";



export const FormContainer = styled.div`
    width: 100%;
    text-align:left;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--background);

    .lt-inner-container {
        z-index: 1;
        width: 100%;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 50px;
        padding-left: 50px;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.6rem;
        color: var(--paragraph);

        @media screen and (max-width: 991px) {
            font-size: 1rem;
            line-height: 1.5rem;
            padding-right: 30px;
            padding-left: 30px;
        }

        @media screen and (max-width: 480px) {
            font-size: .9rem;
            line-height: 1.4rem;
        }

        .lt-section-Title {
            font-size: 2.3rem;
            font-weight: 600;
            line-height: 2.8rem;
            letter-spacing: -1px;
            margin-bottom: 1.5rem;
            display: block;
            background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 991px){
                font-size: 2.2rem;
                line-height: 2.7rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
                line-height: 1.8rem;
            }
        }

        .form-section {

            @media screen and (max-width: 767px){
                margin-top: 1.5rem;
            }
        }

        .lt-field-top-wrap {

            .lt-field-block {
                margin-bottom: 1rem;


                input {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.3rem;
                    padding-left: .5rem;
                    width: 100%;
                    height: 2.5rem;
                    border: 1px solid #D0D0D0;
                    border-radius: 10px;
                    color: var(--dark-grey);

                    @media screen and (max-width: 480px) {
                        font-size: .9rem;
                        line-height: 1.3rem;
                    }

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.3rem;
                        color: var(--light-gray);

                        @media screen and (max-width: 480px) {
                            font-size: .8rem;
                            line-height: 1.3rem;
                        }
                    }
                }
            }

            .lt-field-block-flag {
                margin-bottom: 1rem;

                .tel-container {
                    display: flex;
                    align-items: center;
                    height: 2.5rem;
                    border: 1px solid #D0D0D0;
                    border-radius: 10px;
                    background-color: white;
                    

                    .tel-box {
                        display: flex;
                        margin-left: 1rem;

                        .select-box {
                            width: 1.5rem;
                            height: 1rem;
                            position: relative;
                        

                            .flag-img {
                                width: 1.5rem;
                                height: 1rem;
                                position: absolute;
                            }

                            select {
                                font-size: .9rem;
                                font-weight: 400;
                                width: 1.5rem;
                                height: 1rem;
                                background: none;
                                position: absolute;
                                padding-top: 1rem;
                                border: none;
                                outline: none;
                                appearance: none;
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                cursor: pointer;
                            }

                        }

                    }
                }

                .country-code {
                    margin-left: .8rem;

                    .result {
                        font-size: 1rem;
                        font-weight: 500;
                        line-height: 1.2rem;
                        color: var(--dark-grey);

                        @media screen and (max-width: 480px) {
                            font-size: .9rem;
                            line-height: 1.3rem;
                        }
                    }
                }

                input {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.2rem;
                    padding-left: .5rem;
                    width: 100%;
                    height: 2rem;
                    border: none;
                    color: var(--dark-grey);
                    background: none;

                    @media screen and (max-width: 480px) {
                        font-size: .9rem;
                        line-height: 1.3rem;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .lt-field-middle-wrap {
            margin-bottom: 1rem;


            textarea {
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.2rem;
                padding-left: .5rem;
                padding-right: .5rem;
                padding-top: .5rem;
                width: 100%;
                height: 6rem;
                border: 1px solid #D0D0D0;
                border-radius: 10px;
                color: var(--dark-grey);

                @media screen and (max-width: 480px) {
                    font-size: .9rem;
                    line-height: 1.3rem;
                }

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.2rem;
                    color: var(--light-gray);

                    @media screen and (max-width: 480px) {
                        font-size: .9rem;
                        line-height: 1.3rem;
                    }
                }
            }
        }

        .lt-field-bottom-wrap {
            

            .lt-field-button-wrap {
                display: flex;
                align-items: center;

                button {
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: white;
                    background-color: var(--primary);
                    border: none;
                    padding: .7rem 4rem;

                    @media screen and (max-width: 767px){
                        font-size: .9rem;
                        padding: .7rem 3rem;
                    }

                    @media screen and (max-width: 480px){
                        font-size: .8rem;
                        padding: .6rem 2rem;
                    }
                }

                .process-spinning-icon {
                    display: inline-block;
                    background-color: #23282d;
                    opacity: .75;
                    width: 24px;
                    height: 24px;
                    border: none;
                    border-radius: 100%;
                    padding: 0;
                    margin: 0 24px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        background-color: #fbfbfc;
                        top: 4px;
                        left: 4px;
                        width: 6px;
                        height: 6px;
                        border: none;
                        border-radius: 100%;
                        transform-origin: 8px 8px;
                        animation: spin 1s infinite linear;
                    }

                    @keyframes spin {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }

`;