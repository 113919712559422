import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MainContainer } from "../globalStyles"
import { TabWrap, TabTitles, TabContent, TabLinks } from "../styles/ServiceTabs.style"
import { FaChevronCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";



function ServiceTabs() {

    const [ toggleState, setToggleState ] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }
    return(
        <MainContainer>
            <TabWrap>
                <div className="col-sm-1 col-md-2">
                    <TabTitles>
                        <div className="tab-title-Container">
                            <li className= {toggleState === 1 ? "tab-name active-name" : "tab-name"} 
                            onClick={() => toggleTab(1)}>Web Development</li>
                            <li className= {toggleState === 2 ? "tab-name active-name" : "tab-name"} 
                            onClick={() => toggleTab(2)}>Graphic Design</li>
                            <li className= {toggleState === 3 ? "tab-name active-name" : "tab-name"} 
                            onClick={() => toggleTab(3)}>Web Apps</li>
                            <li className= {toggleState === 4 ? "tab-name active-name" : "tab-name"} 
                            onClick={() => toggleTab(4)}>Digital Marketing</li>
                        </div>
                    </TabTitles>
                </div>
                <div className="col-sm-11 col-md-10 col-lg-6">
                    <TabContent>
                        <div className= {toggleState === 1 ? "tab-content active-content" : "tab-content"}>
                            <h2 className="tab-heading">Global Website Development Services</h2>
                            <p>Artographer is a web development company from India that 
                                has served a variety of businesses from the USA with 
                                industry-leading web design and development solutions.
                            </p>
                            <p>We have an excellent team of web application developers who 
                                are capable of creating innovative and high-performance
                                website development of any complexity.
                            </p>
                            <p>With our unparalleled web application development services, 
                                we promise to deliver you a website of superior performance 
                                that suits your organization's specific needs.
                            </p>
                            <p>Whether you're a large, medium or small corporation, our 
                                web development services company is here to help you.
                            </p>
                            <div className="tab-buttons">
                                <div className="tab-button left">
                                    <Link className="tab-link"
                                        to= '/services/wordpress-development-services'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;WordPress Development</span>
                                    </Link>
                                </div>
                                <div className="tab-button right">
                                    <Link className="tab-link"
                                        to='/services/ecommerce-website-development'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;eCommerce Development</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 2 ? "tab-content active-content" : "tab-content"}>
                            <h2 className="tab-heading">Creative Graphic Design Services</h2>
                            <p>Artographer is a web development company from India that 
                                has served a variety of businesses from the USA with 
                                industry-leading web design and development solutions.
                            </p>
                            <p>We have an excellent team of web application developers who 
                                are capable of creating innovative and high-performance
                                website development of any complexity.
                            </p>
                            <p>With our unparalleled web application development services, 
                                we promise to deliver you a website of superior performance 
                                that suits your organization's specific needs.
                            </p>
                            <p>Whether you're a large, medium or small corporation, our 
                                web development services company is here to help you.
                            </p>
                            <div className="tab-buttons">
                                <div className="tab-button left">
                                    <Link className="tab-link"
                                        to= '/services/logo-design-company'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;Logo Design</span>
                                    </Link>
                                </div>
                                <div className="tab-button right">
                                    <Link className="tab-link"
                                        to='/hire-a-graphic-designer'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;Hire a Graphic Designer</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 3 ? "tab-content active-content" : "tab-content"}>
                            <h2 className="tab-heading">Advanced Web Application Development Services</h2>
                            <p>Creating customized web applications to meet unique business 
                                requirements is an important asset to any global 
                                organization that wants to promote growth.
                            </p>
                            <p>Artographer offers modern web application development 
                                services to help you sail easily through the 
                                constantly-evolving technological changes.
                            </p>
                            <p>We have many years of experience in the field of developing 
                                web applications using cutting edge technologies like React 
                                JS, Angular and Laravel.
                            </p>
                            <p>Combining our developer's skills & experience with the latest 
                                technology we can deliver you with robust web applications 
                                according to your custom business requirements.
                            </p>
                            <div className="tab-buttons">
                                <div className="tab-button left">
                                    <Link className="tab-link"
                                        to='/services/web-app-development'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;Web App Development</span>
                                    </Link>
                                </div>
                                <div className="tab-button right">
                                    <Link className="tab-link"
                                        to='/services/reactjs-development'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;React Development</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className= {toggleState === 4 ? "tab-content active-content" : "tab-content"}>
                            <h2 className="tab-heading">Strategic Digital Marketing Services</h2>
                            <p>If you're looking for a digital marketing company to increase 
                                your visibility, promote your brand effectively, and improve 
                                your lead conversion, you have come to the right place.
                            </p>
                            <p>We help companies across the globe to showcase their 
                                products/services to specifically targeted user groups with 
                                our digital marketing strategies.
                            </p>
                            <p>Through our digital marketing services, we will bring together 
                                technology and the art of communication to enrich your digital 
                                presence.
                            </p>
                            <p>All our digital marketing services are designed with 
                                ROI-focused solutions which will have a direct positive 
                                impact on your brand.
                            </p>
                            <div className="tab-buttons">
                                <div className="tab-button left">
                                    <Link className="tab-link"
                                        to='/services/digital-marketing-services'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;Digital Marketing</span>
                                    </Link>
                                </div>
                                <div className="tab-button right">
                                    <Link className="tab-link"
                                        to='/services/seo-services'
                                    >
                                        <span><FaChevronCircleRight /> &nbsp;SEO</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </TabContent>
                </div>
                <div className="col-md-0 col-lg-4">
                    <TabLinks>
                        <ul>
                            <li>
                                <Link 
                                    to= '/services?active=graphic-design'
                                    className= {toggleState === 1 ? "tab-shortcut active-shortcut" : "tab-shortcut"}>
                                        Web Development
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to= '/services?active=web-development'
                                    className= {toggleState === 2 ? "tab-shortcut active-shortcut" : "tab-shortcut"}>
                                        Graphic Design
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to= '/services?active=web-app-development'
                                    className= {toggleState === 3 ? "tab-shortcut active-shortcut" : "tab-shortcut"}>
                                        Web App
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to= '/services?active=digital-marketing'
                                    className= {toggleState === 4 ? "tab-shortcut active-shortcut" : "tab-shortcut"}>
                                        Digital Marketing
                                </Link>   
                            </li>
                        </ul>
                    </TabLinks>
                </div>
            </TabWrap>
        </MainContainer>
    );
}

export default ServiceTabs;