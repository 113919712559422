import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const WetLab = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | WET Lab</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/wet-lab"
        />
        <meta
          name="descriptions"
          content="Our skilled graphic designers created a logo for WET Lab, and the client is thrilled with the result. See how we bring your brand vision to life!"
        />
      </Helmet>
      <PortfolioPage
        title="Logo Design for Water & Energy Technologies"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/wet-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Oregon State University, USA"
        industry="Science & Technology"
        requirement="Water & Energy Technologies is a research laboratory in Oregon State University based in Oregon State, USA. The client wanted a simple logo which must contain three hills and river."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/wet-lab-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            This was a very difficult assignment for us where we worked hard,
            submitted various iterations and did many revisions. The client have
            a serious sentiments with Three Sisters (a mountain range) based in
            Oregon State, USA. So he decided and asked us that the logo should
            be simple and look like <b>Three Sisters</b> with flowing river.
          </span>
        }
        bottomImage={<img src="../images/portfolio/wet-lab-logo.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/all-song-lyrics">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/boat-media">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default WetLab;
