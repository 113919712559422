import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col } from 'react-bootstrap';
import { Thankyoucontainer } from '../styles/ThankYou.style';
import { MainContainer } from '../globalStyles';
import PageTitle from '../components/PageTitle';
import PrimaryButton from '../components/PrimaryButton';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';




const ThankYou = () => {
    return (
        <>
            <Helmet>
                <title>
                    Artographer - Thank You Services
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/thank-you/" />
                <meta 
                    name="descriptions" 
                    content="Hey there, Thank you for contacting Artographer. This email is checked regularly during business hours."
                />
            </Helmet>
            <PageTitle
                title= "Thank You For Contacting Us"
            />
            <MainContainer>
                <Thankyoucontainer>
                    <Row className='justify-content-center'>
                        <Col md={3}>
                            <div>
                                <img 
                                    src='../images/thankyou-page-image.svg'
                                    alt='artographer'
                                    style={{width: '100%', height: '100%', objectFit: 'contain'}}
                                />
                            </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={5}>
                            <div className='right-container'>
                                <p>Hey there, Thank you for contacting Artographer.</p>
                                <p>This email is checked regularly during business hours.</p>
                                <p>We'll get back to you as soon as possible, usually within a few hours.</p>
                                <p>While we work on your requirement, you can browse more about our resources that can help you in your digital quest.</p>
                                <div className='btn-container'>
                                    <Link to='/'>
                                        <PrimaryButton maintext= 'Head Back Home' />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Thankyoucontainer>
            </MainContainer>
        </>
    );
}

export default ThankYou;
