import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Braccio = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Braccio</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/braccio"
        />
        <meta
          name="descriptions"
          content="Our skilled graphic designers created stunning Logo, Branding, and Packaging designs for Braccio, leaving the client highly satisfied with the results."
        />
      </Helmet>

      <PortfolioPage
        title="Logo, Branding and Packaging design for Braccio"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/braccio-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Braccio"
        industry="Jeweller"
        requirement="The client is from Illinois, USA. They are an e-commerce website based luxury jewellery seller. The client requested a simple text logo that looks appealing and soothing."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/braccio-screen.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            After a few brainstorming sessions, we designed a simple logo with
            pastel colors. To give a minimalistic look, we created the logo’s
            text with flowy lines. We chose navy blue color for the text and
            arctic blue for the background.
            <br />
            <br />
            As the client sells customized jewellery, we decided to go for an
            innovative design for jewellery boxes and inlets.
          </span>
        }
        bottomImage={<img src="../images/portfolio/braccio-logo.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/moexa">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/pick-ur-pet">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Braccio;
