import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function Ppc() {
    return(
        <Page>
            <Helmet>
                <title>
                    PPC Management Services Company India – PPC Advertising Agency India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/pay-per-click-services/" />
                <meta 
                    name="descriptions" 
                    content="We, an India based PPC marketing company provides PPC advertising and management services to B2B partners and direct niche customers to improve their ROI. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "PPC Services"
                text1= "At Artographer, we offer Pay Per Click (PPC) services for both local and global clients. We work together to ramp up a business’s online presence in line with their business objectives."
                text2= "Just give us a buzz and we will be more than happy to show our pay per click sample works. We also work out a strategy to pull up your websites and help you to earn better ROI."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our PPC Marketing Company Will Fuel the Growth of Your Business"
                    content= "Pay Per Click advertising or PPC advertising, management is the most efficient form of advertising available for businesses online. Artographer will help you set your very own PPC advertising campaign. Using this type of advertising, you can almost guarantee ROI for your business and the more you put in, the more you will get out."
                    image= '../images/artographer-ppc-service.png'
                    alt= 'artographer ppc service'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our PPC Marketing Services"
                content= "Our PPC Marketing team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>How do Our PPC Marketing Company Work?</SectionTitle>}
                    para1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Helps you advertise your business on popular online platforms</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Tool to promote your business</p></div>
                        </>
                    }
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>You are the advertiser</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>You pay for the ad only when someone clicks on it</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Combine with a great website to ensure ROI</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Ideal for anyone from construction companies to online retailers to small manufacturers</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <DigitalMarketingTools
                    title= "Our Expert's PPC Advertising Certifications"
                    image= {<img className="marketing-image" src="../images/seo-expert-certification.svg" alt=""/>}
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>White Label Digital Marketing Agency – An Extension of Your Team</SectionTitle>}
                    para1= {<span>White label PPC marketing services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients.</span>}
                    para2= {<span>Our professional PPC professionals will make sure all the pieces of PPC campaign are successfully implemented.<br /><br />As a general rule, we will never contact your customers directly.</span>}
                    para3= "But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the PPC marketing needs of any agency. We are happy to work under your branding to take care of your customers."
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized PPC Marketing Services"
                    content= "We have worked with PPC marketing clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a PPC marketing services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default Ppc;