import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function PhpWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    PHP Development Sevices Company India | Custom PHP Web Application Development
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/php-website-design/" />
                <meta 
                    name="descriptions" 
                    content="We, Artographer are one of the best PHP website development companies expertized in Core PHP and PHP web application development. Click to know more."
                />
            </Helmet>
            <PageTitle
                title= "PHP Website Development"
                text1= "Artographer helps you to create a complete functional and high-quality PHP website development. Our PHP developers promote interactivity and innovation of your website which will increase the trust among people."
                text2= "The web design and development services we provide at Artographer are highly secure and you can maintain the performance of your website regularly."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Custom PHP Web Development Services"
                    content= "PHP is the programming language that is used to create many of the interactive and social features you find across websites online and is very useful for responsive PHP web development."
                    content2= "All businesses with an online presence can outsource their custom PHP web development to Artographer. Some of the professionals who can benefit from our white label services are,"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Tech-based companies</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Small businesses</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Start ups</span></span>}
                    listItem4= {<span><FaCheck className="blds-list-icon"/><span>Online professionals</span></span>}
                    image= '../images/artographer-php-website.png'
                    alt= 'artographer php website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">Artographer’s Custom PHP Development Services</span>}
                    para2= {<span>PHP is also a powerful tool when it comes to building content management systems and back-ends as well as for effective responsive website development.<br /><br />That means we can help you to create tools that make managing and updating your site much easier – so you won’t need to call us every time you want to change something on your page.</span>}
                    para3= {<span>If you want all the power of a WordPress site without any of the restrictions, then custom PHP web development is what you need.<br /><br />To find out more, take a look around our site or get in touch for a no-obligation consultation!</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our PHP Web Development Services"
                content= "Our PHP web development team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Choose Us For Your PHP Web Development Requirements?</SectionTitle>}
                    para1= {<span>PHP is a powerful programming language that can be used online to create adaptive and feature-rich websites.<br /><br />Artographer is a premier website design company with years of experience in PHP web development.</span>}
                    para2= "The websites we design includes Rich web 2.0 features PHP web development with Functional control panel, Content management platform, Easy website management, Interactive and social features and more. Our capabilities include:"
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>E-commerce development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Web application development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Responsive website development</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEO services</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized PHP Website Development Services"
                    content= {<span>We have worked with PHP website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a PHP website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default PhpWeb;