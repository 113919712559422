import styled from "styled-components";

export const TestimonialWrap = styled.div`
text-align: center;

    .title {
        text-align: center;
    }

    h3 {
        margin-top: -1.3rem;
        margin-bottom: 1.3rem;
        font-size: 1.8rem;
        font-style: italic;
        letter-spacing: -1px;
        background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 480px){
            font-size: 1rem;
            margin: 0 0 1rem 0;
        }
    }

    .section-text {
        width: 700px;
        margin: auto;

        @media screen and (max-width: 767px){
            width: auto;
        }
    }

    h5 {
        margin-top: 1.2rem;
        font-size: 1.1rem;
        color: #3B3B3B;
        font-style: italic;
        letter-spacing: -1px;
        color: var(--light-gray);

        @media screen and (max-width: 480px){
            font-size: .9rem;
        }
    }

    .button-wrap {
        margin-top: 2.5rem;

        @media screen and (max-width: 480px){
            margin-top: 1rem;
        }
    }
`;

