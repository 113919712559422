import styled from "styled-components";


export const SectionContainer = styled.div`
    text-align: left;
    margin-top: -2rem;

    @media screen and (max-width: 480px){
        margin-top: 0;
    }

    .blds-left-container {
        margin-top: 2rem;

        @media screen and (max-width: 767px){
            margin-bottom: 4rem;
        }

        @media screen and (max-width: 480px){
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        .title {
            text-align: left;

            @media screen and (max-width: 1199px) {
                margin-bottom: 1.5rem;
            }

            @media screen and (max-width: 991px) {
                margin-bottom: 1.3rem;
            }
            
            @media screen and (max-width: 767px) {
                margin-bottom: 1rem;
            }
        }

        p {
            margin-top: 1.5rem;

            @media screen and (max-width: 1199px){
                margin-top: .8rem;
            }

            @media screen and (max-width: 991px) {
                margin-top: .6rem;
            }

            @media screen and (max-width: 767px) {
                margin-top: .4rem;
            }

            @media screen and (max-width: 480px) {
                margin-top: .2rem;
            }
        }

        ul {
            padding: 0;
            margin-bottom: 2rem;
        }

        li {
            list-style: none;
            margin-bottom: -.3rem;

            @media screen and (max-width: 1199px){
                margin-bottom: -.4rem;
            }

            @media screen and (max-width: 767px){
                margin-bottom: -.6rem;
            }

            @media screen and (max-width: 480px){
                margin-bottom: -.8rem;
            }

            p{
                margin-top: 0;
            }

            .blds-list-icon {
                color: var(--primary);
                margin-right: .8rem;

                @media screen and (max-width: 767px){
                    font-size: .8rem;
                    margin-right: .6rem;
                }
                
                @media screen and (max-width: 480px){
                    font-size: .7rem;
                    margin-right: .4rem;
                }
            }
        }
    }

    .blds-right-container {
        padding: 0 3rem ;

        @media screen and (max-width: 1199px){
            padding: 0 3rem;
        }

        @media screen and (max-width: 767px){
            padding: 0 15px;
        }

        @media screen and (max-width: 480px){
            padding: 0 15px;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            opacity: .9;
        }
    }



`;