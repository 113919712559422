import React from 'react';
import { PageImageStyle } from '../globalStyles';

const PageImage = (props) => {
    return (
        <PageImageStyle>
            <img src={props.src} alt={props.alt}/>
        </PageImageStyle>
    );
}

export default PageImage;
