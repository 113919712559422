import styled from "styled-components";



export const HeroContainer = styled.div`
        background-color: #F8F8F8;
        width: 100%; 
        padding-left: 50px;
        padding-right: 50px;
        text-align: center;
        position: relative;
        padding-top: 10rem;
        padding-bottom: 3rem;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--paragraph);

        @media screen and (max-width: 991px) {
            font-size: .9rem;
            line-height: 1.4rem;
            padding-right: 30px;
            padding-left: 30px;
        }

        @media screen and (max-width: 480px) {
            font-size: .8rem;
            line-height: 1.3rem;
            padding-right: 15px;
            padding-left: 15px;
            padding-bottom: 1.5rem;
        }

    .hero-left-container {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            object-fit: contain;
            height: 90%;
            width: 90%;

            @media screen and (max-width: 767px){
                margin-top: 2rem;
            }

            @media screen and (max-width: 480px){
                width: 100%;
                height: 100%;
            }
        }

        .desktop-hero-image {

            @media screen and (max-width: 767px){
                display: none;
                max-height: 100px;
            }
        }

        .mobile-hero-image {
            display: none;

            @media screen and (max-width: 767px){
                display: block;
                margin-top: 1rem;
            }

            @media screen and (max-width: 480px){
                margin-top: 0;
            }

        }
    }

    .hero-right-container {
        text-align: left;
        margin-top: 2rem;

        h1 {
            font-size: 3rem;
            font-weight: 800;
            background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 3.5rem;
            letter-spacing: -1px;

            @media screen and (max-width: 991px) {
                font-size: 2.3rem;
                line-height: 2.8rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.8rem;
                line-height: 2.2rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1.5rem;
                line-height: 1.9rem;
            }
        }

        h2 {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1.2rem;
            letter-spacing: -1px;

            @media screen and (max-width: 991px) {
                font-size: 1.3rem;
                line-height: 1.6rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.2rem;
                line-height: 2.2rem;
                margin-top: .5rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1.1rem;
                line-height: 1.5rem;
                margin-top: .7rem;
            }
        }

        h3 {
            font-size: 1.3rem;
            font-weight: 500;
            color: #3B3B3B;
            margin-top: 1rem;
            margin-bottom: 1rem;
            letter-spacing: -1px;

            @media screen and (max-width: 991px) {
                font-size: 1.1rem;
                line-height: 1.5rem;
            }

            @media screen and (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5rem;
                margin-top: 0.3rem;
                margin-bottom: .7rem;
            }

            @media screen and (max-width: 480px) {
                font-size: 1rem;
                line-height: 1.5rem;
                margin-top: .7rem;
            }

            span {
                margin-left: .5rem;
                margin-right: .5rem;
                color: var(--secondary);
            }
        }

        h5 {
            font-size: .9rem;
            font-weight: 500;
            color: #3B3B3B;
            margin-top: .3rem;
            margin-bottom: 2rem;

            @media screen and (max-width: 767px){
                margin-bottom: 1.5rem;
            }

            @media screen and (max-width: 480px){
                font-size: .8rem;
            }
        }

        .hero-desc-text {

            @media screen and (max-width: 991px){
                display: none;
            }

            @media screen and (max-width: 767px){
                display: block;
            }
        }

        .hero-buttons {
            display: flex;
            margin-top: 2rem;

            @media screen and (max-width: 991px){
                margin-top: 1.2rem;
            }


            @media screen and (max-width: 480px){
                flex-direction: column;
                align-items: flex-start;
                margin-top: 1.2rem;
            }

            .button-wrap {
                margin-left: .8rem;
                margin-right: .8rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (max-width: 767px){
                    
                    &:first-child {
                        margin-left: 0;
                    }
                    
                }
            
                @media screen and (max-width: 480px){
                    align-items: flex-start;
                    margin-top: -1rem;
                    margin-left: 0;
                    margin-right: 0;
                }

                &:first-child {
                    margin-left: 0;

                    @media screen and (max-width: 480px){
                        margin-top: 0;
                    }
                }
            }
        }
    }
`;