import styled from "styled-components";


export const HireWrap = styled.div`

    .hire-section-container {
        padding-right: 120px;
        padding-left: 120px;
    }

`;