import styled from "styled-components";


export const SectionContainer = styled.div`
    padding: 0;

    .oldp-content-container {
        text-align: left;

        .bold-text {
            font-size: 2.3rem;
            font-weight: 600;
            line-height: 3rem;
            letter-spacing: -1px;
            color: var(--dark-grey);
            margin-top: -1.2rem;
            display: block;
        }

        .list-item {
            display: flex;

            .check-icon {
                color: var(--primary);
                margin-right: .8rem;
                margin-top: .4rem;
            }
        }
    }
    
`;