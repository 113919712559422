import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const BeePac = () => {
  return (
    <>
      <Helmet>
        <title>
          Portfolio Archive - Artographer | BeePack Packaging Solutions
        </title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/bee-pac"
        />
        <meta
          name="descriptions"
          content="Our skilled graphic designers created a logo and packaging designs for BeePack Packaging Solutions, leaving the client fully satisfied with the results."
        />
      </Helmet>
      <PortfolioPage
        title="Logo design for Beepac"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/beepac-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="BeePac"
        industry="Packaging"
        requirement="The client approached us for a logo representing their business. They were very keen that the logo must look bright and unique."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/beepac-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            As the client’s business is to manufacture and sell packaging
            products to multiple industries to contain, store or send their
            products using BeePack's packaging. The client wanted his logo to be
            looked like a bee.
            <br />
            <br />
            Considering their demand we started working on it and sent some
            iterations to him. Finally the client satified with the logo after a
            few revisions.
          </span>
        }
        bottomImage={<img src="../images/portfolio/beepac-logo.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/twinkle-architecture-designs">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link
            to="/portfolio/twinkle-architecture-designs"
            style={{ visibility: "hidden" }}
          >
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default BeePac;
