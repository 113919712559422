import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PortfolioContainer } from "../styles/Portfolio.style";
import { MainContainer } from "../globalStyles";
import PortfolioData from "../components/PortfolioData";
import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";
import PrimaryButton from "../components/PrimaryButton";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  const ReversePortfolioData = PortfolioData.slice(0).reverse();

  const [items, setItems] = useState(ReversePortfolioData);
  const [toggleClass, setToggleClass] = useState(0);

  const filterItem = (categItem, index) => {
    const updatedItems = ReversePortfolioData.filter((currElem) => {
      return currElem.category === categItem;
    });

    setToggleClass(index);
    setItems(updatedItems);
  };

  const defaultClass = () => {
    setItems(ReversePortfolioData);
    setToggleClass(0);
  };

  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/"
        />
        <meta
          name="descriptions"
          content="graphic design, and web development projects. See our innovative solutions in action!"
        />
      </Helmet>
      <PageTitle
        title="Portfolio"
        text1="We are specialized in delivering tailor-made and customized websites. Our websites are as unique as our clients. Some approach us for a basic website with maintenance. Others come to us for a complete development/revamp of their existing website. Some for logo designs. Some want their websites to be written by our writers. Others reach us for SEO and other digital marketing techniques."
        text2="Our diversified experience gives us the confidence to work the clients from different industries. We always keep our doors open for opportunities to learn and explore. Browse through some of our projects to know what we have done for our clients."
        button={
          <PrimaryButton smalltext="Its Free" maintext="Get Custom Quote" />
        }
      />
      <MainContainer>
        <PortfolioContainer>
          <Row className="gx-2">
            <Col sm={12} md={2}>
              <div className="menu-tab container">
                <div className="menu-tabs">
                  <span
                    className={
                      toggleClass === 1
                        ? "btn-menu-tab active-tab"
                        : "btn-menu-tab"
                    }
                    onClick={() => filterItem("web", 1)}
                  >
                    Web Development
                  </span>
                  <span
                    className={
                      toggleClass === 2
                        ? "btn-menu-tab active-tab"
                        : "btn-menu-tab"
                    }
                    onClick={() => filterItem("graphic", 2)}
                  >
                    Graphic Design
                  </span>
                  <span
                    className={
                      toggleClass === 0
                        ? "btn-menu-tab active-tab"
                        : "btn-menu-tab"
                    }
                    onClick={defaultClass}
                  >
                    All
                  </span>
                </div>
              </div>
            </Col>
            <Col sm={12} md={10}>
              <div className="menu-items">
                <Container>
                  <Row>
                    {items.map((elem) => {
                      const { id, name, image, description, link } = elem;

                      return (
                        <Col key={id} sm={6} md={4} lg={3} xl={3}>
                          <div className="pf-thumb-container">
                            <Link to={link}>
                              <div className="pf-thumb-wrap">
                                <img src={image} alt={name} />
                              </div>
                              <div className="pf-content-wrap">
                                <span className="pf-thumb-desc">
                                  {description}
                                </span>
                                <IoIosArrowDropright className="pf-icon-right" />
                              </div>
                            </Link>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </PortfolioContainer>
      </MainContainer>
    </>
  );
};

export default Portfolio;
