import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function SocialMediaMarketing() {
    return(
        <Page>
            <Helmet>
                <title>
                    Social Media Marketing Services Company India | Social Media Marketing Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/social-media-marketing/" />
                <meta 
                    name="descriptions" 
                    content="Our Social media marketing & management company in India helps you online visibility to expel through our excellent social media marketing and management services. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Social Media Marketing"
                text1= "We, Artographer, a professional social media marketing company offers you the ultimate solution to all of your social media marketing problems."
                text2= "Our social media marketers will help to expand your reach, maximize your web presence, increase traffic, shape your brand image and help you make connections with influencers."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-Service Social Media Marketing Company Will Fuel The Growth Of Your Business"
                    listTitle= "Today, more than ever, social media has become a conduit between your customers and your business. The more you engage with the customer, You’ll be able to Reap the following benefits"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Get amazing online exposure</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Create a strong and consistent visual identity</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Spread brand awareness</span></span>}
                    content3= {<span style={{display: "block", marginBottom: "2rem"}}>Artographer provides social media graphic design services that are aimed at enhancing your business presence on social media like Facebook, Instagram, Pinterest, Twitter, LinkedIn and more. We deliver customized designs to fit your business so that your company pages can really evoke a feeling of quality and stay ahead of your competition.</span>}
                    image= '../images/artographer-social-media-marketing.png'
                    alt= 'artographer social media marketing'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries That can Benefit From Our Social Media Marketing Services"
                content= "Our social media marketing team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>How Do Our Social Media Marketing Company Work?</SectionTitle>}
                    para1= "These days a strong presence on social media is more important than ever. If your social media branding design isn’t active on Facebook, Twitter, LinkedIn, Instagram, Pinterest or any of the others, then you’re probably missing out on countless business opportunities even as you’re reading this."
                    para2= "Being on social media, posting updates regularly and providing value for your followers is all wonderful, but how to go about forging a relationship. This is where social media and graphic design come in."
                    para3= "Let a social media design expert at Artographer help you leverage the power of social media graphic design to advance your business. A company like Artographer will provide dedicated and comprehensive social media design services, help you identify your social media strengths and also formulate long-term solutions."
                    button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <DigitalMarketingTools
                    title= "Social Media Marketing Tools We Use"
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer The Following Result Driven Social Media Marketing Services"
                    title1= "Facebook Marketing Services"
                    content1= "At Artographer, our experts will help any type of business to increase awareness, get more customers, and build a brand that people will love. Through our unique and tailored approach, we will help you achieve business success."
                    title2= "Instagram Marketing Services"
                    content2= "Instagram is one of the high-engagement social media platforms. We offer Instagram marketing services to attract more followers, increase engagement among people, and make your Instagram account more visible."
                    title3= "Twitter Marketing Services"
                    content3= "As a renowned social media marketing company, we help you to build a relationship with your target audience and convert them into sales. Also, we boost your business by including unique Twitter marketing strategies."
                    title4= "Pinterest Marketing Services"
                    content4= "Your business can greatly benefit by utilizing this social media. Our Pinterest marketing services will drive massive traffic to your website and boost your sales by attracting people with diverse interests."
                    title5= "LinkedIn Marketing Services"
                    content5= "LinkedIn is a platform to get connected with potential people based on industry and interest. Our team will help you to integrate the LinkedIn marketing strategies with your advertising efforts to produce a large number of leads and sales."
                    title6= "YouTube Marketing services"
                    content6= "Nowadays, rather than content and images, YouTube videos attract more audience. Our team provides services in various aspects of YouTube marketing by producing online videos, optimizing videos, analyzing videos, and advertising them."
                />
            </MainContainer>
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>White Label Social Media Marketing Agency – An Extension Of Your Team</SectionTitle>}
                    para1= {<span>White label social media marketing services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients.</span>}
                    para2= {<span>You take all the credits. Our professional social media marketers will make sure all the pieces of social media marketing are successfully implemented.<br /><br />As a general rule, we will never contact your customers directly.</span>}
                    para3= "But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the social media marketing needs of any agency. We are happy to work under your branding to take care of your customers."
                    button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Social Media Marketing Services"
                    content= {<span>We have worked with social media marketing clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in social media marketing services.<br /> If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Social Media Marketing Services'
                    title1="How does social media marketing agency work?"
                    content1="A social media marketing agency promotes the products and services of your business on various social media platforms. Artographer follows several effective strategies to manage your brand’s presence on all social networking websites and work to get the attention of your target audience."
                    title2="How much does social media marketing cost?"
                    content2="Social media management can cost from $1000 per month and may vary according to your business."
                    title3="How much does social media management cost?"
                    content3="Social media management cost is calculated based on your goals, and the results you expect out of it. For an accurate estimation, please contact us."
                    title4="Do you provide social media design services?"
                    content4="Yes! We provide social media design services. To get a brief idea about our social media design, take a look at our service page."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "Shopify Website Development"
                    subTitle4= "Creative Shopify Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default SocialMediaMarketing;