import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from 'react-bootstrap';
import { MainContainer } from "../globalStyles";
import { Wrapper } from "../styles/AboutSection.style";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { Link } from "react-router-dom";


function AboutSection() {
    return (
        <MainContainer>
            <Wrapper>
                <Row>
                    <Col sm={12} md={6} className="about-container-left">
                        <h2>Our Proven Process<br /><span>Brand, Build, Grow</span></h2>
                        <h4>
                            We combine creative branding, web design and the 
                            right online tools to accelerate your business success
                        </h4>
                        <p> 
                            Artographer was born in 2018 from a recognition that businesses 
                            needed a realistic and straightforward approach to growing effectively. 
                            Our team focuses on world-class branding, effective web design and powerful 
                            online tools that drive measurable growth results for our clients.
                        </p>
                        <p>
                            Artographer originally began as a full service digital marketing agency, 
                            helping businesses market themselves with the branding and websites they 
                            already had. Over time, we realized that businesses often had little to no 
                            foundational branding and lackluster online platforms that weren't converting 
                            leads for their business, even after marketing. Artographer honed in on 
                            this need and pivoted into a unique niche that focused on three implementation 
                            steps for business growth: Brand, Build, and Grow.
                        </p>
                        <div className="button-wrap">
                            <div className="button1">
                                <Link to= '/about-artographer?active=company-overview'>
                                    <PrimaryButton 
                                        smalltext= 'Learn More'
                                        maintext= 'About Us'
                                    />
                                </Link>
                            </div>
                            <div className="button2">
                                <Link to= '/request-a-quote'>
                                    <SecondaryButton 
                                        smalltext= 'Get from us'
                                        maintext= 'A Free Quote'
                                    />
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="about-container-right">
                        <img 
                            src= '../images/artographer-india-creative-studio.png'
                            alt= 'about'
                            style={{width: '100%', height: '100%', objectFit: 'contain', opacity: '0.9'}}
                        />
                        </div>
                    </Col>
                </Row>
            </Wrapper>
        </MainContainer>
    );
}

export default AboutSection;