import styled from "styled-components";

export const AccContainer = styled.div`


    .btn-container {
        text-align: left;

        .accordion {
            width: 100%;
            text-align: left;
            padding: 1.2rem 1rem .5rem 1rem;
            color: var(--dark-grey);
            background-color: rgba(244, 121, 53, .2);
            border: none;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: var(--primary);
            }

            .accordion-title {
                font-size: 1.1rem;
                font-weight: 600;

            }

            .accordion-icon {
                margin-left: auto;
                transition: transform 0.6s ease;
            }

            .rotate {
                transform: rotate(180deg);
            }
        }

        .active {
            color: white;
            background-color: var(--primary);
        }
    }

    .accordion-content {
        color: white;
        background-color: var(--primary);
        overflow: hidden;
        transition: max-height .6s ease;

        .accordion-text {
            padding: 1rem;
        }
    }

`;

export const AccContainer2 = styled.div`

    .accordion-section-title {

        @media screen and (max-width: 767px){
            text-align: center;
        }

        @media screen and (max-width: 480px){
            text-align: left;
        }
    }

    .accordion-container {
        margin-top: 2.5rem;
        padding: 0 2.5rem;

        @media screen and (max-width: 767px){
            padding: 0;
        }

        .btn-container {
            text-align: left;
            margin-bottom: 2rem;

            @media screen and (max-width: 480px){
                margin-bottom: 1rem;
            }

            .accordion {
                width: 100%;
                text-align: left;
                padding: 1.2rem 1rem .5rem 1rem;
                color: var(--dark-grey);
                background-color: rgba(244, 121, 53, .2);
                border: none;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    color: white;
                    background-color: var(--primary);
                }

                .accordion-title {
                    font-size: 1.1rem;
                    font-weight: 600;

                    @media screen and (max-width: 767px){
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    @media screen and (max-width: 480px){
                        font-size: .9rem;
                        font-weight: 600;
                    }
                }

                .accordion-icon {
                    margin-left: auto;
                    transition: transform 0.3s ease;
                }

                .rotate {
                    transform: rotate(180deg);
                }
            }

            .active {
                color: white;
                background-color: var(--primary);
            }
        }

        .accordion-content {
            color: white;
            background-color: var(--primary);
            overflow: hidden;
            transition: max-height .3s ease;

            .accordion-text {
                padding: 1rem;
            }
        }
    }

`;