import React from "react";
import { SectionContainer } from "../styles/WhiteLabel.style";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { SectionTitle } from "../globalStyles";
import { Link } from "react-router-dom";


function WhiteLabel(props) {
    return(
        <SectionContainer>
            <Row>
                <Col className="grid-wrap" md={4} sm={12}>
                    <SectionTitle className="wlld-section-title">{props.title}</SectionTitle>
                    <h5 className="wlld-section-subtitle">{props.subtitle}</h5>
                </Col>
                <Col className="grid-wrap" md={4} sm={12}>
                    <p className="bold-content">{props.contentbold1}</p>
                    <p>{props.content1}</p>
                    <p>{props.content3}</p>
                    <div>{props.image}</div>
                </Col>
                <Col className="grid-wrap column-third" md={4} sm={12}>
                    <p className="bold-content">{props.contentbold2}</p>
                    <p>{props.content2}</p>
                    <p>{props.content4}</p>
                </Col>
            </Row>
            <Link to= "/request-a-quote">
                {props.button}
            </Link>
        </SectionContainer>
    );
}

export default WhiteLabel;