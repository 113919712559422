import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck, FaChevronCircleRight } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import PlanCost from "../components/PlanCost";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function DigitalMarketing() {
    return(
        <Page>
            <Helmet>
                <title>
                    Digital Marketing Services Company | White Label Online Marketing Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/digital-marketing-services/" />
                <meta 
                    name="descriptions" 
                    content="Our Digital marketing company in India offers the best digital marketing services like ✅ SEO, ✅ PPC, ✅ SMO and more. Hire our digital marketing agency to boost your sales 💫 & business rapidly under the white label partnership"
                />
            </Helmet>
            <PageTitle
                title= "Global Digital Marketing Services"
                text1= "Artographer, a professional digital marketing company, will ensure that new customers and clients are able to find your business in the online space."
                text2= "Our digital marketers can help you obtain the maximum online visibility and exposure."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-Service Digital Marketing Company Will Fuel the Growth of Your Business"
                    content= "Many businesses ask themselves if they really need to seek the assistance of digital marketers. It is high time to get help if you are:"
                    listTitle= "Benefits of our professional logo design services include,"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Facing a marketing slowdown</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Reduced lead generation & business inquiries</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Unsure of where to start your marketing efforts</span></span>}
                    content3= {<span style={{marginBottom: "2rem",marginTop: "-.6rem", display: "block"}}>If your answer to all or few above options is ‘yes’, then you have to get help from our digital marketers at Artographer. We use a variety of different online marketing strategies to help you achieve your marketing goals. Get in touch with us today!</span>}
                    image= '../images/artographer-digital-marketing.png'
                    alt= 'artographer digital marketing'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>How Do Our Online Marketing Agency Work?</SectionTitle>}
                    para1= "Our professional digital marketers at Artographer know that there is no one-size-fits-all strategy. Every company is unique, so the planning and process have to be altered accordingly."
                    para2= "As a digital marketing agency, generally we will evaluate your website traffic, determine the best online platforms to invest, and regularly maintain the stability between your marketing activities and their results."
                    para3= "Our digital marketing team works together to formulate and implement a multi-faceted plan to bring more leads to your business and convert them to customers. On the whole, our digital marketers will listen, build, launch and grow your online presence."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Digital Marketing Services"
                content= "Our digital marketing team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <DigitalMarketingTools
                    title= "Digital Marketing Tools We Use"
                    image= {<img className="marketing-image" src="../images/digital-marketing-tools.svg" alt=""/>}
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer The Following Result-driven Digital Marketing Services"
                    title1= "Search Engine Optimization"
                    content1= "At Artographer, our team deals with advanced SEO practices and utilizes the leading SEO tools to produce the best results for our clients."
                    title2= "Social Media Marketing"
                    content2= "SMM helps to increase brand awareness and broaden customer reach. We understand social media growth and follow a successful social strategy to enhance your presence on social media."
                    title3= "Content Marketing"
                    content3= "CM stimulates the interest of people in a business’s products and services. Our team at Artographer covers the complete content marketing cycle i.e. strategy, creation, production, promotion, and measurement."
                    title4= "Conversion Rate Optimization"
                    content4= "CRO increases the percentage of visitors to your website. We use field-tested unique strategies that enhance your business and reduce the money you spend on ads on a monthly basis."
                    title5= "Online Reputation Management"
                    content5= "ORM helps to drive public opinion about a business, its products, and services. Our digital marketers follow unique techniques to maintain positive brand identity in the eyes of your customers."
                    title6= "PPC Management"
                    content6= "At Artographer, our team deals with advanced SEO practices and utilizes the leading SEO tools to produce the best results for our clients."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Digital Marketing Agency"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White label digital marketing services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. Our professional digital marketers will make sure all the pieces of digital marketing are successfully implemented.</span>}
                    content2= "As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the digital marketing needs of any agency. We are happy to work under your branding to take care of your customers."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <SectionTitle className="plan-cost-section-title">Monthly Digital Marketing Cost For Startups, Small Business and Online Businesses</SectionTitle>
                <div className="ldcs-section-container">
                    <Container>
                        <Row>
                            <Col>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Organic Born</span>}
                                    head3= {<span className="sub-head">For Beginners</span>}
                                    price= "249"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>DM Setup</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Monitor</span></span>}
                                    listItem3= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Report</span></span>}
                                />
                            </Col>
                            <Col>
                                <PlanCost
                                    head2= {<span className="ldcs-tab-head primary">Optimal Boost</span>}
                                    head4= {<span className="sub-head">For Startups and Maintenance</span>}
                                    price= "449"
                                    intro= "* Starts from / Monthly"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Advanced SEO</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Blogging</span></span>}
                                    listItem3= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Reports</span></span>}
                                    discount= "* 10% Off on Quarterly Billing"
                                />
                            </Col>
                            <Col>
                                <PlanCost
                                    head= {<span className="ldcs-tab-head">Turbo Start</span>}
                                    head3= {<span className="sub-head">For Long Term Benefits</span>}
                                    price= "649"
                                    intro= "*Starts from / Monthly + 10% Ad Fee"
                                    listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Ad Campaigns & Monitoring</span></span>}
                                    listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Blogging + Paid Directories</span></span>}
                                    listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Marketing Consulting</span></span>}
                                    listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Marketing Automation</span></span>}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized Digital Marketing Services"
                    content= {<span>We have worked with digital marketing clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a digital marketing services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Digital Marketing Services'
                    title1="How can your digital marketing services help our business?"
                    content1="Our Digital marketing services enables you to get in touch with as many people as possible. You can deliver your message through various channels (SEO, SMM, content marketing etc.) and create interest among people about your business. It’s a great chance for your business to reach more relevant leads."
                    title2="How long does it take to see results of your digital marketing efforts?"
                    content2="We will expect a minimum of six months of commitment from your end."
                    title3="What does your digital marketor do?"
                    content3="Our digital marketer will develop, implement and manage marketing campaigns according to the nature of your business. Our expert digital marketer will enhance the awareness of your brand in the digital space to acquire quality leads/customers."
                    title4="Why should I hire the digital marketing agency rather than In-House?"
                    content4="The major benefit of hiring an agency is all about their experience and resources. As a digital marketing company, we instantly acquire knowledge about new updates and implement them in our strategies to get better ROI."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default DigitalMarketing;