import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const Boat = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Boat Media Pvt. Ltd.</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/boat-media"
        />
        <meta
          name="descriptions"
          content="Our skilled web developers crafted a stunning, high-speed website for Boat Media, leaving the client fully satisfied with the results."
        />
      </Helmet>

      <PortfolioPage
        title="Branding and Website Development for Boat Media"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/boat-port-cover.jpg"
            alt="cure-heaven"
          />
        }
        bName="Boat Media Pvt. Ltd."
        industry="Branding & Printing"
        requirement="The client approached us from Gurugram, India. They looked for a collaboration with us for their complete website development, that also includes web design, logo design and content development."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/boat-bg-image.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            This website development process was challenging in satisfying the
            client’s expectations, and we did have few iterations for the logo
            design, website design, and content. The content part went through
            many reviews in trying to match the client’s requirements.
            <br />
            <br />
            The website design and development stage was a Milky Way. We used
            animated CTA buttons, hover animations, flip effects and many more
            appealing effects. Though it had few iterations, the client was
            aesthetic about the design, development process and was also much
            satisfied with the final look of the website.
          </span>
        }
        bottomImage={<img src="../images/portfolio/boat-form.jpg" alt="" />}
        preButton={
          <Link to="/portfolio/wet-lab">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/kenzi">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default Boat;
