import React from "react";
import { SectionContainer } from '../styles/RelatedServices.style';
import SatisfactionImage from "./SatisfactionImage";




function RelatedServices(props) {
    return(
        <>
            <SatisfactionImage />
            <SectionContainer>
                <div className="rswo-left-container">
                    <div className="tab-title">Related Services We Offer</div>
                    <p className="tab-text">Slide this section to view the services we offer that can help you take a decision.</p>
                </div>
                <div className="rswo-right-container">
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title}</a></div>
                        <p className="tab-content">{props.subTitle}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title2}</a></div>
                        <p className="tab-content">{props.subTitle2}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title3}</a></div>
                        <p className="tab-content">{props.subTitle3}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title4}</a></div>
                        <p className="tab-content">{props.subTitle4}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title5}</a></div>
                        <p className="tab-content">{props.subTitle5}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                    <div className="service-tabs">
                        <div className="tab-title-wrap"><a className="tab-title">{props.title6}</a></div>
                        <p className="tab-content">{props.subTitle6}</p>
                        <p className="tab-btn-wrap"><a className="tab-btn">Read More</a></p>
                    </div>
                </div>
            </SectionContainer>
        </>
    )
}


export default RelatedServices;