import styled from "styled-components";


export const SectionContainer = styled.div`
    padding: 0;

    .llds-container {
        text-align: center;
        margin-left: 120px;
        margin-right: 120px;

        @media screen and (max-width: 767px){
            margin: 0;
        }

        .llds-section-title {
            margin-bottom: 1rem;

            @media screen and (max-width: 767px){
                text-align: center;
            }
        }

        .llds-para-text {

            @media screen and (max-width: 480px){
                text-align: left;
            }
        }

        .llds-btn {
            margin-top: 2rem;

            @media screen and (max-width: 480px){
                margin-top: 1rem;
            }
        }
    }
`;