import React, { useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { AccContainer2 } from '../styles/Accordion.style';
import { FaChevronDown } from "react-icons/fa";
import { SectionTitle } from "../globalStyles";


function AccordionPro(props) {

    const [setActiveOne, setActiveStateOne] = useState("");
    const [setHeightOne, setHeightStateOne] = useState("0px");
    const [setRotateOne, setRotateStateOne] = useState("accordion-icon")

    const [setActiveTwo, setActiveStateTwo] = useState("");
    const [setHeightTwo, setHeightStateTwo] = useState("0px");
    const [setRotateTwo, setRotateStateTwo] = useState("accordion-icon")

    const [setActiveThree, setActiveStateThree] = useState("");
    const [setHeightThree, setHeightStateThree] = useState("0px");
    const [setRotateThree, setRotateStateThree] = useState("accordion-icon")

    const [setActiveFour, setActiveStateFour] = useState("");
    const [setHeightFour, setHeightStateFour] = useState("0px");
    const [setRotateFour, setRotateStateFour] = useState("accordion-icon")

    const content = useRef(null);

    function toggleAccordionOne() {
        setActiveStateOne(setActiveOne === "" ? "active" : "");
        setHeightStateOne(setActiveOne === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotateStateOne(setActiveOne === "active" ? "accordion-icon" : "accordion-icon rotate");

        setActiveStateTwo("");
        setHeightStateTwo("0px");
        setRotateStateTwo("accordion-icon");

        setActiveStateThree("");
        setHeightStateThree("0px");
        setRotateStateThree("accordion-icon");

        setActiveStateFour("");
        setHeightStateFour("0px");
        setRotateStateFour("accordion-icon");
    }

    function toggleAccordionTwo() {
        setActiveStateTwo(setActiveTwo === "" ? "active" : "");
        setHeightStateTwo(setActiveTwo === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotateStateTwo(setActiveTwo === "active" ? "accordion-icon" : "accordion-icon rotate")

        setActiveStateOne("");
        setHeightStateOne("0px");
        setRotateStateOne("accordion-icon");

        setActiveStateThree("");
        setHeightStateThree("0px");
        setRotateStateThree("accordion-icon");

        setActiveStateFour("");
        setHeightStateFour("0px");
        setRotateStateFour("accordion-icon");
    }

    function toggleAccordionThree() {
        setActiveStateThree(setActiveThree === "" ? "active" : "");
        setHeightStateThree(setActiveThree === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotateStateThree(setActiveThree === "active" ? "accordion-icon" : "accordion-icon rotate")

        setActiveStateOne("");
        setHeightStateOne("0px");
        setRotateStateOne("accordion-icon");

        setActiveStateTwo("");
        setHeightStateTwo("0px");
        setRotateStateTwo("accordion-icon");

        setActiveStateFour("");
        setHeightStateFour("0px");
        setRotateStateFour("accordion-icon");
    }

    function toggleAccordionFour() {
        setActiveStateFour(setActiveFour === "" ? "active" : "");
        setHeightStateFour(setActiveFour === "active" ? "0px" : `${content.current.scrollHeight}px`);
        setRotateStateFour(setActiveFour === "active" ? "accordion-icon" : "accordion-icon rotate")

        setActiveStateOne("");
        setHeightStateOne("0px");
        setRotateStateOne("accordion-icon");

        setActiveStateThree("");
        setHeightStateThree("0px");
        setRotateStateThree("accordion-icon");

        setActiveStateTwo("");
        setHeightStateTwo("0px");
        setRotateStateTwo("accordion-icon");
    }

    return(
        <AccContainer2>
            <SectionTitle className="accordion-section-title">{props.sectionTitle}</SectionTitle>
            <div className="accordion-container">
                <Row>
                    <Col sm={12} md={6}>
                        <div className="btn-container">
                            <button className={`accordion ${setActiveOne}`} onClick={toggleAccordionOne}>
                                <p className="accordion-title">{props.title1}</p>
                                <span className={`${setRotateOne}`}><FaChevronDown/></span>
                            </button>
                            <div ref={content} style={{maxHeight: `${setHeightOne}`}} className="accordion-content">
                                <div className="accordion-text"
                                dangerouslySetInnerHTML={{ __html: props.content1}}>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="btn-container">
                            <button className={`accordion ${setActiveTwo}`} onClick={toggleAccordionTwo}>
                                <p className="accordion-title">{props.title2}</p>
                                <span className={`${setRotateTwo}`}><FaChevronDown/></span>
                            </button>
                            <div ref={content} style={{maxHeight: `${setHeightTwo}`}} className="accordion-content">
                                <div className="accordion-text"
                                dangerouslySetInnerHTML={{ __html: props.content2}}>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="btn-container">
                            <button className={`accordion ${setActiveThree}`} onClick={toggleAccordionThree}>
                                <p className="accordion-title">{props.title3}</p>
                                <span className={`${setRotateThree}`}><FaChevronDown/></span>
                            </button>
                            <div ref={content} style={{maxHeight: `${setHeightThree}`}} className="accordion-content">
                                <div className="accordion-text"
                                dangerouslySetInnerHTML={{ __html: props.content3}}>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="btn-container">
                            <button className={`accordion ${setActiveFour}`} onClick={toggleAccordionFour}>
                                <p className="accordion-title">{props.title4}</p>
                                <span className={`${setRotateFour}`}><FaChevronDown/></span>
                            </button>
                            <div ref={content} style={{maxHeight: `${setHeightFour}`}} className="accordion-content">
                                <div className="accordion-text"
                                dangerouslySetInnerHTML={{ __html: props.content4}}>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </AccContainer2>
    )
}


export default AccordionPro;