import styled from "styled-components";



export const SectionContainer = styled.div`
    text-align: left;
    box-sizing: content-box;
    display: flex;
    margin: 0 0 var(--gap);
    position: relative;
    margin-top: 1rem;

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 3rem;
        bottom: 0;
        width: 5px;
        border-radius: 5px;
        background: linear-gradient(130deg,var(--primary),var(--primary) 41.07%,var(--secondary) 76.05%);
        z-index: 20;
    }

    .rswo-left-container {
        margin-top: 4rem;
        flex: 0 0 250px;
        margin-right: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 1.5rem;
        transform: translateY(-10px);
        color: #FFFFFF;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #333333;
            z-index: 2;
            border-radius: 8px;
        }

        &::before {
            content: "";
            background: linear-gradient(130deg,var(--primary),var(--primary) 41.07%,var(--secondary) 76.05%);
            position: absolute;
            top: -5px;
            left: -5px;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            z-index: 1;
            border-radius: 12px;
        }

        .tab-title {
            font-size: 2.3rem;
            font-weight: 700;
            line-height: 2.8rem;
            letter-spacing: -1px;
            margin-bottom: 1.2rem;
            position: relative;
            z-index: 3;
        }

        .tab-text {
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -1px;
            position: relative;
            z-index: 3;
        }
    }

    .rswo-right-container {
        scrollbar-color: #f8f8f8;
        scrollbar-gutter: always;
        padding: 3rem 0 1.5rem 2rem;
        margin: 0;
        display: flex;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        .service-tabs {
            min-width: 300px;
            min-height: 350px;
            box-shadow: -2rem 0 3rem -2rem #9A9A9A;
            padding: 1.5rem;
            border-radius: 16px;
            background: #FFFFFF;
            color: #333333;
            display: flex;
            flex-direction: column;
            transition: .2s;
            margin: 0;
            z-index: 2;

            &:first-child {
                z-index: 1;
            }

            &:not(:first-child) {
                margin-left: -100px;
                box-shadow: -3rem 0 3rem -2rem #9A9A9A
            }

            &:first-child:hover,
            &:first-child:focus-within {
                transform: translate(-.5rem,-1rem) rotate(3deg);
            }

            &:hover, 
            &:focus-within {
                transform: translateY(-1rem) rotate(3deg);
                margin-right: 100px;
            }

            .tab-title-wrap {
                min-height: 70px;
                margin-bottom: 20px;

                .tab-title {
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.8rem;
                    text-decoration: none;
                    letter-spacing: -1px;
                    background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: block;
                    cursor: pointer;
                }
            }

            .tab-content {
                font-size: .8rem;
                font-weight: 600;
                line-height: 1.2rem;
                text-transform: uppercase;
                color: var(--dark-grey);
            }

            .tab-btn-wrap {
                -webkit-margin-before: auto;
                margin-block-start: auto;
                display: grid;
                gap: .5rem;
                align-items: center;
                color: var(--dark-color);
                line-height: 1.3;
                padding-top: .5rem;
                margin-bottom: 0;
                width: 100%;
                
                .tab-btn {
                    font-size: .9rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: var(--primary);
                    cursor: pointer;

                    &:hover {
                        color: var(--secondary);
                    }
                }
            }
        }
    }
`;
