import styled from "styled-components";


export const SectionContainer = styled.div`
    padding: 0;

    .worf-section-title {
        margin-bottom: 2rem;
        text-align: center;
    }

    .worf-block-container {
        text-align: left;

        .wofr-block-title {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            letter-spacing: -1px;
        }

        p {
            margin: 0;
        }

        .wofr-block {
            padding: 1.5rem;
        }

        .br-1 {
            border-left: 10px solid rgba(51, 153, 255, .5);
            border-right: 5px solid rgba(51, 153, 255, .5);
            border-top: 10px solid rgba(51, 153, 255, .5);
            border-bottom: 5px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 991px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 5px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }
        }

        .br-2 {
            border-left: 5px solid rgba(51, 153, 255, .5);
            border-right: 5px solid rgba(51, 153, 255, .5);
            border-top: 10px solid rgba(51, 153, 255, .5);
            border-bottom: 5px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 991px){
                border-left: 5px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 10px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }
        }

        .br-3 {
            border-left: 5px solid rgba(51, 153, 255, .5);
            border-right: 10px solid rgba(51, 153, 255, .5);
            border-top: 10px solid rgba(51, 153, 255, .5);
            border-bottom: 5px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 991px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 5px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }
        }

        .br-4 {
            border-left: 10px solid rgba(51, 153, 255, .5);
            border-right: 5px solid rgba(51, 153, 255, .5);
            border-top: 5px solid rgba(51, 153, 255, .5);
            border-bottom: 10px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 991px){
                border-left: 5px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }
        }

        .br-5 {
            border-left: 5px solid rgba(51, 153, 255, .5);
            border-right: 5px solid rgba(51, 153, 255, .5);
            border-top: 5px solid rgba(51, 153, 255, .5);
            border-bottom: 10px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 991px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 5px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 10px solid rgba(51, 153, 255, .5);
            }

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 5px solid rgba(51, 153, 255, .5);
            }
        }

        .br-6 {
            border-left: 5px solid rgba(51, 153, 255, .5);
            border-right: 10px solid rgba(51, 153, 255, .5);
            border-top: 5px solid rgba(51, 153, 255, .5);
            border-bottom: 10px solid rgba(51, 153, 255, .5);

            @media screen and (max-width: 767px){
                border-left: 10px solid rgba(51, 153, 255, .5);
                border-right: 10px solid rgba(51, 153, 255, .5);
                border-top: 5px solid rgba(51, 153, 255, .5);
                border-bottom: 10px solid rgba(51, 153, 255, .5);
            }
        }
    }
`;