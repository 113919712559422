import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import { SectionTitle } from "../globalStyles";
import { SectionContainer } from '../styles/IndustriesBenefit.style';
import PrimaryButton from "./PrimaryButton";
import { Link } from 'react-router-dom';


function Hire(props) {
    return(
        <SectionContainer>
            <Container>
                <Row>
                    <Col className="bfol-left-container">
                        <SectionTitle className="bfol-section-title">{props.title}</SectionTitle>
                        <p className="bfol-para-text">{props.content}</p>
                        <Link to= '/request-a-quote'>
                            <PrimaryButton
                                smalltext= 'Its Free'
                                maintext= 'Get Custom Quote'
                            />
                        </Link>
                    </Col>
                    <Col className="bfol-right-container">
                        {props.image}
                    </Col>
                </Row>
            </Container>
        </SectionContainer>
    );
}


export default Hire;