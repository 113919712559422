import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { MainContainer } from "../globalStyles";
import { PhilosophyWrap } from "../styles/Philosophy.style";
import SecondaryButton from "./SecondaryButton";
import { Link } from "react-router-dom";
import { SectionTitle } from "../globalStyles";




function Philosophy() {
    return (
        <MainContainer>
            <PhilosophyWrap>
                <Row>
                    <Col md={6} sm={12}>
                        <div className="philo-image">
                            <img src="../images/work-together.webP" alt="artographer"/>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className="philo-content">
                            <SectionTitle style={{textAlign: 'left', marginBottom: '1.8rem'}}>We work with you, not for you</SectionTitle>
                            <p>Our philosophy is simple : we want our clients 
                                to love their brand's online presence and we do everything 
                                we can to help them achieve that. We work with you, not 
                                for you. We understand your business and what makes it 
                                special.
                            </p>
                            <br />
                            <Link to= '/request-a-quote'>
                                <SecondaryButton
                                    smalltext= 'Its Free'
                                    maintext= 'Ask for a Quote'
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </PhilosophyWrap>
        </MainContainer>
    );
}
  
export default Philosophy;