import 'bootstrap/dist/css/bootstrap.min.css';
import { Row } from 'react-bootstrap';
import { SectionTitle } from "../globalStyles";
import { SectionContainer } from '../styles/IndustriesBenefit.style';
import PrimaryButton from "./PrimaryButton";
import { Link } from 'react-router-dom';


function IndustriesBenefits(props) {
    return(
        <SectionContainer>
            <Row>
                <div className="bfol-right-container col-md-6 col-sm-12 order-md-2 d-flex align-item-center">
                    <img src="../images/industries-we-serve.svg" alt='industries'/>
                </div>
                <div className="bfol-left-container col-md-6 col-sm-12 order-md-1">
                    <SectionTitle className="bfol-section-title">{props.title}</SectionTitle>
                    <p className="bfol-para-text">{props.content}</p>
                    <Link to= '/request-a-quote'>
                        <PrimaryButton
                            smalltext= 'Its Free'
                            maintext= 'Get Custom Quote'
                        />
                    </Link>
                </div>
            </Row>
        </SectionContainer>
    );
}


export default IndustriesBenefits;