import styled from "styled-components";


export const ExpertiseCertifications = styled.div`
    text-align: center;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    max-width: 1300px;

    @media screen and (max-width: 1199px) {
        padding-left: 150px;
        padding-right: 150px;
    }

    @media screen and (max-width: 991px) {
        padding-left: 50px;
        padding-right: 50px;
    }

    @media screen and (max-width: 480px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    img {
        object-fit: contain;
        height: auto;
        width: 100%;
        text-align: center;
    }
`;