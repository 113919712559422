import styled from "styled-components";


export const SectionContainer = styled.div`
    max-width: 250px;

    @media screen and (max-width: 767px){
        max-width: 100%;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .head-wrap {
        background-color: #54595F;

        .ldcs-tab-head {
            display: block;
            font-size: 2rem;
            font-weight: 600;
            line-height: 2rem;
            letter-spacing: -1px;
            color: white;
            text-align: center;
            padding: 1.5rem;

            @media screen and (max-width: 767px){
                font-size: 1.8rem;
                padding: 1.5rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
                padding: 1rem;
            }
        }

        .sub-head {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
            color: white;
            text-align: center;
            padding-bottom: 1rem;
            margin-top: -.8rem;
            display: block;
        }
    }

    .head-wrap-primary {
        background-color: var(--primary);

        .primary {
            background-color: var(--primary);
            display: block;
            font-size: 2rem;
            font-weight: 600;
            letter-spacing: -1px;
            color: white;
            text-align: center;
            padding: 1.5rem;

            @media screen and (max-width: 767px){
                font-size: 1.8rem;
                padding: 1.5rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
                padding: 1rem;
            }
        }

        .sub-head {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
            color: white;
            text-align: center;
            padding-bottom: 1rem;
            margin-top: -.8rem;
            display: block;
        }
    }


    .ldcs-tab-wrap {
        background-color: #F7F7F7;
        padding: 2rem 1rem;
        text-align: center;

        .ldcs-plan-price {
            font-family: 'Poppins',sans-serif;
            font-size: 4rem;
            font-weight: 600;
            color: var(--dark-grey);
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 767px){
                font-size: 3rem;
            }

            @media screen and (max-width: 480px){
                font-size: 2.5rem;
            }

            span {
                font-size: 2rem;

                @media screen and (max-width: 767px){
                    font-size: 1.7rem;
                }

                @media screen and (max-width: 480px){
                    font-size: 1.5rem;
                }
            }

        }

        .ldcs-plan-intro {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.2rem;
        }

        .plan-details-wrap {
            margin-top: 5rem;
            padding: 0 1.3rem;

            @media screen and (max-width: 480px){
                margin-top: 3rem;
            }

            .ldcs-list-item {
                font-size: 1rem;
                font-weight: 500;
                list-style: none;

                @media screen and (max-width: 767px){
                    font-size: .9rem;
                }

                @media screen and (max-width: 480px){
                    font-size: .8rem;
                }

                &:last-child {
                    border: none;
                }

                .demo {
                    display: block;
                    padding-bottom: .6rem;
                    margin-bottom: .6rem;
                    border-bottom: 2px solid #D3D3D3;

                    @media screen and (max-width: 767px){
                        border-bottom: 1px solid #D3D3D3;
                    }

                    .list-icon {
                        margin-right: .5rem;
                        color: var(--primary);
                    }
                }

                .last {
                     border-bottom: none;
                }
            }
        }
        .ldcs-discount {
            font-size: .8rem;
            font-weight: 600;
            color: #696969;
            display: block;
            margin-top: 2rem;

            @media screen and (max-width: 480px){
                font-size: .7rem;
            }
        }
    }

    .ldcs-btn-container {
        text-align: center;
        margin-top: 2rem;
    }
`;