import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import {
  NavbarContainer,
  MenuWrap,
  Logo,
  QuicklinkCont,
  MenuTriggerWrap,
  RaqWrap,
  MenuItems,
  MainNavContainer,
  MobileMenuWrap,
  MobileMenuContainer,
} from "../styles/NavbarPro.style";
import LogoImg from "../assets/logo-black.png";
import MenuIcon from "../assets/menu-icon.png";
import { motion, AnimatePresence } from "framer-motion";
import PrimaryButton from "./PrimaryButton";
import ReactGA from "react-ga";
import HiringAnimated from "./HiringAnimated";

const NavbarPro = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const [hoverMenu, setHoverMenu] = useState(false);

  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <NavbarContainer>
      <div className="navbar-inner-Container">
        {/* <HiringAnimated /> */}
        <Row className="align-items-center">
          <Col md={12}>
            <MenuWrap>
              <div className="left-container">
                <ReactGA.OutboundLink eventLabel="Logo" to="/" target="_self">
                  <Logo src={LogoImg} alt="artographertacts"/>
                </ReactGA.OutboundLink>
              </div>
              <div className="right-container">
                <QuicklinkCont>
                  <div
                    className="desktop-only-wrap"
                    onMouseOver={() => setHoverMenu(true)}
                    onMouseOut={() => setHoverMenu(false)}
                  >
                    <div className="quick-links">Quick Links</div>

                    {hoverMenu && (
                      <ul className="on-hover-menu-wrap">
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/about-artographer"
                          >
                            About
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/services?active=graphic-design"
                          >
                            Graphic Design
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link className="list-item-link" to="/case-studies">
                            Case Studies
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/services?active=web-design"
                          >
                            Web Design
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link className="list-item-link" to="/portfolio">
                            Portfolio
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/services?active=web-development"
                          >
                            Web Development
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link className="list-item-link" to="/testimonials">
                            Testimonials
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/services?active=web-app-development"
                          >
                            Web App Development
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link className="list-item-link" to="/contact-us">
                            Contact Us
                          </Link>
                        </li>
                        <li
                          className="on-hover-menu-items"
                          onClick={() => setHoverMenu(false)}
                        >
                          <Link
                            className="list-item-link"
                            to="/services?active=digital-marketing"
                          >
                            Digital Marketing
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </QuicklinkCont>
                <MenuTriggerWrap>
                  <span className="menu-trigger">
                    <img
                      src={MenuIcon}
                      alt="menu trigger icon"
                      onClick={() => {
                        setOpenMenu(true);
                      }}
                    />
                  </span>
                </MenuTriggerWrap>
                <RaqWrap>
                  <Link to="/request-a-quote">
                    <PrimaryButton maintext="Request a Quote" />
                  </Link>
                </RaqWrap>
                <AnimatePresence exitBeforeEnter>
                  {openMenu && (
                    <MainNavContainer
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          delay: 0.1,
                          duration: 0.3,
                        },
                      }}
                    >
                      <motion.div
                        className="navbar-container"
                        initial={{
                          opacity: 0,
                          y: -100,
                        }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 0.3,
                            delay: 0.1,
                          },
                        }}
                        exit={{
                          opacity: 0,
                          y: -100,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                      >
                        <Row className="d-flex justify-content-center">
                          <div className="col-10 nav-inner-container">
                            <div className="nav-head-wrap">
                              <div className="nav-title-wrap">
                                <span className="nav-title">
                                  Explore our <br /> Market-Fit Services
                                </span>
                                <p className="nav-desc">
                                  We ensure to establish websites with the
                                  latest trends as we believe that, products
                                  whose value satisfies the needs of the market
                                  and its potential customers can be efficiently
                                  successful.
                                </p>
                              </div>
                              <div
                                className="close-button-wrap"
                                onClick={() => setOpenMenu(false)}
                              >
                                <>&#10005;</>
                              </div>
                              <div className="btn-container">
                                <Link to="/request-a-quote">
                                  <div onClick={() => setOpenMenu(false)}>
                                    <PrimaryButton
                                      smalltext="Its Free"
                                      maintext="Request a Quote"
                                    />
                                  </div>
                                </Link>
                                <div className="google-reviews-wrap">
                                  <img
                                    src="../images/google-reviews.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="nav-bottom-wrap">
                              <div className="align-top">
                                <MenuItems>
                                  <span className="list-title">
                                    Quick Links
                                  </span>
                                  <ul>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/about-artographer?active=company-overview"
                                      >
                                        About - Artographer
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/about-artographer?active=engagement-models"
                                      >
                                        Engagement Model
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/testimonials"
                                      >
                                        Testimonials
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/portfolio"
                                      >
                                        Portfolio
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/case-studies"
                                      >
                                        Case Studies
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/contact-us"
                                      >
                                        Contact Us
                                      </Link>
                                    </li>
                                  </ul>
                                </MenuItems>
                                <MenuItems>
                                  <span className="list-title">
                                    Our Services
                                  </span>
                                  <ul>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services"
                                      >
                                        Services
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services?active=graphic-design"
                                      >
                                        Graphic Design
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services?active=web-design"
                                      >
                                        Web Design
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services?active=web-development"
                                      >
                                        Web Development
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services?active=web-app-development"
                                      >
                                        Web App Development
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services?active=digital-marketing"
                                      >
                                        Digital Marketing
                                      </Link>
                                    </li>
                                  </ul>
                                </MenuItems>
                                <MenuItems>
                                  <span className="list-title">Hire Us</span>
                                  <ul>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services/hire-a-graphic-designer"
                                      >
                                        Hire a Graphic Designer
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services/hire-a-web-developer"
                                      >
                                        Hire a Web Developer
                                      </Link>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <Link
                                        className="nav-link-item"
                                        to="/services/hire-digital-marketing-manager"
                                      >
                                        Hire a Digital Marketing Manager
                                      </Link>
                                    </li>
                                  </ul>
                                </MenuItems>
                                <MenuItems>
                                  <span className="list-title">Connect</span>
                                  <ul>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <a
                                        className="nav-link-item"
                                        href="https://www.facebook.com/artographertacts/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Facebook
                                      </a>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <a
                                        className="nav-link-item"
                                        href="https://www.instagram.com/artographertactsindia/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Instagram
                                      </a>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <a
                                        className="nav-link-item"
                                        href="https://www.linkedin.com/in/artographer-tacts-304b49243/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Linkedin
                                      </a>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <a
                                        className="nav-link-item"
                                        href="https://twitter.com/ArtographerT"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Twitter
                                      </a>
                                    </li>
                                    <li onClick={() => setOpenMenu(false)}>
                                      <a
                                        className="nav-link-item"
                                        href="https://www.youtube.com/channel/UCLBQh-ta7EHR5oOADv9b_cw"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Youtube
                                      </a>
                                    </li>
                                  </ul>
                                </MenuItems>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </motion.div>
                    </MainNavContainer>
                  )}
                </AnimatePresence>
                <MobileMenuContainer>
                  <div className="toggle-btn-wrap">
                    <img
                      src={MenuIcon}
                      alt="menu toggle btn"
                      onClick={() => {
                        setMobileMenu(true);
                      }}
                    />
                  </div>
                  <AnimatePresence>
                    {mobileMenu && (
                      <MobileMenuWrap
                        initial={{
                          x: 1000,
                        }}
                        animate={{
                          x: 0,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        exit={{
                          x: 1000,
                          transition: {
                            duration: 0.4,
                          },
                        }}
                      >
                        <div
                          className="menu-outer-container"
                          onClick={() => setMobileMenu(false)}
                        >
                          <div className="mobile-close-button-wrap">
                            <span
                              className="mobile-menu-close-icon"
                              onClick={() => setMobileMenu(false)}
                            >
                              <>&#10005;</>
                            </span>
                          </div>
                          <ul className="mobile-link-container">
                            <li className="mobile-only-menu">
                              <Link className="mobile-menu-link" to="/">
                                Home
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/about-artographer?active=company-overview"
                              >
                                About Us
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link className="mobile-menu-link" to="/services">
                                Services
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/services/logo-design-company"
                              >
                                Logo Design
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/services/wordpress-development-services"
                              >
                                Website Development
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/hire-a-graphic-designer"
                              >
                                Hire a Graphic Designer
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/hire-a-web-developer"
                              >
                                Hire a Web Developer
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/portfolio"
                              >
                                Portfolio
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/testimonials"
                              >
                                Testimonials
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/case-studies"
                              >
                                Case Studies
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/request-a-quote"
                              >
                                Request A Quote
                              </Link>
                            </li>
                            <li className="mobile-only-menu">
                              <Link
                                className="mobile-menu-link"
                                to="/contact-us"
                              >
                                Contact Us
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </MobileMenuWrap>
                    )}
                  </AnimatePresence>
                </MobileMenuContainer>
              </div>
            </MenuWrap>
          </Col>
        </Row>
      </div>
    </NavbarContainer>
  );
};

export default NavbarPro;
