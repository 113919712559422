import styled from "styled-components";


export const TabWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const TabTitles = styled.div`
    text-align: left;
    position: relative;
    margin-top: 1rem;

    @media screen and (max-width: 767px){
        text-align: center;
        margin-left: .8rem;
        margin-top: 0;
    }

    @media screen and (max-width: 480px){
        margin-left: .4rem;
        margin-right: .4rem;
    }


    &::before, &::after {
            display: none;
        }

    .tab-title-container {
        position: absolute;
        right: 0;
    }

    .tab-name {
        font-size: 1.1rem;
        font-weight: 500;
        list-style: none;
        padding-left: 10px;
        margin: 0;
        margin-bottom: .5rem;
        border-radius: 0;
        color: var(--paragraph);
        transition: .3s all ease;

        @media screen and (max-width: 767px){
            font-size: .9rem;
            writing-mode: vertical-rl;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-bottom: 1rem;
        }
        
        &:hover {
            color: var(--primary);
            cursor: pointer;
        }
    }

    .active-name {
        color: var(--primary);

        &::before {
            content: 'For';
            color: var(--secondary);
            position: absolute;
            left: -20px;
            transition: all ease 0.5s;

            @media screen and (max-width: 767px){
                display: none;
            }
        }
        
        &:hover {
            cursor: default;
        }
    }
`;

export const TabContent = styled.div`
    text-align: left;
    color: var(--paragraph);
    margin-left: 25px;
    margin-right: 20px;

    @media screen and (max-width: 991px){
        margin-left: 15px;
    }

    &::before, &::after {
        display: none;
    }

    .tab-content {
        display: none;

    }

    .active-content {
        display: block;
    }


    .tab-heading {
        font-size: 2.8rem;
        font-weight: 700;
        line-height: 3.3rem;
        letter-spacing: -1px;
        margin-bottom: 1.5rem;
        background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (max-width: 480px){
            font-size: 1.7rem;
            line-height: 2.2rem;
            margin-bottom: 1rem;
        }
    }

    .tab-buttons {
        display: flex;
        float: left;
        width: 100%;
        font-size: 1.1rem;
        margin-top: 2rem;

        @media screen and (max-width: 767px){
            font-size: 0.8rem;
            margin-top: 0;
            flex-direction: column;
        }

        .tab-button {
            width: 50%;
            float: left;
            text-decoration: none;
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: transform;

            @media screen and (max-width: 767px){
                width: auto;
                margin-bottom: .7rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }


            &:hover {
                transform: translateX(10px);
            }
        }

        .tab-link {
            text-decoration: none;
            color: var(--secondary);
        }

        span {
        display: flex;
        align-items: center;
        justify-content: left;
        }
    }
`;

export const TabLinks = styled.div`
text-align: left;
font-size: 2.2rem;
text-transform: uppercase;
letter-spacing: 1px;
font-weight: 700;
line-height: 3.2rem;
color: transparent;
list-style: none;
margin-top: 7rem;
-webkit-text-stroke: 1px var(--primary);
transition: .5s ease;

@media screen and (max-width: 991px){
    display: none;
}

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 0 1rem;
    }

    .tab-shortcut {
        margin-bottom: .2rem;
        padding-left: .6rem;
        padding-right: .6rem;
        cursor: pointer;
        text-decoration: none;
        color: transparent;

    }

    .active-shortcut {
        -webkit-text-stroke: 0;
        color: white;
        border: 1px solid transparent;
        background-color: var(--primary);
        cursor: pointer;
    }
`;