import styled from "styled-components";


export const DarkContainer = styled.div`
    text-align: left;
    background-color: var(--background);
    padding: 11.5rem 15rem 3.5rem 15rem;

    @media screen and (max-width: 1199px){
        padding: 10rem 6rem 3rem 6rem;
    }

    @media screen and (max-width: 991px){
        padding: 10rem 2rem 3rem 2rem;
    }

    @media screen and (max-width: 767px){
        padding: 12rem 30px 2.5rem 30px;
    }

    @media screen and (max-width: 480px){
        padding: 9rem 15px 2rem 15px;
    }



        .pt-title-wrap {
            margin-top: 1rem;

            h1 {
                font-size: 3.8rem;
                font-weight: 700;
                letter-spacing: -2px;
                line-height: 4.3rem;
                color: var(--dark-grey);
                padding-left: .7rem;
                border-left: 4px solid var(--primary);

                @media screen and (max-width: 1199px){
                    font-size: 3.2rem;
                    line-height: 3.7rem;
                }

                @media screen and (max-width: 991px){
                    font-size: 3rem;
                    line-height: 3.5rem;
                    letter-spacing: -1px;
                }

                @media screen and (max-width: 767px){
                    font-size: 2.5rem;
                    line-height: 3rem;
                    letter-spacing: -1px;
                }

                @media screen and (max-width: 480px){
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                }
            }
        }

        .bold-text {
            font-size: 1.1rem;
            font-weight: 700;
            margin-bottom: -1rem;
        }

        p {
            font-size: 1rem;
            font-weight: 500;
            color: var(--dark-grey);

            @media screen and (max-width: 991px){
                margin-bottom: .8rem;
            }

            @media screen and (max-width: 767px){
                margin-bottom: .6rem;
            }

            @media screen and (max-width: 480px){
                font-size: .8rem;
            }
        }

        ul {
            padding: 0;

            .icon {
                font-size: .9rem;
                color: var(--primary);
                margin-right: 0.8rem;
            }

            li {
                list-style: none;
                font-size: 1rem;
                font-weight: 500;
                color: var(--dark-grey);
                margin-bottom: .5rem;

                @media screen and (max-width: 480px){
                    font-size: .9rem;
                    margin-bottom: .2rem;
                }

                .pt-list-icon {
                    margin-right: .8rem;
                    color: var(--primary);

                    @media screen and (max-width: 767px){
                        margin-right: .6rem;
                    }

                    @media screen and (max-width: 480px){
                        margin-right: .4rem;
                        font-size: .7rem;
                    }
                }
            }
        }

        .bottom-btn {
            margin-top: 2rem;
            display: block;
            
            @media screen and (max-width: 991px){
                margin-top: 1.5rem;
            }

            @media screen and (max-width: 767px){
                margin-top: 1rem;
            }
        }
`;

export const DarkContainer2 = styled.div`
    text-align: left;
    background-color: var(--background);
    padding: 0 150px;

    @media screen and (max-width: 991px) {
        padding: 0 50px;
    }

    @media screen and (max-width: 480px) {
        padding-right: 15px;
        padding-left: 15px;
    }
    

    .pt-title-wrap {
            padding: 12.5rem 0 3.5rem 0;

            @media screen and (max-width: 1199px) {
                padding: 10rem 0 3rem 0;
            }

            @media screen and (max-width: 991px) {
                padding: 10rem 0 2.5rem 0;
            }

            @media screen and (max-width: 767px) {
                padding: 12rem 0 1.5rem 0;
            }

            @media screen and (max-width: 480px) {
                padding: 10rem 0 1.5rem 0;
            }

            h1 {
                font-size: 3.8rem;
                font-weight: 700;
                letter-spacing: -2px;
                line-height: 4.3rem;
                color: var(--dark-grey);
                padding-left: .7rem;
                border-left: 4px solid var(--primary);

                @media screen and (max-width: 1199px) {
                    font-size: 3.2rem;
                    line-height: 3.7rem;
                }

                @media screen and (max-width: 991px) {
                    font-size: 2.9rem;
                    line-height: 3.4rem;
                }

                @media screen and (max-width: 767px) {
                    font-size: 2.5rem;
                    line-height: 3rem;
                }

                @media screen and (max-width: 480px) {
                    font-size: 2.2rem;
                    line-height: 2.7rem;
                }
            }
        }

`;