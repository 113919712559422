import React, {useState} from "react";
import Technologies from "../components/Technologies";
import Certification from "../components/Certification";
import Testimonials from "../components/TestimonialSection";
import Philosophy from "../components/Philosophy";
import ServiceTabs from "../components/ServiceTabs";
import AboutSection from "../components/AboutSection";
import ProcessTab from "../components/ProcessTab";
import OneRoof from "../components/OneRoof";
import Divider from "../components/Divider";
import Form from '../components/Form';
import HeroSection from "../components/HeroSection";
import DarkContainerSection from "../components/DarkContainerSection";


function Home() {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <ProcessTab />
            <Divider />
            <ServiceTabs />
            <Divider />
            <Technologies />
            {/* <Speed3x /> */}
            <DarkContainerSection />
            <Philosophy />
            <Form />
            <OneRoof />
            <Divider />
            <Testimonials />
            <Certification />
        </>
    );
  }

  export default Home;