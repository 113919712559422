import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import { Helmet } from 'react-helmet';
import PageImage from "../components/PageImage";




function Seo() {
    return(
        <Page>
            <Helmet>
                <title>
                    SEO Services Company India | Outsource Local SEO Agency India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/seo-services/" />
                <meta 
                    name="descriptions" 
                    content="We are the best SEO services company from India providing white label SEO solutions for agencies looking to outsource their SEO requirements to India. Click to know more."
                />
            </Helmet>
            <PageTitle
                title= "SEO Services"
                text1= "As one of the creative SEO services company in India, we ensure that your website gets good rankings in search engines like Google, Bing, Baidu, etc. Our SEO approach is completely free from black hat SEO techniques or any other spamming methods."
                text2= "We also offer white label SEO services for digital marketing agencies, SEO companies or web design agencies who want to add SEO to their service line-up without adding more employees. If you choose to work with us, you will truly get a reliable SEO partner."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "How Artographer SEO Services Help Your Business?"
                    content= "SEO has greatly evolved and is no longer a link-building process. Architecturally sound coding, fast loading pages, responsiveness of the website, and social media popularity determine the search engine ranking factor of a website."
                    content2= "All the SEO strategies we follow are unique and our SEO specialists make it a point to think out of the box to drive better results."
                    listTitle= "Our digital marketers can customize the techniques to your business requirement and help you generate the maximum ROI."
                    image= '../images/artographer-seo-service.png'
                    alt= 'artographer seo service'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our SEO Services"
                content= "Our SEO team at Artographer can help in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Custom SEO Solutions for Your Business Needs</SectionTitle>}
                    para1= "Every business has a different strategy. At Artographer, we know the fact that a strategy worked for a company may not work for others. At first, we analyze the needs of your business, get your budget, identify the target audience, and finally, understand your business goals."
                    para2= "Our team focus and work together to afford you with best search engine optimization services to meet your business goals. By employing unique skilled SEO services we can help you to increase the ROI of your business."
                    para3= "We work along with you to make your business stand apart from the competition."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <DigitalMarketingTools
                    title= "Digital Marketing Tools We Use"
                    image= {<img className="marketing-image" src="../images/seo-expert-certification.svg" alt=""/>}
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Our SEO Agency Process to Double Your Website ROI"
                    title1= "Advanced Website Audit"
                    content1= "Website audit is a complete analysis of all the factors that affects the visibility of your website in search engines. This will help us to identify and formulate a plan to resolve the issues."
                    title2= "Competitor Analysis"
                    content2= "Our SEO professionals will identify your primary competitors and evaluate their SEO strategy. It will help us to easily figure out where you lag and work on these elements to reach a higher position."
                    title3= "Keyword Research"
                    content3= "Our SEO professionals do a thorough keyword research to find out the popular words and phrases people search relating to your industry to figure out the terms to rank for."
                    title4= "SEO Content Creation"
                    content4= "Content plays an important role in improving your rankings. We create content that is consistent, trustworthy, relevant, engaging, and that suits your brand voice. We also optimize your existing content to increase relevancy and improve your rankings."
                    title5= "Search Engine Optimization Execution"
                    content5= "Our professional SEO experts use a combination of latest on-page and off-page strategies to increase website traffic so that you can reach a higher search engine position."
                    title6= "Monitor Traffic & Rankings"
                    content6= "We analyze every keyword, topic, and ranking as it’s extremely important to monitor SEO performance. This will give us details of whatever isn’t working and help us fix it."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label SEO Services"
                    subtitle= "An Extension of Your SEO Team from India"
                    content1= {<span>White label SEO services are suitable for agencies that are looking for extra marketing resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients. Our professional SEO professionals will make sure all the pieces of SEO are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.<br /><br />All of our B2B services are designed to off-load the SEO needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized SEO Services"
                    content= {<span>We have worked with clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a SEO services.<br /><br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our SEO Services'
                    title1="I already do PPC, do I need to do SEO?"
                    content1="Organic search (SEO) leads to more sales than paid online advertising methods. Also, conversion rate is high via SEO than PPC. You can also combine SEO and PPC to get better results."
                    title2="How do you charge for SEO services?"
                    content2="We charge based on the service you choose and how quickly you like to achieve your goals."
                    title3="I am in another country, can you still work for us?"
                    content3="Yes, we work for clients around the world."
                    title4="Do you guarantee higher rankings?"
                    content4="We do not guarantee a higher ranking. We don’t believe in short term benefits and always rely on search engine guidelines to produce better organic rankings which are long lasting."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Non Profit Website Development"
                    subTitle3= "Creative Non Profit Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default Seo;