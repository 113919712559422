import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from '../components/Divider';
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function WooCommerce() {
    return(
        <Page>
            <Helmet>
                <title>
                    WooCommerce Website Development Services Company India | Expert WooCommerce Theme, Plugin Developer
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/woocommerce-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Artographer website development provides one of the most powerful and flexible ways to add an e-commerce store to your website. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "WooCommerce Website Development"
                text1= "WooCommerce website development provides the most powerful and flexible way to add an e-commerce store to your website. It is an e-commerce platform plugin for WordPress."
                text2= "Our WooCommerce development company in India has worked on more than 50+ WooCommerce store development projects ranging from designing, development to payment integrations. All the WooCommerce store we create offer amazing functionality and user experience."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "WooCommerce Website Design and Development Company"
                    content= "At Artographer, we help businesses and site owners to get set-up with their very own WooCommerce websites – complete with custom designs and all the features they could possibly need. As an e-commerce platform, WooCommerce is of course suited to online businesses looking to sell their products."
                    listTitle= "This is perfect for highstreet stores, for arts and crafts, for digital marketers and more. More specifically, we recommend WooCommerce website development to:"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>New businesses looking to get started in e-commerce</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Existing companies looking to add an e-commerce platform</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Existing WooCommerce stores that need customization and updates</span></span>}
                    image= '../images/artographer-woocommerce-website.png'
                    alt= 'artographer woocommerce website'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <LocationbasedService
                    title= "WooCommerce Development Company in India"
                    content= "We mainly recommend WooCommerce for those companies that are selling 500 items or less and with no plans to scale beyond that point. For larger operations, a custom e-commerce platform may be more suitable. Ofcourse WooCommerce also requires that your website be powered by WordPress – though we can also migrate your site to the CMS if necessary. If you don’t yet have an e-commerce solution in place, then this is something that’s very much worth considering. This way you can start selling physical or digital products to a huge global market with no overheads other than storage and delivery."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our WooCommerce Web Development Company Services"
                content= "Our WooCommerce website development team at Artographer can develop in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Logo Design Services"
                    title1= "Custom Logo Design"
                    content1= "Our expert logo designers deliver customized logo designs that particularly define your brand and that best reflects your business specifications and expectations."
                    title2= "Iconic Logo Design"
                    content2= "We have expertise in designing iconic logos that specifically symbolizes your brand and create a positive impression of your products and services among your target audience."
                    title3= "Typography Logo Design"
                    content3= "We leverage the latest technology and tools to create aesthetically pleasing typography logos. Our creative typography logo design services will help to drive more active audience to your business."
                    title4= "Illustrative Logo Design"
                    content4= "Our creative logo designers create colorful and exquisite illustrative logos that make you stand out in the competition curve."
                    title5= "Monogram Logo Design"
                    content5= "We create stunning monogram logo designs by making the best use of the latest tools and technologies to create a unique brand identity."
                    title6= "Wordmark Logo Design"
                    content6= "We offer fascinating wordmark logo designs that have high recall values to grab the attention of your viewers and convey your brand message."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "Our WooCommerce Website Development Services Include"
                    content1= "At Artographer, we can handle the full set up and installation of your WooCommerce store and will populate it with items, add a new design and more. We also offer advanced customization not normally available through WooCommerce alone. These include:"
                    content2= {
                        <>
                            <span className="list-item"><FaCheck className="check-icon"/><span>Custom payment gateway options</span></span>
                            <span className="list-item"><FaCheck className="check-icon"/><span>Additional security and encryption</span></span>
                            <span className="list-item"><FaCheck className="check-icon"/><span>Custom designs and themes</span></span>
                            <span className="list-item"><FaCheck className="check-icon"/><span>Custom fields and information in forms</span></span>
                        </>
                    }
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based/Localized WooCommerce Website Development"
                    content= {<span>We have worked with WooCommerce website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a WooCommerce website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Marketing Automation Services"
                    subTitle3= "Creative Marketing Automation Services"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default WooCommerce;