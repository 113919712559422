import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function StartupWeb() {
    return(
        <Page>
            <Helmet>
                <title>
                    Startup Website Design Company | Web Development services for Startup
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/startup-website-design/" />
                <meta 
                    name="descriptions" 
                    content="Artographer offers Website Design & Development Services for Startups in US, UK, India. Our Startup Website Design Company helps to make the first impression into the Digital World. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Startup Website Development"
                text1= "Artographer helps you to create startup websites for various business models. We follow a unique and modern way to build websites which will reach your target customers."
                text2= "Our team builds the websites by including core values of your business and add all the necessary features to build a stunning website."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Our Full-service Startup Website Development Company will Fuel the Growth of Your Business"
                    content= "Artographer, a startup website design company in India brings the expertise you need for your startup’s website."
                    content2= "We design sites that make sure that all of your viewers remember you and come back when they need your services."
                    listTitle= "Artographer is a premier website design company that has helped several startups to grow and become successful. We help businesses to"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Make a high-impact digital debut</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Attract investors and customers</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Develop strategy for monetization of web and mobile</span></span>}
                    listItem4= {<span><FaCheck className="blds-list-icon"/><span>Effective digital marketing campaign to develop a trustworthy brand</span></span>}
                    image= '../images/artographer-startup-website.png'
                    alt= 'artographer startup website development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    para1= {<span className="bold-text">Web Development for Startups from Artographer</span>}
                    para2= {<span>Artographer provides best in class responsive website design services to startup business and small entrepreneurs.<br /><br />First impressions are everything in business and this is even truer on the web where attention spans are so famously short.</span>}
                    para3= {<span>An intuitive design that immediately grabs the visitor’s attention is essential.<br /><br />Our startup website development company serve for entrepreneurs taking their first steps in the world of business.</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Startup Website Development"
                content= "Our startup website development team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Artographer Provides Unique Web Development Services for Startups</SectionTitle>}
                    para1= "The right web design makes a huge difference to the success of any business by improving the way that potential clients and customers see it online. Website design for startups needs to be unique, eye catching, and engage the customer’s interest in your business."
                    para2= "Moreover, it needs to communicate your message and draw the user in so that they are invested in your cause. Even more importantly, it needs to utilize an intuitive and easy layout so that users know precisely how to find the information they’re looking for and so that they’re inspired to take action by signing up to your newsletter or making a purchase."
                    para3= "In fact, it’s especially crucial that small businesses in particular, have eye-grabbing designs to have any chance of being remembered by their visitors. Don’t turn down this opportunity to have modern and highly effective responsive website development for such an incredibly discounted price."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Why Choose Artographer for Your Startup Website Design Requirements?</SectionTitle>}
                    para1= "Artographer is a leading service provider of startup website development services in India from the world over."
                    para2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Progressive approach to technology</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Creative and innovative solutions</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Experienced developers</p></div>
                        </>
                    }
                    para3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Top technology tools</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>Transparency and ease of work</p></div>
                        </>
                    }
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Startup Website Development Services"
                    content= {<span>We have worked with startup website development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a startup website development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Membership Website Development"
                    subTitle2= "Creative Membership Website Development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default StartupWeb;