import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import DigitalMarketingTools from "../components/DigitalMarketingTools";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function ContentMarketing() {
    return(
        <Page>
            <Helmet>
                <title>
                    Content Writing Services Company India - Creative SEO Based Copy Writing Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/content-marketing-services/" />
                <meta 
                    name="descriptions" 
                    content="We Artographer are a leading Content Marketing Agency which provides high-quality SEO based content writing services to clients all over the world. Click to know more"
                />
            </Helmet>
            <PageTitle
                title= "Content Marketing Services"
                text1= "Artographer, one of the content writing companies in India with dedicated content writers working round-the-clock. We always try to deliver quality-rich services to the clients."
                text2= "As a content writing company, we take all necessary measures to deliver plagiarism free unique-content to boost SEO. We will assist you to elevate your success with our unique content writing techniques."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "SEO Based Content Writing Services"
                    content= "Our content writers at Artographer write search engine-friendly content in order to cope up with algorithms that are getting stricter nowadays. As one of the experienced SEO agency, our talented writers utilize modern SEO tools that will help to increase search engine ranking. Along with ranking, they also research industry-specific information and come up with content that matches the needs of our client’s requirement. We produce reliable content that focuses on your niche or industry. This will help to increase the online visibility, trust, and accessibility of your website among your target online market."
                    image= '../images/artographer-content-marketing.png'
                    alt= 'artographer content marketing'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development'
            />
            <MainContainer>
                <LocationbasedService
                    title= "We Build a Successful Content Marketing Strategy"
                    content= {<span>At Artographer, we set clear-end goals, to take your business to the next level. The content strategy we follow help us to analyze the competitors’ strength and create content that will work. With analyzed information, we create relevant content starting from blogs, news, press release, whitepapers, case studies, email pieces, e-newsletters, catalogs, brochures, billboards, postcards, sales letters, to online ads.<br /><br />We use several tools to analyze user engagement, social sharing, reliability, content performance, etc. Also, we have a team of expert web developers who create custom CMS that allows you to manage content updating activities efficiently.</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Content Marketing Services"
                content= "Our Content Marketing team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <DigitalMarketingTools
                    title= "Content Marketing Tools We Use"
                    image= {<img className="marketing-image" src="../images/digital-marketing-tools.svg" alt=""/>}
                    column1= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MARKETO</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>HUBSPOT</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>AHREFS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEM RUSH</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>BUZZUMO</p></div>
                        </>
                    }
                    column2= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GET RESPONSE</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ACTIVE CAMPAIGN</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MAIL CHIMP</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEND GRID</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>ZAPPIER</p></div>
                        </>
                    }
                    column3= {
                        <>
                            <div className="list-item"><FaCheck className="check-icon"/><p>MOZ</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ANALYTICS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>GOOGLE ADS</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>TAG MANAGER</p></div>
                            <div className="list-item"><FaCheck className="check-icon"/><p>SEARCH CONSOLE</p></div>
                        </>
                    }   
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Our Creative Content Writing Services Include"
                    title1= "Article/Blog Post Writing Services"
                    content1= "Encourage the interest of your website visitors and bring back them to your website again with our article/blog post writing services."
                    title2= "Product Descriptions Writing Services"
                    content2= "Convert your website audience with unique content that efficiently describes your products. We have fully skilled writers who can describe products in an attention-grabbing manner."
                    title3= "White Paper/ Ebook Writing Services"
                    content3= "Create a longlasting impression among users with effective white papers/ eBooks. Our whitepapers/eBooks can establish your brand as an industrial one and generate quality leads."
                    title4= "Social Media Content Writing Services"
                    content4= "Our writers produce content that will bring maximum impact on your social profiles, tweets, posts, via constant updates of quality content."
                    title5= "Website Content Writing Services"
                    content5= "Increase the credibility and exposure of your business with killer website content. Our writers bring out proficiency, accuracy, creativity, and professional quality in content."
                    title6= "Press Release / Newsletters Writing Services"
                    content6= "Keep your current and prospective customers up-to-date by sending fresh and relevant newsletter/press release content daily, weekly, or monthly."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Content Writing Services"
                    subtitle= "An Extension of Your Team"
                    content1= {<span>White-label content marketing services are suitable for agencies that are looking for extra marketing resources to share their workload. Artographer will work behind the scenes and make you look great in front of your clients.<br /><br />Our content writers will make sure all the pieces of content marketing are successfully implemented.</span>}
                    content2= {<span>As a general rule, we will never contact your customers directly.<br /><br />But in the event of such a situation, we are on your team. All of our B2B services are designed to off-load the content marketing needs of any agency. We are happy to work under your branding to take care of your customers.</span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based Content Writing Services Company"
                    content= {<span>We have worked with clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a content writing.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Logo Design Services'
                    title1="Do you offer free revisions?"
                    content1="We do not offer free revisions after the content is made live."
                    title2="Why should I hire a digital marketing agency rather than In-House?"
                    content2="The major benefit of hiring an agency is all about their experience and resources. As a digital marketing company, we instantly acquire knowledge about new updates and implement them in our strategies to get better ROI."
                    title3="How much do you charge for the content writing services?"
                    content3="We bill $1 per word for website pages and for blog/ article the cost differs from $80 to $950 based on the complexity of the topic."
                    title4="What inputs do you expects from clients before you start writing?"
                    content4="To write a perfect content you have to answer questions like 'who is your target audience?' and 'what are the goals of the project?'. These answers along with deep research can help us to deliver perfect content."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default ContentMarketing;