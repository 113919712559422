import React from "react";
import { DarkContainer } from "../styles/PageTitle.style";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";


function PageTitle(props) {
    return(
        <DarkContainer>
            <Row>
                <Col sm={12} md={6} className="pt-title-wrap">
                    <h1>{props.title}</h1>
                </Col>
                <Col sm={12} md={6}>
                    <p>{props.boldText}</p>
                    <p>{props.text1}</p>
                    <p>{props.text2}</p>
                    <p>{props.text3}</p>
                    <p>{props.text4}</p>
                    <ul>
                        <li>{props.listItem1}</li>
                        <li>{props.listItem2}</li>
                    </ul>
                    <Link to='/request-a-quote'
                        className="bottom-btn"
                    >
                        {props.button}
                    </Link>
                </Col>
            </Row>
        </DarkContainer>
    )
}

export default PageTitle;