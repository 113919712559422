import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck, FaChevronCircleRight } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import PlanCost from "../components/PlanCost";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function LogoDesign() {
    return(
        <Page>
            <Helmet>
                <title>
                    Logo Design Services Company India | Best Logo Design Services India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/logo-design-company/" />
                <meta 
                    name="descriptions" 
                    content="Get Best Logo Design Services India from Artographer Agency – Team of Professional Logo Designers / Logo Makers. We are the #1 Rated Online Logo Design Company India. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Logo Design Services"
                text1= "Artographer is a renowned logo design service company in India. We aim to design memorable and recognizable logos that make your business fly high in the online space."
                text2= "With our creative logo designs, you can easily build a distinctive identity, enlarge your customer base, and excel in the competition curve."
                text3= "Stay connected with us to avail our best logo design services that effectively convey your brand message to your target audience."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Benefits of Our Custom Logo Design Services"
                    content= "Attractive logos that we create will stand as an epitome of your organization and create a long-lasting impression on the visitor's minds at the very first glance. Our skilled designers create logos with impressive color palettes, enticing symbols, intricate calligraphy, and fabulous design art."
                    listTitle= "Benefits of our professional logo design services include,"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Copyright transfer</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Three days turnaround time</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Industry-specific designers</span></span>}
                    listItem4= {<span><FaCheck className="blds-list-icon"/><span>Highly-affordable price</span></span>}
                    listItem5= {<span><FaCheck className="blds-list-icon"/><span>100% money-back guarantee</span></span>}
                    image= '../images/artographer-logo-design-services.png'
                    alt= 'logo design service'
                />
            </MainContainer>
            <PageImage
                src='../images/graphic-service.webp'
                alt='graphic design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Logo Design Process</SectionTitle>}
                    para1= "A logo is the visual entry point for customers, hence, it is crucial to design appealing logos that effectively captivate the audience. At Artographer, we are specialized in designing eye-catchy logos for any business type and size."
                    para2= "Before embarking on the logo design for your business we will completely research your company's history, logo placement, logo size, logo style, and others."
                    para3= "Based on the market analysis and your suggestions, our logo designers create remarkable logo designs to build a successful brand image for your business in the online world."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Logo Design Services"
                content= "Our logo design team at Artographer are experts in various innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Logo Design Services"
                    title1= "Custom Logo Design"
                    content1= "Our expert logo designers deliver customized logo designs that particularly define your brand and that best reflects your business specifications and expectations."
                    title2= "Iconic Logo Design"
                    content2= "We have expertise in designing iconic logos that specifically symbolizes your brand and create a positive impression of your products and services among your target audience."
                    title3= "Typography Logo Design"
                    content3= "We leverage the latest technology and tools to create aesthetically pleasing typography logos. Our creative typography logo design services will help to drive more active audience to your business."
                    title4= "Illustrative Logo Design"
                    content4= "Our creative logo designers create colorful and exquisite illustrative logos that make you stand out in the competition curve."
                    title5= "Monogram Logo Design"
                    content5= "We create stunning monogram logo designs by making the best use of the latest tools and technologies to create a unique brand identity."
                    title6= "Wordmark Logo Design"
                    content6= "We offer fascinating wordmark logo designs that have high recall values to grab the attention of your viewers and convey your brand message."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Logo Design Agency"
                    subtitle= "A Dedicated Partner of your Team"
                    content1= "We provide excellent white label logo design services to tech-based companies looking to outsource their design services."
                    content2= "Our unique and impressive logos support start-ups, online professionals, small and medium, and large-sized businesses to create an engaging experience for their customers in the online sphere."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <SectionTitle className="plan-cost-section-title">Logo Design Cost for Startups, Small Businesses, and Online Businesses</SectionTitle>
                <div className="ldcs-section-container">
                    <Row>
                        <Col sm={12} md={4}>
                            <PlanCost
                                head= {<span className="ldcs-tab-head">Basic</span>}
                                price= "149"
                                intro= "Suitable for customers looking for logo only"
                                listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>3 Business Days</span></span>}
                                listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Concepts</span></span>}
                                listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>3 Round Revisions</span></span>}
                                listItem4= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Vector File Transfer</span></span>}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <PlanCost
                                head2= {<span className="ldcs-tab-head primary">Economic</span>}
                                price= "199"
                                intro= "Suitable for customers looking for logo & basic social media designs"
                                listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Business Days</span></span>}
                                listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Concepts</span></span>}
                                listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Unlimited Revisions</span></span>}
                                listItem4= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Vector File Transfer</span></span>}
                                listItem5= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Social Media Logo Tool Kit</span></span>}
                                listItem6= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Stationary Designs (Business Card)</span></span>}
                                discount= "* 10% Off on Quarterly Billing"
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <PlanCost
                                head= {<span className="ldcs-tab-head">Brand</span>}
                                price= "249"
                                intro= "Suitable for customers looking for complete logo and branding package"
                                listItem1= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>6 Business Days</span></span>}
                                listItem2= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>4 Concepts</span></span>}
                                listItem3= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Unlimited Revisions</span></span>}
                                listItem4= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Vector File Transfer</span></span>}
                                listItem5= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Social Media Logo Tool Kit</span></span>}
                                listItem6= {<span className="demo"><FaChevronCircleRight className="list-icon"/><span>Stationary Designs</span></span>}
                                listItem7= {<span className="demo last"><FaChevronCircleRight className="list-icon"/><span>Twitter Header, Facebook Cover</span></span>}
                            />
                        </Col>
                    </Row>
                </div>
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Logo design Services"
                    content= "We have worked with logo design clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a digital marketing services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle= 'Frequently Asked Questions about Our Logo Design Services'
                    title1="Why do I need a logo for my business?"
                    content1="Professional logos symbolize your brand message to the audience. By having a logo you can easily gain a unique identity in the competitive business world."
                    title2="Do I need to pay any other hidden charges for my logo design?"
                    content2="Don't panic about it! Other than your logo design package fee, we will not ask for any other hidden charges from you at any time."
                    title3="How much do you charge for a logo design?"
                    content3="Our price depends on logo placement, logo size, logo style, and others. It might vary from business-to-business. We have several packages to meet your individual requirements."
                    title4="What is the timeframe you take to design the logo?"
                    content4="Time-frame depends on the complexity of your requirements and the core value of the project. Normally, it takes 2-3 weeks for us to completely design your logo that best reflects your expectations."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default LogoDesign;