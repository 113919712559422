import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const VinayArts = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | Vinay Mehandi Artist</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/vinay-arts"
        />
        <meta
          name="descriptions"
          content="Our expert web developers created a stunning and fast portfolio website for Vinay Mehandi Artist, delivering a satisfying and polished result."
        />
      </Helmet>
      <PortfolioPage
        title="Website design for Vinay Mehandi Artist"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/vinay-arts-cover.jpg"
            alt="vinay-arts"
          />
        }
        bName="Vinay Henna Art Studio"
        industry="Art & Craft"
        requirement="The client approached us for a simple online portfolio where his clients can see the artwork."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals. This was a custom development that was developed from scratch."
        bgImage={"../images/portfolio/vinay-arts-bgimage.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            The client is a very popular henna artist in Delhi, India who owns a
            art studio in Delhi. Artistic henna tattoos are very popular and
            important in Indian culture, especially at important celebrations
            and rites of passage thats why most of people want to see the
            previous work of the artist before applying henna on their hands.
            <br />
            <br />
            This regular demand bring the client to our office to revamp his
            existing website. Then, we created the design prototype for the
            website with the new theme, color palette, layout, and style.
            <br />
            <br />
            Once design was confirmed, we started with the development works. We
            were so focused on UI and UX, so implemented smooth navigation,
            user-friendly elements, and so on.
          </span>
        }
        bottomImage={
          <img src="../images/portfolio/vinay-arts-gallery.webp" alt="" />
        }
        preButton={
          <Link to="/portfolio/cure-heaven">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/all-song-lyrics">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default VinayArts;
