import styled from "styled-components";

export const IframeContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;

    .iframe {
        overflow: hidden;
        border: 0;
        align-self: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;