import styled from "styled-components";



export const Wrapper = styled.div`
    text-align: left;
    
    .about-container-left {
        margin-top: 1rem;

        @media screen and (max-width: 767px){
            margin: 0;
        }

        h1 {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 3.1rem;
            letter-spacing: -1px;

            @media screen and (max-width: 1199px){
                font-size: 2.1rem;
                line-height: 2.7rem;
                margin-bottom: 2.2rem;
            }

            @media screen and (max-width: 991px){
                font-size: 2rem;
                line-height: 2.3rem;
            }

            @media screen and (max-width: 767px){
                text-align: left;
                margin-bottom: 1.5rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1.5rem;
                line-height: 2rem;
                margin-bottom: 1rem;
            }

            span {
                font-size: 2.7rem;
                font-weight: 800;
                color: var(--primary);

                @media screen and (max-width: 1199px){
                    font-size: 2.5rem;
                    line-height: 2.8rem;
                }

                @media screen and (max-width: 991px){
                    font-size: 2rem;
                    line-height: 2.3rem;
                }

                @media screen and (max-width: 767px){
                    margin-bottom: 1.8rem;
                    line-height: 2.3rem;
                }
            }

            
        }

        h4 {
            font-size: 1.2rem;
            font-weight: 600;
            color: gray;
            margin-top: 1rem;
            margin-bottom: 1rem;

            @media screen and (max-width: 991px){
                font-size: 1rem;
                line-height: 1.5rem;
            }

            @media screen and (max-width: 480px){
                font-size: 1rem;
                line-height: 1.3rem;
            }
        }

        .button-wrap {
            display: flex;
            margin-top: 2.5rem;
            padding: 0;

            @media screen and (max-width: 767px){
                margin-top: 1.5rem;
            }

            .button2 {
                margin-left: 2rem;

                @media screen and (max-width: 767px){
                    margin-left: 1.5rem;
                }

                @media screen and (max-width: 480px){
                    margin-left: 1rem;
                }
            }
        }
    }

    .about-container-right {
        padding: 0 3rem ;

        @media screen and (max-width: 1199px){
            padding: 0 3rem;
        }

        @media screen and (max-width: 767px){
            padding: 0 15px;
            margin-top: 3rem;
        }

        @media screen and (max-width: 480px){
            padding: 0 15px;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            opacity: .9;
        }
    }
`;


