import styled, {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Source Sans Pro',sans-serif;
        -moz-user-select:none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        :root {
            --primary: #e95b0d;
            --secondary: #0583d8;
            --paragraph: #333333;
            --background: #F8F8F8;
            --dark-grey: #333;
            --light-gray: #787878;
            --dark-blue: #121A24;
        }
    }
`;

export const MainContainer = styled.div`
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6rem;
    color: var(--paragraph);

    @media screen and (max-width: 1199px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: 991px) {
        font-size: 1rem;
        line-height: 1.5rem;
        padding-right: 30px;
        padding-left: 30px;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: 480px) {
        font-size: .9rem;
        line-height: 1.4rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-right: 15px;
        padding-left: 15px;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.9rem;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: 2.5rem;
    display: block;
    color: var(--dark-grey);

    @media screen and (max-width: 1199px){
        font-size: 2.1rem;
        line-height: 2.6rem;
        margin-bottom: 2.2rem;
    }

    @media screen and (max-width: 991px){
        font-size: 2rem;
        line-height: 2.3rem;
    }

    @media screen and (max-width: 767px){
        text-align: left;
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
    }
`;

export const GradientSectionTitle = styled.h3`
    font-size: 2.3rem;
    font-weight: 600;
    line-height: 3rem;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: 2.5rem;
    display: block;
    background: -webkit-linear-gradient(313deg,var(--primary),var(--primary),var(--secondary),var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const BackToTopButtonStyle = styled.button`
    position: fixed;
    bottom: 50px;
    right: 45px;
    border: none;
    background-color: #e95b0d;
    padding: 10px;
    color: white;
    z-index: 9999999;

    @media screen and (max-width: 767px){
        right: 25px;
        bottom: 25px;
    }

    @media screen and (max-width: 480px){
        right: 15px;
        bottom: 15px;
    }
`;

export const PageImageStyle = styled.div`

    @media screen and (max-width: 767px){
        display: none;
    }

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

export const ClientsLogoStyle = styled.div`
    margin-top: 2rem;
    object-fit: contain;

    @media screen and (max-width: 767px) {
        display: none;
    }
`;

export const DividerStyle = styled.div`

    .mobile_divider {
        display: none;

        @media screen and (max-width: 767px){
            display: block;
        }
    }

    .desktop_divider {

        @media screen and (max-width: 767px){
            display: none;
        }
    }
`


export default GlobalStyle;
