import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";





function StationeryDesign() {
    return(
        <Page>
            <Helmet>
                <title>
                    Business Stationery Design Company India | Best Stationery Design Services India
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/business-stationery-design/" />
                <meta 
                    name="descriptions" 
                    content="Get Best Stationery Design Services India from Artographer Agency – Team of Professional Stationery Designers / Stationery Makers. We are the #1 Rated Online Stationery Design Company India. Click to learn more."
                />
            </Helmet>
            <PageTitle
                title= "Business Stationery Design Services"
                text1= "Complete your branding with gorgeous custom stationery that keeps you looking polished and professional. Get custom stationery design from Artographer, and our designers will create something you’ll love."
                text2= "No matter how you choose to work, rest assured you’re working with the best. All of our designers are hand-vetted and rated"
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Benefits of Our Custom Stationery Design Services"
                    content= "From notes to clients to business letters, all types of communications your business sends out should be instantly recognisable as coming from you. So, make sure to use your startup’s brand guideline to help inform your design decisions!"
                    content2= 'Every letter you send out should be printed on a custom letterhead, with your company’s logo and the font you use elsewhere (on your website and marketing emails). This should match your business card design, your compliment slips and your correspondence cards. By making sure everything is consistent, it grows your brand – making you even more memorable to important correspondents and clients.'
                    listTitle= 'We have curated the best stationery designers so you can find the right expert and request a quote instantly.'
                    image= '../images/artographer-logo-design-services.png'
                    alt= 'logo design service'
                />
            </MainContainer>
            <PageImage
                src='../images/graphic-service.webp'
                alt='graphic design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Stationery Design Process</SectionTitle>}
                    para1= "The first step is to take stock of the physical dimensions of the stationery you are designing for. The copy and brand assets will further limit the amount of space to work with. While a logo, street address, web address, phone number, and email might fit on the full sheet, the half sheet and business card might have to be trimmed back to just the website, depending on the design."
                    para2= "Next, give some thought as to what the stationery needs to communicate and how it will be used. If a client will use the stationery for writing lengthy letters, they would require more whitespace. If they simply want to scrawl out a personal note by hand, they would probably need less."
                    para3= "The design to the right organizes its information with ample white space and a dramatic logo. The oversized logo feels weighty but the pillow of whitespace around it makes sure it doesn’t feel like it’s dragging down the page."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Stationery Design Services"
                content= "Our business stationery design team at Artographer are experts in various innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <Divider />
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Stationery Design Services"
                    title1= "Custom Stationery Design"
                    content1= "Our expert stationery designers deliver customized designs that particularly define your brand and that best reflects your business specifications and expectations."
                    title2= "Iconic Stationery Design"
                    content2= "Our creative brief makes it easy to describe your vision for the perfect stationery design and set your budget. "
                    title3= "Typography Stationery Design"
                    content3= "We leverage the latest technology and tools to create aesthetically pleasing typography Stationery. Our creative typography Stationery design services will help to drive more active audience to your business."
                    title4= "Illustrative Stationery Design"
                    content4= "Our creative Stationery designers create colorful and exquisite illustrative Stationery that make you stand out in the competition curve."
                    title5= "Professional Stationery Design"
                    content5= "We create stunning professional stationery designs by making the best use of the latest tools and technologies to create a unique brand identity."
                    title6= "Stationery Stands Out"
                    content6= "We offer fascinating stationery designs that have high recall values to grab the attention of your viewers and convey your brand message."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Stationery Design Agency"
                    subtitle= "A Dedicated Partner of your Team"
                    content1= "We provide excellent white label stationery design services to tech-based companies looking to outsource their design services."
                    content2= "Our unique and impressive stationery support start-ups, online professionals, small and medium, and large-sized businesses to create an engaging experience for their customers in the online sphere."
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Stationery design Services"
                    content= "We have worked with stationery design clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a digital marketing services. If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer."
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Stationery Design Services'
                    title1="What will I get with my stationery design?"
                    content1="Our designers are experts at creating letterhead, envelopes, envelope stickers, compliments slips, invoices, receipts and much more. No matter what you have designed, you’ll get everything you need to move to production. Your stationery design will be uploaded in the correct formats based on your stated needs."
                    title2="Do I need to pay any other hidden charges for my stationery design?"
                    content2="Don't panic about it! Other than your stationery design package fee, we will not ask for any other hidden charges from you at any time."
                    title3="How much do you charge for a stationery design?"
                    content3="Our price depends on stationery placement, size, style and others. It might vary from business-to-business. We have several packages to meet your individual requirements. To know more about our stationery design pricing options, you can call us +91 881-043-7133.."
                    title4="What if I need more than one type of stationer designed?"
                    content4="Your stationery design process includes one piece of stationery (letterhead, envelope, note or compliment slip, etc). If you need additional assets created, you have the option to select that when you start your process (for an additional cost). You can also choose to start a 1-to-1 Project with your winning designer after the process to create the additional assets. "
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Graphic Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default StationeryDesign;