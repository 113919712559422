import { SecondaryButtonStyle } from '../styles/Buttons.style';
import { CgChevronRightO } from "react-icons/cg";

import React from 'react';

const SecondaryButton = (props) => {
    return (
        <SecondaryButtonStyle>
            <span className='small-text'>
                {props.smalltext}
            </span>
            <span className='main-text'>
                {props.maintext}
                <CgChevronRightO className='icon'/>
            </span>
        </SecondaryButtonStyle>
    );
}

export default SecondaryButton;

