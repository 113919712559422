import React from 'react';
import { PageStyle } from '../styles/privacy&Terms.style';
import PageTitleFullWidth from '../components/PageTitleFullWidth';
import { MainContainer } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import {Helmet} from 'react-helmet';




const TermConditions = () => {
    return (
        <PageStyle>
            <Helmet>
                <title>
                    Artographer Terms and Conditions statement.
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/terms-conditions/" />
                <meta 
                    name="descriptions" 
                    content="Artographer Terms and Conditions statement."
                />
            </Helmet>
            <PageTitleFullWidth
                title= "Terms and Conditions"
            />
            <MainContainer style={{textAlign: 'left'}}>
                <div className='section-block'>
                    <p className='text-content'>Welcome to our Artographer website.</p>
                    <p className='text-content'>If you continue to browse and use our website, you are agreeing to abide by the following terms and conditions. Please read them carefully before continuing.</p>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Artographer has its own standing and at times our website may use “we” or “us” to refer our identity. The term “you” refers to the user or viewer of our website</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>The content in our website are general and is subject to change without notice. Use of materials/information on our website is entirely at your own risk and we are not liable for the damages you may suffer</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>We do our best to give you the right information through our website. However, please make sure to contact us or any other experts in the field to make any important decision20</p>
                        </li>                    
                    </ul>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Copyright, IP Rights and Trademark
                    </div>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Our website has materials such as texts, images, and sound, protected by copyright/intellectual property rights</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>All the materials in this site are owned by us or licensed by its owners (incase of using third-party logos or images we ensure to provide the respective source, and disclosure) for usage on our website</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>After conducting thorough research and carefully considering the ratings and the type of services, we select the listing for every curated blog of Artographer</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>All the trademarks on this website belong to us or are licensed by those trademark owners for usage on our website. The trademarks present in our website that are not a property of Artographer e.g.Facebook, are given proper acknowledgement</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Illegal use of this website may give rise to a claim for damages or be a criminal offence</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You may not copy (print, store, download), distribute, broadcast, or tamper with any of the content present in the website</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <span className='block-title'>
                        Changes to Terms and Conditions
                    </span>
                    <p className='text-content'>Artographer may change the above said terms and conditions at any time. You need to check them each time you visit the website. Be aware that you are also bound to the changes made to the terms and conditions</p>
                    <span className='block-title'>
                        Data Protection
                    </span>
                    <p className='text-content'>Personal data that you give through our website will be used according to our <a href='/privacy-policy'>privacy policy</a>. Please read it carefully</p>
                    <span className='block-title'>
                        Contact Us
                    </span>
                    <p className='text-content'>If you have any questions or complaints about our website, please <a href='/contact-us'>contact us</a>.</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Cancellation and Refund Policy
                    </div>
                    <p className='text-content'>Artographer depends on this Cancellation and Refund Policy to assure your satisfaction. In some situations, you may not be satisfied with our services for reasons that are beyond our control. Under such circumstances, we may process a refund or cancellation under the following conditions..</p>
                    <span className='block-title'>
                        Filing a Complaint
                    </span>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>At Artographer, we will keep up our promise of taking every project to the final stage and completing it as per your requirements. If such a situation arises where you are dissatisfied with our services, a refund will be considered only when things truly go out of hand.</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>We will strive to find a solution where both of us can mutually agree on. Even then, if you wish to get a refund, we request you to send a message for a final dialogue before we proceed with the refund.</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>You can go ahead and file for a ‘refund claim’ when we are not able to agree on a mutually acceptable solution.</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Refund Eligibility
                    </div>
                    <p className='text-content'>Here we have clearly listed the Cancellation and Refund Policy related to each of our services.</p>
                    <span className='block-title'>
                        Graphic design/logo design/brochure design
                    </span>
                    <p className='text-content'><span className='bold-text'>Full refund</span> – You will be eligible for a full refund if the initial design style of the project has not been approved or if the project has not been started. In such a situation, a full refund policy will take place.</p>
                    <p className='text-content'><span className='bold-text'>Partial refund</span> – if there is a delivery failure from our end after the approval of the initial design style, we will release a partial refund which will be in proportion to the work we completed.</p>
                    <p className='text-content'><span className='bold-text'>No refund</span> – You cannot avail of any refund if the project is completed.</p>
                    <span className='block-title'>
                        Web programming
                    </span>
                    <p className='text-content'><span className='bold-text'>Full refund</span> – If the project has not been kicked off.</p>
                    <p className='text-content'><span className='bold-text'>Partial refund</span> – We will issue a partial refund if we fail to complete the project as per our contract of agreement. The refund amount will be calculated in proportion to the project completed vs the project that is yet to be completed as per the scope of the project.</p>
                    <p className='text-content'><span className='bold-text'>No refund</span> – You cannot avail of any refund if the project is completed and uploaded to the server.</p>
                    <span className='block-title'>
                        Dedicated hiring
                    </span>
                    <p className='text-content'><span className='bold-text'>Full refund</span> – If the project has not been kick-started by the developer/designer/content writer/SEO expert/or any other resource.</p>
                    <p className='text-content'><span className='bold-text'>Partial refund</span> – According to the work done till you decide to cancel the order.</p>
                    <p className='text-content'><span className='bold-text'>No refund</span> – For the services that have already been provided.</p>
                    <span className='block-title'>
                        SEO & Internet marketing
                    </span>
                    <p className='text-content'>We do not have any control over the policies of the search engines or directories. Getting listed on some search engines and directories may take longer, even after the commencement of the project. In some cases, search engines and directories may drop the listing for no apparent or predictable reason. So, Artographer is not responsible for changes made to your website by other parties that may affect the search engine or directory ranking.</p>
                    <p className='text-content'>Our SEO and internet marketing services require an extensive amount of resources and time to formulate the right strategy, which leads us to incur internal expenses. So, payment made for SEO and other internet marketing services is non-refundable.</p>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Refund Eligibility
                    </div>
                    <ul>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Partial refunds will be processed within a maximum of 100 business days from the date of cancellation.</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Full refunds will be processed within 50 business days.</p>
                        </li>
                        <li>
                            <span className='check-icon'><FaCheck /></span>
                            <p>Refunds will be paid either by credit card, Bank Transfer or paypal.</p>
                        </li>
                    </ul>
                </div>
                <div className='section-block'>
                    <div className='block-head'>
                        Contact Us Regarding Cancellation and Refund Policy Questions
                    </div>
                    <p className='text-content'>We reserve the right to change this policy at any time. So, we request you to review this policy periodically. If you have any questions or suggestions regarding this policy, kindly <a href='/contact-us'>send us a message.</a></p>
                    <p className='text-content'>Note – This Cancellation and Refund Policy is applicable to all the websites owned by Artographer.</p>
                    <p className='text-content'>Policy updated on – January 2022</p>
                </div>
            </MainContainer>
        </PageStyle>
    );
}

export default TermConditions;
