import React from "react";
import PortfolioPage from "../../components/PortfolioPage";
import { Link } from "react-router-dom";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { Helmet } from "react-helmet";

const AllSongLyrics = () => {
  return (
    <>
      <Helmet>
        <title>Portfolio Archive - Artographer | allsonglyrics.in</title>
        <link
          rel="canonical"
          href="https://www.artographertacts.com/portfolio/all-song-lyrics"
        />
        <meta
          name="descriptions"
          content="Our talented graphic design team crafted a unique logo for AllSongLyrics.in, leaving the client highly satisfied with the result."
        />
      </Helmet>
      <PortfolioPage
        title="Logo Design for All Song Lyrics"
        titleImage={
          <img
            className="title-image"
            src="../images/portfolio/all-songs-lyrics.jpg"
            alt="cure-heaven"
          />
        }
        bName="All Song Lyrics"
        industry="Music"
        requirement="The client approached us from Noida, India. They looked for a collaboration with us for their complete website development, that also includes web design and logo design."
        process="We follow a common process for all our clients. As this client had unique requirements, we brainstormed within the team and tailor-made the process that best suited the client’s goals."
        bgImage={"../images/portfolio/all-song-lyrics-portfolio.jpg"}
        bTitle="What did we do for the client?"
        bContent={
          <span>
            Allsonglyrics.in is an online music gallery where anyone can enjoy
            the music with lyrics and other details like singer, writer,
            artists, release date etc. This website development process was
            challenging in satisfying the client’s expectations, and we did have
            few iterations for the logo design and website design.
            <br />
            <br />
            The website design and development stage was a Milky Way. We used
            animated CTA buttons, hover animations, flip effects and many more
            appealing effects. Though it had few iterations, the client was
            aesthetic about the design, development process and was also much
            satisfied with the final look of the website. We also designed some
            web banners and CD covers.
          </span>
        }
        bottomImage={
          <img src="../images/portfolio/all-song-lyrics-logo.jpg" alt="" />
        }
        preButton={
          <Link to="/portfolio/vinay-arts">
            <button>
              <IoIosArrowDropleft className="icon-left" />
              Previous Portfolio Item
            </button>
          </Link>
        }
        nxtButton={
          <Link to="/portfolio/wet-lab">
            <button>
              Next Portfolio Item
              <IoIosArrowDropright className="icon-right" />
            </button>
          </Link>
        }
      />
    </>
  );
};

export default AllSongLyrics;
