import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import WhiteLabel from "../components/WhiteLabel";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from '../components/Divider';
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function WebRedesign() {
    return(
        <Page>
            <Helmet>
                <title>
                    Website Redesign Services Company India - UX, UI Redesign Agency
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/website-redesign-company/" />
                <meta 
                    name="descriptions" 
                    content="Best Website Redesign Company - Artographer provides custom website redesign services to give a refreshing look to your website. We take care of examining your UX and UI redesign to increase website conversions. Click to know more."
                />
            </Helmet>
            <PageTitle
            boldText= {<span className="bold-text">Best UX and UI Design & Redesign Services</span>}
            title= "Website Design & Redesign Services"
            text1= "Artographer offers website design and redesign services for any type of online website. Our developers and designers bring the knowledge of user behavior and business expectations into every website design to engage the right audience."
            text2= "Whether you are a solo online professional or running a small online store, or a small business owner – we are here to improve your website traffic, UX, and ROI of your website through website redesign."
            listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
            listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
            button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Revamp and Redefine Your Digital Presence with Our Website Redesign Services"
                    content= "Users interact with sophisticated, modern and intuitive websites on a daily basis. If your website is not in-line with current trends, it may signal to users that your company is outdated."
                    content2= "Even if you think your current website design is the best, after a while a website redesign is absolutely necessary."
                    listTitle= "Some of the major benefits of redesigning your website are"
                    listItem1= {<span><FaCheck className="blds-list-icon"/><span>Improved user experience (UX) and ROI</span></span>}
                    listItem2= {<span><FaCheck className="blds-list-icon"/><span>Great exposure to digital marketing</span></span>}
                    listItem3= {<span><FaCheck className="blds-list-icon"/><span>Increased social authority and website traffic</span></span>}
                    image= '../images/artographer-web-redesign-service.png'
                    alt= 'artographer web redesign services'
                />
            </MainContainer>
            <PageImage
                src='../images/web-design-service.webp'
                alt='web design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Our Website Redesign Process</SectionTitle>}
                    para1= "We begin the website redesign process with you. Our developers and designers will listen to you and take detailed notes – things you like/dislike about your current website, and what you expect out of a redesign."
                    para2= "We will take inspiration from websites you like, collect required data from your existing website, and talk with your team to understand what type of website redesign will make you happy. Once we have all the data, we’ll begin the redesign work."
                    para3= {<span>You’ll be kept in the loop during every stage of the process. After the website is finished, we’ll transfer all the data to the new website and launch the website for the world to see! <br /> <br />Whether you need a big change or a small one, our website redesign professionals are always ready to revive your online presence.</span>}
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Website Redesign Services"
                content= "Our website redesign team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "We Offer the Following Result-driven Website Redesign Services"
                    title1= "HTML5 Website Redesign"
                    content1= "HTML5 web design along with Javascript and CSS3 has gained prominence. You can rely on our HTML5 website redesign services to get the most innovative responsive design for your website."
                    title2= "CMS Website Redesign"
                    content2= "No matter the size, we can redesign your WordPress website to make it growth-driven and conversion focused to please your visitors and help you drive marketing results."
                    title3= "E-commerce Website Revamp"
                    content3= "With the help of our developers, we can redesign your E-commerce website to help your potential and existing clients better connect with your business."
                    title4= "PHP & Laravel Web Application Redesign"
                    content4= "Our developers and designers at Artographer excel at redesigning custom PHP and Larvel web applications that will mirror your brand image."
                    title5= "UX, Micro Interaction & Animation Redesign"
                    content5= "Our talented team of designers and developers can create and redesign inspirationally simple and proactively vibrant UX, micro interactions, and website animations."
                    title6= "Improve SEO & Marketing by Redesign"
                    content6= "We can redesign your website to improve your digital marketing efforts and at the same time safeguard your current search engine authority."
                />
            </MainContainer>
            <MainContainer>
                <WhiteLabel 
                    title= "White Label Website Redesign Agency"
                    subtitle= "A Dedicated Partner of your Team"
                    content1= {<span>White label website redesign outsourcing is suitable for agencies that are looking for extra resources to share their workload.<br /><br />All of our B2B services are designed to off-load the website redesign needs of any agency.</span>}
                    content2= {<span>We are happy to work under your branding to take care of your customers.<br /><br />Let us redesign websites for your clients on time and on budget!</span>}
                    button= {<span className="wlld-quote-button"><PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' /></span>}
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Website Redesign Services"
                    content= {<span>We have worked with website redesign clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in a website redesign services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle='Frequently Asked Questions about Our Website Redesign Services'
                    title1="Will you provide mockups for my website redesign?"
                    content1="Yes, we do. We provide full-color mockups for every project we work."
                    title2="How much does it cost to redesign a website?"
                    content2="Redesigning website would always depend on the size and structure of your website. We would charge between $1500 to $5000 for a medium level businesses."
                    title3="How many revisions can I make in the design?"
                    content3="We will understand your requirements before creating the mockups. So naturally, there will be minor changes and additions. If you request too many changes, you will be incurred more charges."
                    title4="Can I update my own website, or you will do it for me?"
                    content4="The major benefit of hiring an agency is all about their experience and resources. As a digital marketing company, Once after we launch the redesigned website, we encourage all our clients to maintain their own website because we design it in a user-friendly way. However, if you’re too busy to update the website or need professional assistance, you can avail our website maintenance services. Acquire knowledge about new updates and implement them in our strategies to get better ROI."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Logo Design Services"
                    subTitle= "Creative Graphic Design Services"
                    title2= "Mobile App UI & UX Design"
                    subTitle2= "Mobile app development | Develop android app | IOS application development"
                    title3= "Startup Website Development"
                    subTitle3= "Creative Startup Website Development"
                    title4= "E-commerce Website Development"
                    subTitle4= "Creative E-commerce Website Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default WebRedesign;