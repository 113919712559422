import React from "react";
import PageTitle from '../components/PageTitle';
import { MainContainer, SectionTitle } from '../globalStyles';
import { FaCheck } from "react-icons/fa";
import { Page } from '../styles/AllPage.style'
import AccordionPro from "../components/AccordionPro";
import RelatedServices from "../components/RelatedServices";
import LocationbasedService from "../components/LocationbasedServices";
import ResultDriven from "../components/ResultDriven";
import IndustriesBenefits from "../components/IndustriesBenefit";
import OurProcess from "../components/OurProcess";
import PrimarySection from "../components/PrimarySection";
import OneRoof from "../components/OneRoof";
import Certification from "../components/Certification";
import PrimaryButton from "../components/PrimaryButton";
import Form from '../components/Form';
import ClientsLogo from "../components/ClientsLogo";
import Divider from "../components/Divider";
import {Helmet} from 'react-helmet';
import PageImage from "../components/PageImage";




function AndroidApp() {
    return(
        <Page>
            <Helmet>
                <title>
                    Android App Development Services Company India | Application Development
                </title>
                <link rel="canonical" href="https://www.artographertacts.com/services/android-app-development/" />
                <meta 
                    name="descriptions" 
                    content="Artographer is a leading Android App Design and Development company offers Android Application Development, Android UI/UX design services and more. Click to learn more." 
                />
            </Helmet>
            <PageTitle
                title= "Android App Development"
                text1= "Our Android app development company offers comprehensive and state-of-the-art custom Android app development services to new startups and small businesses. We can develop apps for different industry verticals such as health, fitness, education, travel & tourism, entertainment and many more."
                text2= "Artographer’s team of professional Android app developers are well-versed in native programming languages like Java & Kotlin and hybrid programming languages like React JS, ionic & flutter. From POC, MVP, design, development, testing and maintenance, we take care of everything."
                listItem1= {<span><FaCheck className="pt-list-icon"/><span>White Label Partnership</span></span>}
                listItem2= {<span><FaCheck className="pt-list-icon"/><span>Startup Business Partnerships</span></span>}
                button= {<PrimaryButton smalltext= 'Its Free' maintext= 'Get Custom Quote' />}
            />
            <ClientsLogo />
            <MainContainer>
                <PrimarySection 
                    title= "Android App UI & UX Design Service Providers"
                    content= "We are a professional Android App development company in India specializing at building dazzling Android UI & UX designs. The interactive mobile app design we create will work smoothly across diverse mobile phone devices. Our creative Android UI & UX designers create world-class designs that will transform your business into brands."
                    content2= "Designers of our Android application development agency provide clients an interactive prototype of their product. The best part of our Android UI & UX design services is that without writing a single line of code, clients are able to suggest changes and get them corrected according to their taste prior to the development work."
                    image= '../images/artographer-android-app-development.png'
                    alt= 'android app development'
                />
            </MainContainer>
            <PageImage
                src='../images/web-development-service.webp'
                alt='web development design'
            />
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Leading Android App Development Company for Small Businesses & Startups</SectionTitle>}
                    para1= "Google’s Android will maintain its leadership in the market and will account for 85% of smartphone shipment worldwide by 2020. It is clear that Android is going to be the fastest-growing platform in the world."
                    para2= "Android App development is one of our core strengths. Our expert designers and developers will help to grow your company exponentially by creating robust and intuitive Android apps. We also assign a software architect for every mobile app development project. They will control the performance of the development team, review code quality and make sure that the deliverables meet your expectations."
                    para3= "Our team will thoroughly research and implement tailor-made strategies, add amazing features, and power-packed functionalities. We will build solutions around specific requirements, stay on budget and deliver projects successfully within the given timeframe."
                />
            </MainContainer>
            <Divider />
            <MainContainer>
              <IndustriesBenefits 
                title= "Industries that can Benefit from Our Full Stack Android App Development Services"
                content= "Our android mobile app development team at Artographer are experts in developing innovative solutions for clients in a vast variety of industries and verticals. We are dedicated to helping businesses in the travel, hotel & restaurant, education, healthcare, and real estate industries gain more leads, conversions, and loyal customers."
              />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Get Fully-functional Apps for Your Business with Our Professional Android App Development Services"
                    title1= "Custom Android App Development"
                    content1= "As an Android app development company in India, our designers and developers can build custom solutions that will run seamlessly on any type of Android device."
                    title2= "Android App Support & Maintenance"
                    content2= "To accomplish all your mobility needs, our Android mobile app development agency delivers prompt app maintenance support services to ensure that your app stays competitive, robust and secure."
                    title3= "Migration & Upgradation Services"
                    content3= "If you want to migrate your app from another platform to Android or upgrade the present version of your Android app, we are ready to help."
                    title4= "QA and Testing Services"
                    content4= "From initial planning till the project delivery, we assigns quality analysts to make sure your app runs smoothly across all screens."
                    title5= "Proof of Concept (POC)"
                    content5= "Our professional team of business analysts and solution architects will collaborate with you to develop features that have practical potential. In short, we will test if the concept is feasible or not."
                    title6= "Minimum Viable Product (MVP)"
                    content6= "Through our minimum viable product development services, we will gain valuable insights into how the core-value-oriented form of your app will work for your target audience and business goals."
                />
            </MainContainer>
            <MainContainer>
                <OurProcess 
                    title= {<SectionTitle>Professional Android App Development for B2B Outsourcing & White label Company Services</SectionTitle>}
                    para1= {<span>Our white label Android app development servies is suitable for agencies that are looking for extra development resources to share their workload.<br /><br />Artographer will work behind the scenes and make you look great in front of your clients.</span>}
                    para2= {<span>You take all the credits. Our expert Android app developers will make sure all the pieces of Android application development are successfully implemented.<br /><br />As a general rule, we will never contact your customers directly. But in the event of such a situation, we are on your team.</span>}
                    para3= "All of our B2B Android application development solutions are designed to off-load the Android app development needs of any agency. We are happy to work under your branding to take care of your customers."
                />
            </MainContainer>
            <MainContainer>
                <ResultDriven 
                    header= "Verticals of Our Android Application Development Services"
                    title1= "Wi-Fi and GPS Enabled Web Apps"
                    content1= "Our Android development services company in India can develop Wi-Fi and GPS enabled web apps that will help to offer amazing services to your end-users."
                    title2= "Travel & Tourism App Solutions"
                    content2= "To improve the profitability, business outreach, and customer retention of your travel company, we offer superior travel & tourism app development solutions."
                    title3= "E-Learning App Solutions"
                    content3= "Our Android development agency develops tailor-made, standard, and user-friendly e-learning apps for institutes, universities, startups, schools, colleges, entrepreneur, and more."
                    title4= "Online Booking & E-commerce Application"
                    content4= "To improve your business growth, our Android application development company in India can automate booking system and integrate e-commerce models in Android apps."
                    title5= "Healthcare App Solutions"
                    content5= "We can develop revolutionary healthcare Android apps which will help you to upsurge patient experience and better manage health data records."
                    title6= "Banking & Finance App Solutions"
                    content6= "We can develop scalable Android mobile apps to help financial organizations improve their efficiency."
                />
            </MainContainer>
            <MainContainer>
                <LocationbasedService
                    title= "Our Location-based / Localized Android App Development Services"
                    content= {<span>We have worked with Android app development clients from different locations like US, UK, Canada, etc, therefore we have a clear picture of what type of services the audience belonging to these regions will expect in Android app development services.<br />If you have ideas to provide the exact services that your customers expect, then feel free to partner with our web designers, developers, and digital marketers at Artographer.</span>}
                />
            </MainContainer>
            <MainContainer>
                <AccordionPro 
                    sectionTitle= 'Frequently Asked Questions about Our Android App Development Services'
                    title1="Is it possible to develop a Android app within a month?"
                    content1="No. The complexities of Android apps vary according to the type. It entirely depends upon the requirements of your project."
                    title2="What if I want any modification in my Android aplication once it is launched?"
                    content2="Our Android application development agency will help you. Modifications will be charged extra."
                    title3="Will your Android application development support me after my app gets live on the app store?"
                    content3="If you require maintenance work our Android application development team will help you. Maintenance work is chargeable."
                    title4="Which plateform should we target? iOS or Android or Both?"
                    content4="With the growing popularity of both smartphones, we recommend launching your app on both platforms. We can help to launch your app in iOS as well."
                />
            </MainContainer>
            <Form />
            <MainContainer>
                <RelatedServices
                    title= "Mobile App UI & UX Design"
                    subTitle= "Creative Mobile App UI & UX Design "
                    title2= "Graphic Design Services"
                    subTitle2= "Creative Graphic Design Services "
                    title3= "Web App Development"
                    subTitle3= "Creative Web App Development"
                    title4= "Vue Js Development"
                    subTitle4= "Creative Vue Js Development"
                    title5= "Digital Marketing Services"
                    subTitle5= "Social media marketing | SEO Service | Search engine marketers | PPC Marketing"
                    title6= "Social Media Marketing"
                    subTitle6= "Facebook marketing | LinkedIn Marketing | Instagram Marketing | Twitter Marketing"
                />
            </MainContainer>
            <OneRoof />
            <Certification />
        </Page>
    );
}

export default AndroidApp;